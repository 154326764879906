import { CdkDragDrop, moveItemInArray } from '@angular/cdk/drag-drop';
import { ChangeDetectorRef, Component, ElementRef, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { Store } from '@ngrx/store';
import { TranslateService } from '@ngx-translate/core';
import { Helper } from 'app/common/helper';
import {
  Constant,
  ErrorCodeAnnouncementMedia,
  ErrorCodeSimpleMedia,
  FIELD_COMPONENT,
  MODULE_NAME,
  TypeMediaFileEnum,
  TypeMediasNotSupported
} from 'app/config/constants';
import { DialogAutoUpdatePlaylistComponent } from 'app/dialog/dialog-auto-update-playlist/dialog-auto-update-playlist.component';
import { DialogConfirmComponent } from 'app/dialog/dialog-confirm/dialog-confirm.component';
import { DialogCreatePlaylistTabTranslationSpeechSynthesisComponent } from 'app/dialog/dialog-create-playlist-tab-translation-speech-synthesis/dialog-create-playlist-tab-translation-speech-synthesis.component';
import { DialogDeliveryAnnouncementComponent } from 'app/dialog/dialog-delivery-announcement/dialog-delivery-announcement.component';
import { DialogGlossaryComponent } from 'app/dialog/dialog-glossary/dialog-glossary.component';
import { DialogMessageComponent } from 'app/dialog/dialog-message/dialog-message.component';
import { DialogPlayTimingSettingComponent } from 'app/dialog/dialog-play-timing-setting/dialog-play-timing-setting.component';
import { DialogSpecificSequenceSettingComponent } from 'app/dialog/dialog-specific-sequence-setting/dialog-specific-sequence-setting.component';
import { AnnouncementFolder } from 'app/model/entity/announcement/announcement-folder';
import { AnnouncementMedia } from 'app/model/entity/announcement/announcement-media';
import { AnnouncementPlaylist } from 'app/model/entity/announcement/announcement-playlist';
import { CommonPlaylistRegistration } from 'app/model/entity/announcement/common-playlist-registratrion';
import { DeviceCommonSpecific } from 'app/model/entity/announcement/device-common-specific';
import { GroupDeviceAnnouncement } from 'app/model/entity/announcement/group-device-announcement';
import { MessageErrorImportFile } from 'app/model/entity/announcement/message-error-import-file';
import { MessageErrorTabTranslation } from 'app/model/entity/announcement/message-error-tab-translation';
import { Common } from 'app/model/entity/common';
import { PlayTimingSetting } from 'app/model/entity/play-timing-setting';
import { SimpleMedia } from 'app/model/entity/simple/simple-media';
import { SpeechSynthesis } from 'app/model/entity/speech-synthesis';
import { Translation } from 'app/model/entity/translation';
import { AnnouncementManagerService } from 'app/service/announcement/announcement-manager.service';
import { APICustomerService } from 'app/service/api-customer.service';
import { CommonTableService } from 'app/service/common-table.service';
import { CommonService } from 'app/service/common.service';
import { DataService } from 'app/service/data.service';
import { DialogService } from 'app/service/dialog.service';
import { MenuActionService } from 'app/service/menu-action.service';
import { SimplePlaylistService } from 'app/service/simple/simple-playlist.service';
import { UserSettingService } from 'app/service/user-setting.service';
import { AppState } from 'app/store/app.state';
import _ from 'lodash';
import moment from 'moment';
import { ToastrService } from 'ngx-toastr';
import { Subject, Subscription, forkJoin } from 'rxjs';
import { ResetMiniMediaStateAction, SaveMiniMediaStateAction } from '../simple-signage-editor/ngrx/content-action';
import { AudioOfSequence } from './../../model/entity/announcement/audio-of-sequence';
import { CustomTagService } from 'app/service/custom-tag.service';
@Component({
  selector: 'announcement-manager',
  templateUrl: './announcement-manager.component.html',
  styleUrls: ['./announcement-manager.component.scss']
})
export class AnnouncementManagerComponent implements OnInit {
  /**
   * Constant
   */
  Constant = Constant;
  /**
   * Object
   */
  Object = Object;
  /**
   * access to text box edit folder name
   */
  @ViewChild('textBoxFolder') folderRef: ElementRef;
  /**
   * access to text box edit playlist name
   */
  @ViewChild('textBoxPlaylist') textBoxRef: ElementRef;

  @ViewChild('createPlaylistTab') createPlaylistTab: ElementRef<HTMLElement>;

  @ViewChild('commonPlaylistTab') commonPlaylistTab: ElementRef<HTMLElement>;

  @ViewChild('specificPlaylistTab') specificPlaylistTab: ElementRef<HTMLElement>;

  @ViewChild('checkboxPlaylist') checkboxPlaylist: ElementRef<HTMLElement>;

  Helper = Helper;
  TypeMediaFileEnum = TypeMediaFileEnum;
  /**
   * placeholder search
   */
  placeholderSearch: string;
  /**
   * common
   */
  PATH_ANGLE_DOUBLE_RIGHT = Constant.PATH_ANGLE_DOUBLE_RIGHT;

  readonly MAX_LENGTH_PLAYLIST_NAME = 48;
  readonly TIME_ONE_HOUR = 3600;
  readonly MAX_TOTAL_TIME = 1200;
  readonly IS_EDIT_PLAYLIST = 'isEditPlaylist';
  readonly IS_ON_PREVIEW = 'isOnPreview';
  readonly IS_EDIT_FOLDER = 'isEditFolder';
  readonly IS_DELETE_DISABLE = 'isDeleteDisable';
  readonly IS_AUDIO_SILENCE = 'isAudioSilence';
  readonly FORMAT_DATE = 'YYYY.MM.DD HH:mm';
  readonly FORMAT_TIME = 'HH:mm';
  readonly AUDIO_SEQ_ID = 'audio-sequence';
  readonly AUDIO_ANNOUNCEMENT_ID = 'audioAnnouncement';
  readonly INPUT_IMPORT_ID = 'importedFileAnnouncement';
  readonly IS_DRAGGING_MEDIA = 'isDragging';
  readonly IS_MOVING_CSS = 'isMovingCss';
  readonly MAX_WIDTH_TIME_LINE = 645;
  readonly DURATION_ELEMENT = 'duration';
  readonly CURSOR_POINT = 8;
  readonly MAX_NUMBER_COLORS = 5;
  readonly IS_SELECT_FOLDER_ORIGINAL = 'isSelectOriginalFolder';
  readonly IS_SHOW_FOLDER_ORIGINAL = 'isShowOriginalFolder';

  /**
   * FORMAT HOURS
   */
  readonly FORMAT_HOURS = 'HH:mm:ss';

  /**
   * item
   */
  readonly ITEM = 'item';

  /**
   * group playlist common
   */
  public groupPlaylistCommon = { id: 1, name: this.translateService.instant('announcement-manager.common-playlist'), isExpand: false };

  /**
   * group playlist speial
   */
  public groupPlaylistSpecial = { id: 2, name: this.translateService.instant('announcement-manager.playlist'), isExpand: false };

  /**
   * language key
   */
  public languageKey: string;

  groupOriginal: any;

  /**
   * array subscription
   */
  subscriptions: Array<Subscription> = new Array<Subscription>();

  /**
   * save data success
   */
  @Output() saveDataSuccess: EventEmitter<boolean> = new EventEmitter<boolean>();

  /**
   * old value playlist name
   */
  oldValuePlaylistName: string;

  /**
   * common object
   */
  private commonObject: Common;

  /**
   * is chose tab playlist
   */
  readonly IS_CHOSE_TAB_PLAYLIST = 'isChoseTabPlaylist';

  /**
   * is chose tab create playlist
   */
  readonly IS_CHOSE_TAB_CREATE_PLAYLIST = 'isChoseTabCreatePlaylist';

  /**
   * is chose tab common playlist
   */
  readonly IS_CHOSE_TAB_COMMON_PLAYLIST = 'isChoseTabCommonPlaylist';

  /**
   * is chose tab register playlist
   */
  readonly IS_CHOSE_TAB_REGISTER_PLAYLIST = 'isChoseTabRegisterPlaylist';

  /**
   * tab selected
   */
  public tabSelected: any = Constant.PLAYLIST_ENUM;

  /**
   * list playlists
   */
  playlists: Array<AnnouncementPlaylist>;

  /**
   * list playlists
   */
  playlistsCommon: Array<AnnouncementPlaylist>;

  /**
   * list playlists
   */
  playlistsSpecific: Array<AnnouncementPlaylist>;

  /**
   * list folders
   */
  folders: Array<AnnouncementFolder> = new Array<AnnouncementFolder>();

  /**
   * true if show placeholder
   */
  isShowPlaceholder: boolean;

  /**
   * true if dragging media
   */
  isDraggingMedia: boolean;

  /**
   * folder's name contains media dragging
   */
  folderNameContains: string;

  /**
   * playlist selected
   */
  playlistSelected: AnnouncementPlaylist;

  /**
   * playlist selected
   */
  playlistSelectedClone: AnnouncementPlaylist;

  /**
   * playlist group selected
   */
  playlistGroupSelected: any;

  /**
   * true if preview on
   */
  isPreviewOn: boolean;

  /**
   * true if change media of sequence
   */
  isChangeAudioOfSequence: boolean;

  /**
   * old audio of sequence
   */
  oldAudioOfSequences: Array<AudioOfSequence> = new Array();

  /**
   * audios of sequence
   */
  audioOfSequences: Array<AudioOfSequence> = new Array();

  /**
   * true if change data
   */
  isChangedData: boolean;

  /**
   * list point percent
   */
  pointPercents: Array<number>;
  /**
   * list point number
   */
  pointNumbers: Array<number>;

  /**
   * total time
   */
  totalTime: string;

  /**
   * folder selected
   */
  folderSelected: AnnouncementFolder;

  /**
   * media of sequence selected
   */
  audioOfSequenceSelected: AudioOfSequence;

  /**
   * audio selected
   */
  audioSelected: AnnouncementMedia;

  /**
   * true if folder, media are selected
   */
  isSelectedAudioOrFolder: boolean;
  /**
   * true if media of sequence is selected
   */
  isSelectedAudioOfSequence: boolean;
  /**
   * true if audio silence is selected
   */
  isSelectedAudioSilence: boolean;

  /**
   * value cursor point
   */
  valueCursorPoint: number = 0;
  /**
   * time cursor point time line
   */
  timeCursorPointSecond: number = 0;

  /**
   * true if enlarge preview
   */
  isEnlargePreview: boolean = true;

  /**
   * id interval
   */
  idInterval: any;

  /**
   * True if waiting data from API
   */
  private isWaitingActionAPI: boolean;

  /**
   * id interval media
   */
  private idIntervalMedia: any;

  /**
   * total time video
   */
  totalTimeVideo: string;

  /**
   * list media (mini media)
   */
  medias: Array<AnnouncementMedia>;

  /**
   * true if type list
   */
  isTypeList: boolean = true;

  /**
   * medias of folder
   */
  audiosOfFolder: Array<AnnouncementMedia>;

  /**
   * old medias of folder
   */
  oldAudiosOfFolder: Array<AnnouncementMedia>;

  /**
   * search input value
   */
  searchInputValue: string;

  /**
   * handle button back
   */
  isActiveButtonBack: boolean;

  /**
   * old value folder name
   */
  oldValueFolderName: string;

  /**
   * true if dragging media of sequence
   */
  isDraggingMediaOfSequence: boolean;

  /**
   * row height row(mini media)
   */
  heightRowDisplayNone: string;

  /**
   * Error message list
   */
  private errorMessageList = [];

  /**
   * Error message Import File
   */
  private errorMessageImportFile: MessageErrorImportFile;

  /**
   * select playlist group
   */
  public selectingPlaylistGroup: any;

  /**
   * custom tag clone
   */
  public customtagClones: Array<CommonPlaylistRegistration> = new Array();
  /**
   * list custom tag element
   */
  public listCommonPlaylistRegistration: Array<CommonPlaylistRegistration> = new Array();

  /**
   * list device common specifics
   */
  public deviceCommonSpecifics: Array<DeviceCommonSpecific> = new Array();

  /**
   * group device
   */
  public groupsDevice: Array<GroupDeviceAnnouncement> = new Array();

  /**
   * common playlist registration selected
   */
  public commonPlaylistRegistrationSelecting: CommonPlaylistRegistration;

  /**
   * true if check all
   */
  public isCheckedAll: boolean;

  /**
   * true if check all
   */
  public isCheckedAllLanguage: boolean;

  /**
   * deviceSelected
   */
  deviceSelected: CommonPlaylistRegistration;
  /**
   * old common playlist registrations
   */
  public oldCommonPlaylistRegistrations: Array<CommonPlaylistRegistration> = new Array();

  /**
   * customtag clone selecting
   */
  public customtagCloneSelecting: CommonPlaylistRegistration;

  /**
   * play timing setting
   */
  public objectTiming: PlayTimingSetting;

  /**
   * true if change common registration
   */
  isChangeCommonRegistration: boolean;

  /**
   * true if change Specific Registration
   */
  isChangeSpecificRegistration: boolean;

  /**
   * deviceSelected
   */
  deviceSelectedPlaylist: CommonPlaylistRegistration;

  /**
   * auto Update Object
   */
  autoUpdateObject: any;

  /**
   * interval auto update
   */
  intervalAutoUpdate: any;

  /**
   * total time
   */
  totalSize: number;

  /**
   * index live time
   */
  indexLiveTime: number = 0;

  /**
   * true if item is running
   */
  isLiveTimeRunning: boolean;

  /**
   * String now Playing
   */
  public nowPlaying: string;

  /**
   * time Date Line
   */
  private timeDateLine: any;

  /**
   * time Date Line is number
   */
  private numTimeDateLine: number;

  /**
   * header get from timetable editor
   */
  private headersFromTimetable: any;

  /**
   * moduleName
   */
  moduleName: string;

  /**
   * headers Original
   */
  headersOriginal: any = [
    {
      label: this.translateService.instant('announcement-manager.time'),
      field: 'time',
      style: { left: '0', width: '83px', overflow: 'hidden', 'text-overflow': 'ellipsis', 'white-space': 'nowrap' },
      class: 'sticky-column'
    },
    {
      label: this.translateService.instant('announcement-manager.audio-file'),
      field: 'filename',
      style: {
        right: '636px',
        'min-width': '300px',
        'max-width': '300px',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap'
      },
      class: 'sticky-column'
    },
    {
      label: this.translateService.instant('announcement-manager.play-back'),
      field: 'audio',
      style: {
        right: '336px',
        'min-width': '300px',
        'max-width': '300px',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap'
      },
      class: 'sticky-column'
    },
    {
      label: this.translateService.instant('announcement-manager.play-time-start'),
      field: 'startTime',
      style: {
        right: '228px',
        'min-width': '108px',
        'max-width': '108px',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap'
      },
      class: 'sticky-column'
    },
    {
      label: this.translateService.instant('announcement-manager.play-time-end'),
      field: 'endTime',
      style: {
        right: '120px',
        'min-width': '108px',
        'max-width': '108px',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap'
      },
      class: 'sticky-column'
    },
    {
      label: this.translateService.instant('announcement-manager.order'),
      field: 'sequenceIndex',
      style: {
        right: '0',
        'min-width': '120px',
        'max-width': '120px',
        overflow: 'hidden',
        'text-overflow': 'ellipsis',
        'white-space': 'nowrap'
      },
      class: 'sticky-column'
    }
  ];

  fileGlossary: string;

  listActive: Set<string> = new Set();

  isDisableTranslate: boolean;

  /**
   * true if file of yesterday
   */
  grayOut: boolean;

  /**
   * stateOfComponent
   */
  stateOfComponent: {
    isChangeLayout: boolean;
    isEnlargePreview: boolean;
    isTabPlaylist: boolean;
    isPreviewOn: boolean;
    isShowPlaceholder: boolean;
    playlists: Array<AnnouncementPlaylist>;
    playlistsCommon: Array<AnnouncementPlaylist>;
    playlistsSpecial: Array<AnnouncementPlaylist>;
    audioOfSequences: Array<AudioOfSequence>;
    isChangedData: boolean;
    oldAudioOfSequences: Array<AudioOfSequence>;
    folders: Array<AnnouncementFolder>;
    medias: Array<AnnouncementMedia>;
    playlistSelected: AnnouncementPlaylist;
    isTypeList: boolean;
    folderSelected: AnnouncementFolder;
    audioSelected: AnnouncementMedia;
    isSelectedAudioOrFolder: boolean;
    audioOfSequenceSelected: AudioOfSequence;
    totalTime: string;
    pointPercents: Array<number>;
    pointNumbers: Array<number>;
    audiosOfFolder: Array<AnnouncementMedia>;
    isChangeAudioOfSequence: boolean;
    oldAudiosOfFolder: Array<AnnouncementMedia>;
    searchInputValue: string;
    isActiveButtonBack: boolean;
    oldValuePlaylistName: string;
    oldValueFolderName: string;
    timeCursorPointSecond: number;
    colorBeingUsed: Map<Number, string>;
    unUsedColors: string[];
    isSelectedAudioOfSequence: boolean;
    isSelectedAudioSilence: boolean;
    totalTimeVideo: string;
    isWaitingActionAPI: boolean;
    isDraggingMediaOfSequence: boolean;
    heightRowDisplayNone: string;
    errorMessageList: [];
    selectingPlaylistGroup: any;
    isDraggingMedia: boolean;
    folderNameContains: string;
    playlistGroupSelected: any;
    isChangeCommonRegistration: boolean;
    totalSize: number;
  };

  private createPlaylistTabStateOfComponent: {
    isChangeLayout: boolean;
    isEnlargePreview: boolean;
    isTabPlaylist: boolean;
    isPreviewOn: boolean;
    isShowPlaceholder: boolean;
    playlists: Array<AnnouncementPlaylist>;
    audioOfSequences: Array<AudioOfSequence>;
    oldAudioOfSequences: Array<AudioOfSequence>;
    folders: Array<AnnouncementFolder>;
    medias: Array<SimpleMedia>;
    playlistSelected: AnnouncementPlaylist;
    isTypeList: boolean;
    folderSelected: AnnouncementFolder;
    audioSelected: SimpleMedia;
    audioOfSequenceSelected: AudioOfSequence;
    totalTime: string;
    pointPercents: Array<number>;
    pointNumbers: Array<number>;
    audiosOfFolder: Array<SimpleMedia>;
    isChangeAudioOfSequence: boolean;
    oldAudiosOfFolder: Array<SimpleMedia>;
    searchInputValue: string;
    isActiveButtonBack: boolean;
    oldValuePlaylistName: string;
    oldValueFolderName: string;
    timeCursorPointSecond: number;
    colorBeingUsed: Map<Number, string>;
    unUsedColors: string[];
    isSelectedAudioOfSequence: boolean;
    totalTimeVideo: string;
    isWaitingActionAPI: boolean;
  };

  /**
   * miniMediaStateOfComponent
   */
  miniMediaStateOfComponent: {
    folderSelected: AnnouncementFolder;
    folders: Array<AnnouncementFolder>;
    medias: Array<AnnouncementMedia>;
    audiosOfFolder: Array<AnnouncementMedia>;
    mediaDragging: AnnouncementMedia;
  };

  // chuongln
  public stopLazyScroll: Subject<any> = new Subject<any>();

  schedules: any[];
  /**
   * headers
   */
  headers: any[];
  scheduleSelected: any;
  dataLengthAPI;

  private size = 30;
  private indexItem = 0;
  isLoadHeader = false;
  formatTime = 'HH:mm:ss';
  timeAdd = 0;

  /**
   * true if network connected successfully
   */
  public isNetworkConnect: boolean;

  /**
   * timeByImg
   */
  private timeByImg;

  /**
   * lastTimeUpdate
   */
  public lastTimeUpdate;

  /**
   * is tab playlist
   */
  isTabPlaylist: boolean;

  /**
   * Tile header for table Speech Synthesis
   */
  titleHeaderSpeechSynthesis = [
    { title: 'checkAll', value: '' },
    { title: 'language', value: 'announcement-manager.language' },
    { title: 'text', value: 'announcement-manager.text' },
    { title: 'speaker', value: 'announcement-manager.speaker' },
    { title: 'rate', value: 'announcement-manager.rate' },
    { title: 'length', value: 'announcement-manager.length' },
    { title: 'listening', value: 'announcement-manager.listening' }
  ];
  languagesTranslatioMandatory = []; // List language for translation (alway have "jp" & "en")
  languagesTranslation = []; // List language for translation item

  listRate = [
    { title: 'x0.8', value: '80%' },
    { title: 'x1.0', value: '100%' },
    { title: 'x1.2', value: '120%' }
  ]; // List rate
  listSpeechSynthesis: Array<SpeechSynthesis> = new Array<SpeechSynthesis>(); // List Speech Synthesis
  listSpeechSynthesisCloneDeep: Array<SpeechSynthesis> = new Array<SpeechSynthesis>(); // List Speech Synthesis clone Deep
  translation: Translation = new Translation();
  translationText: string = ''; // Translation Text
  /**
   * bucket Save Audio
   */
  bucketSaveAudio: string;
  isPlaying: boolean;
  constructor(
    private menuActionService: MenuActionService,
    private dialogService: DialogService,
    private announcementManagerService: AnnouncementManagerService,
    private dataService: DataService,
    private translateService: TranslateService,
    public readonly store: Store<AppState>,
    private toast: ToastrService,
    private commonService: CommonService,
    private cdr: ChangeDetectorRef,
    private commonTableService: CommonTableService,
    private apiCustomerService: APICustomerService,
    private userSettingService: UserSettingService,
    private simplePlaylistService: SimplePlaylistService,
    private customTagService: CustomTagService
  ) {
    // save data
    this.subscriptions.push(
      this.menuActionService.actionSave.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          if (this.playlistSelected?.isEdit) {
            this.savePlaylist();
          } else if (this.isChangeAudioOfSequence) {
            this.saveSequence(false);
          } else if (this.folderSelected?.isEdit) {
            this.saveFolder();
          } else if (this.isChangeCommonRegistration) {
            this.saveCommonRegistration();
          } else if (this.isChangeSpecificRegistration) {
            this.savePlaylistForDevice();
          }
        }
      })
    );

    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_TAB_PLAYLIST) {
        this.isTabPlaylist = <boolean>data[1];
      }
    });

    // import media
    this.subscriptions.push(
      this.menuActionService.actionImportMedia.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.importMedia();
        }
      })
    );

    // add new playlist
    this.subscriptions.push(
      this.menuActionService.actionAdd.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.addPlaylist();
        }
      })
    );

    // edit playlist
    this.subscriptions.push(
      this.menuActionService.actionEdit.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.editPlaylist();
        }
      })
    );

    // delete playlist
    this.subscriptions.push(
      this.menuActionService.actionDelete.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.deletePlaylist();
        }
      })
    );

    // duplicate playlist
    this.subscriptions.push(
      this.menuActionService.actionDuplicate.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.duplicatePlaylist();
        }
      })
    );

    // add new folder
    this.subscriptions.push(
      this.menuActionService.actionAddFolder.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.addFolder();
        }
      })
    );

    // edit folder name
    this.subscriptions.push(
      this.menuActionService.actionEditFolder.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.editFolder();
        }
      })
    );

    // delete item
    this.subscriptions.push(
      this.menuActionService.actionDeleteItem.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.deleteItemInMenu();
        }
      })
    );

    // announcement timing
    this.subscriptions.push(
      this.menuActionService.actionPlayTimingSetting.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.playTimingSetting();
        }
      })
    );

    this.subscriptions.push(
      this.menuActionService.actionSpecificSequenceSetting.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.specificSequenceSetting();
        }
      })
    );

    this.subscriptions.push(
      this.menuActionService.actionAutoRefresh.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.settingAutoUpdatePlaylist();
        }
      })
    );

    // delivery data
    this.subscriptions.push(
      this.menuActionService.actionDelivery.subscribe(module => {
        if (module == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent]) {
          this.delivery();
        }
      })
    );

    this.subscriptions.push(
      this.translateService.onLangChange.subscribe(() => {
        // multi language date picker
        this.languageKey = this.commonService.getCommonObject().setting?.language;
        // multi language placeholder search
        this.placeholderSearch = this.translateService.instant('announcement-manager.search');
        this.audioOfSequences?.forEach(audio => {
          if (!audio.idMedia) {
            audio.name = this.translateService.instant('announcement-manager.silence');
          }
        });

        this.nowPlaying = this.translateService.instant('announcement-manager.now-playing');
        if (this.tabSelected == Constant.PLAYLIST_ENUM) {
          this.selectDevicePlaylist(this.deviceSelectedPlaylist);
        }
        this.headersOriginal = [
          {
            label: this.translateService.instant('announcement-manager.time'),
            field: 'time',
            style: { left: '0', width: '83px', overflow: 'hidden', 'text-overflow': 'ellipsis', 'white-space': 'nowrap' },
            class: 'sticky-column'
          },
          {
            label: this.translateService.instant('announcement-manager.audio-file'),
            field: 'filename',
            style: {
              right: '636px',
              'min-width': '300px',
              'max-width': '300px',
              overflow: 'hidden',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap'
            },
            class: 'sticky-column'
          },
          {
            label: this.translateService.instant('announcement-manager.play-back'),
            field: 'audio',
            style: {
              right: '336px',
              'min-width': '300px',
              'max-width': '300px',
              overflow: 'hidden',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap'
            },
            class: 'sticky-column'
          },
          {
            label: this.translateService.instant('announcement-manager.play-time-start'),
            field: 'startTime',
            style: {
              right: '228px',
              'min-width': '108px',
              'max-width': '108px',
              overflow: 'hidden',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap'
            },
            class: 'sticky-column'
          },
          {
            label: this.translateService.instant('announcement-manager.play-time-end'),
            field: 'endTime',
            style: {
              right: '120px',
              'min-width': '108px',
              'max-width': '108px',
              overflow: 'hidden',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap'
            },
            class: 'sticky-column'
          },
          {
            label: this.translateService.instant('announcement-manager.order'),
            field: 'sequenceIndex',
            style: {
              right: '0',
              'min-width': '120px',
              'max-width': '120px',
              overflow: 'hidden',
              'text-overflow': 'ellipsis',
              'white-space': 'nowrap'
            },
            class: 'sticky-column'
          }
        ];
        if (this.timeByImg) {
          this.lastTimeUpdate = Helper.formatString(
            this.translateService.instant(`announcement-manager.last-time-update`),
            Helper.updateLanguageLastTime(this.timeByImg, this.languageKey)
          );
        }
        this.setDefaultTranslation();
        this.listSpeechSynthesis = new Array<SpeechSynthesis>();
        this.setDefaultSpeech();
      })
    );

    // store state
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.stateOfComponent = {
            isChangeLayout: componentState?.announcementManagerState?.stateOfComponent.isChangeLayout,
            isEnlargePreview: componentState?.announcementManagerState?.stateOfComponent.isEnlargePreview,
            isTabPlaylist: componentState?.announcementManagerState?.stateOfComponent.isTabPlaylist,
            isPreviewOn: componentState?.announcementManagerState?.stateOfComponent.isPreviewOn,
            isShowPlaceholder: componentState?.announcementManagerState?.stateOfComponent.isShowPlaceholder,
            playlists: componentState?.announcementManagerState?.stateOfComponent.playlists,
            playlistsCommon: componentState?.announcementManagerState?.stateOfComponent.playlistsCommon,
            playlistsSpecial: componentState?.announcementManagerState?.stateOfComponent.playlistsSpecial,
            audioOfSequences: componentState?.announcementManagerState?.stateOfComponent.audioOfSequences,
            isChangedData: componentState?.announcementManagerState?.stateOfComponent.isChangedData,
            oldAudioOfSequences: componentState?.announcementManagerState?.stateOfComponent.oldAudioOfSequences,
            folders: componentState?.announcementManagerState?.stateOfComponent.folders,
            medias: componentState?.announcementManagerState?.stateOfComponent.medias,
            playlistSelected: componentState?.announcementManagerState?.stateOfComponent.playlistSelected,
            isTypeList: componentState?.announcementManagerState?.stateOfComponent.isTypeList,
            folderSelected: componentState?.announcementManagerState?.stateOfComponent.folderSelected,
            audioSelected: componentState?.announcementManagerState?.stateOfComponent.audioSelected,
            isSelectedAudioOrFolder: componentState?.announcementManagerState?.stateOfComponent.isSelectedAudioOrFolder,
            audioOfSequenceSelected: componentState?.announcementManagerState?.stateOfComponent.audioOfSequenceSelected,
            totalTime: componentState?.announcementManagerState?.stateOfComponent.totalTime,
            pointPercents: componentState?.announcementManagerState?.stateOfComponent.pointPercents,
            pointNumbers: componentState?.announcementManagerState?.stateOfComponent.pointNumbers,
            audiosOfFolder: componentState?.announcementManagerState?.stateOfComponent.audiosOfFolder,
            isChangeAudioOfSequence: componentState?.announcementManagerState?.stateOfComponent.isChangeAudioOfSequence,
            oldAudiosOfFolder: componentState?.announcementManagerState?.stateOfComponent.oldAudiosOfFolder,
            searchInputValue: componentState?.announcementManagerState?.stateOfComponent.searchInputValue,
            isActiveButtonBack: componentState?.announcementManagerState?.stateOfComponent.isActiveButtonBack,
            oldValuePlaylistName: componentState?.announcementManagerState?.stateOfComponent.oldValuePlaylistName,
            oldValueFolderName: componentState?.announcementManagerState?.stateOfComponent.oldValueFolderName,
            timeCursorPointSecond: componentState?.announcementManagerState?.stateOfComponent.timeCursorPointSecond,
            colorBeingUsed: componentState?.announcementManagerState?.stateOfComponent.colorBeingUsed,
            unUsedColors: componentState?.announcementManagerState?.stateOfComponent.unUsedColors,
            isSelectedAudioOfSequence: componentState?.announcementManagerState?.stateOfComponent.isSelectedAudioOfSequence,
            isSelectedAudioSilence: componentState?.announcementManagerState?.stateOfComponent.isSelectedAudioSilence,
            totalTimeVideo: componentState?.announcementManagerState?.stateOfComponent.totalTimeVideo,
            isWaitingActionAPI: componentState?.announcementManagerState?.stateOfComponent.isWaitingActionAPI,
            isDraggingMediaOfSequence: componentState?.announcementManagerState?.stateOfComponent.isDraggingMediaOfSequence,
            heightRowDisplayNone: componentState?.announcementManagerState?.stateOfComponent.heightRowDisplayNone,
            errorMessageList: componentState?.announcementManagerState?.stateOfComponent.errorMessageList,
            selectingPlaylistGroup: componentState?.announcementManagerState?.stateOfComponent.selectingPlaylistGroup,
            isDraggingMedia: componentState?.announcementManagerState?.stateOfComponent.isDraggingMedia,
            folderNameContains: componentState?.announcementManagerState?.stateOfComponent.folderNameContains,
            playlistGroupSelected: componentState?.announcementManagerState?.stateOfComponent.playlistGroupSelected,
            isChangeCommonRegistration: componentState?.announcementManagerState?.stateOfComponent.isChangeCommonRegistration,
            totalSize: componentState?.announcementManagerState?.stateOfComponent.totalSize
          };
        })
    );

    // store state of tab create playlist
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((createPlaylist: any) => {
          this.createPlaylistTabStateOfComponent = {
            isChangeLayout: createPlaylist?.announcementManagerState?.stateOfComponent.isChangeLayout,
            isEnlargePreview: createPlaylist?.announcementManagerState?.stateOfComponent.isEnlargePreview,
            isTabPlaylist: createPlaylist?.announcementManagerState?.stateOfComponent.isTabPlaylist,
            isPreviewOn: createPlaylist?.announcementManagerState?.stateOfComponent.isPreviewOn,
            isShowPlaceholder: createPlaylist?.announcementManagerState?.stateOfComponent.isShowPlaceholder,
            playlists: createPlaylist?.announcementManagerState?.stateOfComponent.playlists,
            audioOfSequences: createPlaylist?.announcementManagerState?.stateOfComponent.audioOfSequences,
            oldAudioOfSequences: createPlaylist?.announcementManagerState?.stateOfComponent.oldAudioOfSequences,
            folders: createPlaylist?.announcementManagerState?.stateOfComponent.folders,
            medias: createPlaylist?.announcementManagerState?.stateOfComponent.medias,
            playlistSelected: createPlaylist?.announcementManagerState?.stateOfComponent.playlistSelected,
            isTypeList: createPlaylist?.announcementManagerState?.stateOfComponent.isTypeList,
            folderSelected: createPlaylist?.announcementManagerState?.stateOfComponent.folderSelected,
            audioSelected: createPlaylist?.announcementManagerState?.stateOfComponent.audioSelected,
            audioOfSequenceSelected: createPlaylist?.announcementManagerState?.stateOfComponent.audioOfSequenceSelected,
            totalTime: createPlaylist?.announcementManagerState?.stateOfComponent.totalTime,
            pointPercents: createPlaylist?.announcementManagerState?.stateOfComponent.pointPercents,
            pointNumbers: createPlaylist?.announcementManagerState?.stateOfComponent.pointNumbers,
            audiosOfFolder: createPlaylist?.announcementManagerState?.stateOfComponent.audiosOfFolder,
            isChangeAudioOfSequence: createPlaylist?.announcementManagerState?.stateOfComponent.isChangeAudioOfSequence,
            oldAudiosOfFolder: createPlaylist?.announcementManagerState?.stateOfComponent.oldAudiosOfFolder,
            searchInputValue: createPlaylist?.announcementManagerState?.stateOfComponent.searchInputValue,
            isActiveButtonBack: createPlaylist?.announcementManagerState?.stateOfComponent.isActiveButtonBack,
            oldValuePlaylistName: createPlaylist?.announcementManagerState?.stateOfComponent.oldValuePlaylistName,
            oldValueFolderName: createPlaylist?.announcementManagerState?.stateOfComponent.oldValueFolderName,
            timeCursorPointSecond: createPlaylist?.announcementManagerState?.stateOfComponent.timeCursorPointSecond,
            colorBeingUsed: createPlaylist?.announcementManagerState?.stateOfComponent.colorBeingUsed,
            unUsedColors: createPlaylist?.announcementManagerState?.stateOfComponent.unUsedColors,
            isSelectedAudioOfSequence: createPlaylist?.announcementManagerState?.stateOfComponent.isSelectedAudioOfSequence,
            totalTimeVideo: createPlaylist?.announcementManagerState?.stateOfComponent.totalTimeVideo,
            isWaitingActionAPI: createPlaylist?.announcementManagerState?.stateOfComponent.isWaitingActionAPI
          };
        })
    );

    // store state
    this.subscriptions.push(
      this.store
        .select(state => state)
        .subscribe((componentState: any) => {
          this.miniMediaStateOfComponent = {
            folderSelected: componentState?.miniMediaState?.miniMediaStateOfComponent.folderSelected,
            folders: componentState?.miniMediaState?.miniMediaStateOfComponent.folders,
            medias: componentState?.miniMediaState?.miniMediaStateOfComponent.medias,
            audiosOfFolder: componentState?.miniMediaState?.miniMediaStateOfComponent.audiosOfFolder,
            mediaDragging: componentState?.miniMediaState?.miniMediaStateOfComponent.mediaDragging
          };
        })
    );

    this.commonObject = this.commonService.getCommonObject();
    this.moduleName = MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent];
  }

  async ngOnInit(): Promise<void> {
    this.languageKey = this.commonObject?.setting?.language;
    this.nowPlaying = this.translateService.instant('announcement-manager.now-playing');
    this.placeholderSearch = this.translateService.instant('announcement-manager.search');
    this.dataService.sendData([Constant.IS_TAB_PLAYLIST, true]);
    this.customTagService.getCustomTag().subscribe(data => {
      this.groupOriginal = data[1].elements;
    });
    this.sendTimezoneToBack();
    await this.getTimeDateLine();
    await this.getEndPoint();
    forkJoin([this.getAllPlaylists(), this.getAllDataCommonPlaylistRegistration()]);
    var interval = null;
    interval = setInterval(() => {
      if (this.customtagClones[0]) {
        this.openCustomTagElement(this.customtagClones[0]);
        this.selectDevicePlaylist(this.customtagClones[1]);
        clearInterval(interval);
      }
    }, 50);
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_AUTO_UPDATE_PLAYLIST).subscribe(data => {
      if (!data) {
        return;
      }
      this.autoUpdateObject = JSON.parse(data.value);
      if (this.autoUpdateObject.autoRefresh) {
        var interval = null;
        interval = setInterval(() => {
          if (this.customtagClones[0]) {
            this.intervalCallAPIUpdate(this.autoUpdateObject.autoRefresh.timeAutoRefresh * 1000 * 60);
            clearInterval(interval);
          }
        }, 50);
      }
    });
    this.getLanguagesTranslation();
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        if (
          this.moduleName == MODULE_NAME[FIELD_COMPONENT.AnnouncementManagerComponent] &&
          data[1] &&
          this.tabSelected == Constant.PLAYLIST_ENUM
        ) {
          this.selectDevicePlaylist(this.deviceSelectedPlaylist);
        }
        this.getLanguagesTranslation();
      }
    });
    this.getBucketSave();
    this.dataService.currentData.subscribe(data => {
      if (data[0] == Constant.IS_CHANGE_TIME_ZONE) {
        if (data[1]) {
          this.sendTimezoneToBack();
        }
      }
    });
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_GLOSSARY).subscribe(data => {
      if (data) {
        this.fileGlossary = Object.keys(JSON.parse(data.value))[0];
      }
    });
  }

  /**
   * set Default Translation
   */
  private setDefaultTranslation(): void {
    this.translation = new Translation();
    this.translation.baseLanguageCode = this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey;
    this.translation.sourceLanguageCode = this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey;
  }

  /**
   * set Default Speech
   */
  private setDefaultSpeech(): void {
    this.listActive = new Set<string>();
    const LENGTH_ARRAY = 7;
    this.listSpeechSynthesis = [];
    for (let i = 0; i < LENGTH_ARRAY; i++) {
      let speechSynthesis = new SpeechSynthesis();
      this.listSpeechSynthesis.push(speechSynthesis);
    }
    this.isCheckedAllLanguage = false;
    this.listSpeechSynthesisCloneDeep = _.cloneDeep(this.listSpeechSynthesis);
  }

  ngDoCheck() {
    if (this.grayOut) {
      return;
    }
    const dateNow = new Date(Helper.getCurrentDateByTimeZone(this.commonObject));
    const time = moment(dateNow)
      .format(this.formatTime)
      .replace(/:?/g, '');
    let timeInt = parseInt(time) + (this.formatTime == 'HH:mm:ss' ? this.timeAdd * 10000 : this.timeAdd * 100);
    if (timeInt < this.numTimeDateLine) {
      timeInt = timeInt + 240000;
    }
    const indexLiveTime = this.announcementManagerService?.listScheduleCanRun?.findIndex(playlist => {
      return playlist.audioFiles.some(audio => audio.originalIndex === this.indexLiveTime);
    });
    let isbBeak = false;
    for (var i = indexLiveTime == -1 ? 0 : indexLiveTime; i < this.announcementManagerService?.listScheduleCanRun?.length; i++) {
      this.announcementManagerService?.listScheduleCanRun[i].audioFiles.forEach(e => {
        let timeRowStart = parseInt(e.startTime.replace(/:?/g, ''));
        let timeRowEnd = parseInt(e.endTime.replace(/:?/g, ''));
        if (timeRowStart < this.numTimeDateLine) {
          timeRowStart = timeRowStart + 240000;
          timeRowEnd = timeRowEnd + 240000;
        } else if (timeRowEnd < this.numTimeDateLine) {
          timeRowEnd = timeRowEnd + 240000;
        }
        if (timeRowStart < timeInt && timeRowEnd > timeInt) {
          if (e.originalIndex) this.indexLiveTime = _.cloneDeep(e.originalIndex);
          this.isLiveTimeRunning = true;
        } else if (timeInt > timeRowEnd) {
          if (e.originalIndex) this.indexLiveTime = _.cloneDeep(e.originalIndex);
          this.isLiveTimeRunning = false;
        } else if (timeRowStart > timeInt) {
          isbBeak = true;
          return;
        }
      });
      if (isbBeak) {
        break;
      }
    }
  }

  /**
   * on destroy
   */
  ngOnDestroy(): void {
    this.subscriptions.forEach(subscription => subscription.unsubscribe());
    this.moduleName = undefined;
    clearInterval(this.idInterval);
    clearInterval(this.idIntervalMedia);
    clearInterval(this.intervalAutoUpdate);
    this.clearPreview();
    this.clearPreviewMedia();
    // this.store.dispatch(
    //   new SaveAnnouncementManagerStateAction({
    //     isChangeLayout: true,
    //     isEnlargePreview: this.isEnlargePreview,
    //     isPreviewOn: this.isPreviewOn,
    //     isShowPlaceholder: this.isShowPlaceholder,
    //     playlists: this.playlists,
    //     audioOfSequences: this.audioOfSequences,
    //     oldAudioOfSequences: this.oldAudioOfSequences,
    //     folders: this.folders,
    //     medias: this.medias,
    //     playlistSelected: this.playlistSelected,
    //     isTypeList: this.isTypeList,
    //     folderSelected: this.folderSelected,
    //     audioSelected: this.audioSelected,
    //     audioOfSequenceSelected: this.audioOfSequenceSelected,
    //     totalTime: this.totalTime,
    //     pointPercents: this.pointPercents,
    //     pointNumbers: this.pointNumbers,
    //     audiosOfFolder: this.audiosOfFolder,
    //     isChangeAudioOfSequence: !this.checkDiffDataMediaOfSequences(),
    //     oldAudiosOfFolder: this.oldAudiosOfFolder,
    //     searchInputValue: this.searchInputValue,
    //     isActiveButtonBack: this.isActiveButtonBack,
    //     oldValuePlaylistName: this.oldValuePlaylistName,
    //     oldValueFolderName: this.oldValueFolderName,
    //     timeCursorPointSecond: this.timeCursorPointSecond,
    //     isSelectedAudioOfSequence: this.isSelectedAudioOfSequence,
    //     totalTimeVideo: this.totalTimeVideo,
    //     isWaitingActionAPI: this.isWaitingActionAPI
    //   })
    // );
    // delete this.commonObject.isTabPlaylist;
    // delete this.commonObject.isTabCreatePlaylist;
    // delete this.commonObject.isTabCommonPlaylist;
    // delete this.commonObject.isTabRegisterPlaylist;
  }

  /**
   * handle after change layout
   */
  public handleAfterChangeLayout(): void {
    this.isEnlargePreview = this.stateOfComponent?.isEnlargePreview;
    this.isPreviewOn = this.stateOfComponent?.isPreviewOn;
    this.isShowPlaceholder = this.stateOfComponent?.isShowPlaceholder;
    this.playlists = this.stateOfComponent?.playlists;
    this.audioOfSequences = this.stateOfComponent?.audioOfSequences;
    this.oldAudioOfSequences = this.stateOfComponent?.oldAudioOfSequences;
    this.folders = this.stateOfComponent?.folders;
    this.medias = this.stateOfComponent?.medias;
    this.playlistSelected = this.stateOfComponent?.playlistSelected;
    this.playlistSelectedClone = _.cloneDeep(this.playlistSelected);
    this.isTypeList = this.stateOfComponent?.isTypeList;
    this.folderSelected = this.stateOfComponent?.folderSelected;
    this.audioSelected = this.stateOfComponent?.audioSelected;
    this.audioOfSequenceSelected = this.stateOfComponent?.audioOfSequenceSelected;
    this.totalTime = this.stateOfComponent?.totalTime;
    this.pointPercents = this.stateOfComponent?.pointPercents;
    this.pointNumbers = this.stateOfComponent?.pointNumbers;
    this.audiosOfFolder = this.stateOfComponent?.audiosOfFolder;
    this.isChangeAudioOfSequence = this.stateOfComponent?.isChangeAudioOfSequence;
    this.oldAudiosOfFolder = this.stateOfComponent?.oldAudiosOfFolder;
    this.searchInputValue = this.stateOfComponent?.searchInputValue;
    this.isActiveButtonBack = this.stateOfComponent?.isActiveButtonBack;
    this.oldValuePlaylistName = this.stateOfComponent?.oldValuePlaylistName;
    this.oldValueFolderName = this.stateOfComponent?.oldValueFolderName;
    this.timeCursorPointSecond = this.stateOfComponent?.timeCursorPointSecond;
    this.isSelectedAudioOfSequence = this.stateOfComponent?.isSelectedAudioOfSequence;
    this.totalTimeVideo = this.stateOfComponent?.totalTimeVideo;
    this.isWaitingActionAPI = this.stateOfComponent?.isWaitingActionAPI;
    this.isChangeCommonRegistration = this.stateOfComponent?.isChangeCommonRegistration;

    this.dataService.sendData([this.IS_CHOSE_TAB_PLAYLIST, this.tabSelected == Constant.PLAYLIST_ENUM]);
    this.dataService.sendData([this.IS_CHOSE_TAB_CREATE_PLAYLIST, this.tabSelected == Constant.CREATE_SEQUENCE_ENUM]);
    this.dataService.sendData([this.IS_CHOSE_TAB_COMMON_PLAYLIST, this.tabSelected == Constant.COMMON_SEQUENCE_ENUM]);
    this.dataService.sendData([this.IS_CHOSE_TAB_REGISTER_PLAYLIST, this.tabSelected == Constant.SPECIFIC_SEQUENCE_ENUM]);
    this.dataService.sendData([this.IS_EDIT_PLAYLIST, this.playlistSelected?.isEdit]);
    this.dataService.sendData([this.IS_EDIT_FOLDER, this.folderSelected?.isEdit]);
    this.dataService.sendData([this.IS_AUDIO_SILENCE, !this.audioOfSequenceSelected?.idMedia]);
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
    this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
    this.commonObject.isTabCreatePlaylist = this.tabSelected == Constant.CREATE_SEQUENCE_ENUM;
    this.totalSize = this.stateOfComponent?.totalSize;
    Helper.saveMainStateAction(this.store, this.commonObject);
    if (this.tabSelected == Constant.CREATE_SEQUENCE_ENUM && (this.audioOfSequenceSelected || this.audioSelected)) {
      if (this.audioOfSequenceSelected) {
        this.resetCursorPoint();
      }
      setTimeout(() => {
        this.redrawAudio(this.tabSelected == Constant.CREATE_SEQUENCE_ENUM);
      });
    }
  }

  /**
   * get all data tab create playlist
   */
  public getAllDataTabCreatePlaylist(): void {
    forkJoin([this.getAllPlaylists(), this.getDataMiniMedia()]);
  }

  /**
   * choose tab
   * @param tabEnum
   */
  public chooseTab(tabEnum: any): void {
    if (this.tabSelected == tabEnum) {
      return;
    }

    // confirm save if change data
    this.isChangeAudioOfSequence = !this.checkDiffDataAudioOfSequences();
    this.isChangeCommonRegistration = !this.checkDiffDataCommonPlaylistRegistration();
    if (
      (this.playlistSelected?.isEdit || this.isChangeAudioOfSequence || this.folderSelected?.isEdit) &&
      this.tabSelected == Constant.CREATE_SEQUENCE_ENUM
    ) {
      this.handleChangeTabFromCreatePlaylistTab(tabEnum);
    } else if (this.tabSelected == Constant.COMMON_SEQUENCE_ENUM && this.isChangeCommonRegistration) {
      this.handleChangeTabFromCommonPlaylistTab(tabEnum);
    } else if (this.tabSelected == Constant.SPECIFIC_SEQUENCE_ENUM && this.isChangeSpecificRegistration) {
      this.handleChangeTabFromSpecificTab(tabEnum);
    } else {
      this.switchTab(tabEnum);
    }
  }

  /**
   * handle change tab from common playlist tab
   * @param tabEnum
   */
  public handleChangeTabFromCommonPlaylistTab(tabEnum: any): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('announcement-manager.save-changes'),
          button1: this.translateService.instant('announcement-manager.yes'),
          button2: this.translateService.instant('announcement-manager.no')
        }
      },
      result => {
        if (result) {
          let saveListCommon = this.listCommonPlaylistRegistration?.filter(common => !common.isGroup);
          saveListCommon = Helper.convertCommonPlaylistRegistrationsToBackend(saveListCommon);
          let customList = this.customtagClones.filter(cusomtag => cusomtag.isChecking);
          if (customList.length > 1) {
            this.dialogService.showDialog(
              DialogConfirmComponent,
              {
                data: {
                  title: this.translateService.instant('announcement-manager.error'),
                  text: this.translateService.instant('announcement-manager.multi-select'),
                  button1: this.translateService.instant('announcement-manager.yes'),
                  button2: this.translateService.instant('announcement-manager.no')
                }
              },
              result => {
                if (result) {
                  if (this.validateBeforeSaveCommonRegistration(saveListCommon)) {
                    let el: HTMLElement = this.commonPlaylistTab.nativeElement;
                    el.click();
                    el.focus();
                    return;
                  } else {
                    this.announcementManagerService.saveCommonRegistration(saveListCommon).subscribe(data => {
                      this.saveDataSuccess.emit(true);
                      this.handleDataAfterGetCommonRegistration(data);
                    });
                    this.switchTab(tabEnum);
                  }
                } else {
                  this.switchTab(tabEnum);
                  return;
                }
              }
            );
          } else {
            if (this.validateBeforeSaveCommonRegistration(saveListCommon)) {
              let el: HTMLElement = this.commonPlaylistTab.nativeElement;
              el.click();
              el.focus();
              return;
            } else {
              this.announcementManagerService.saveCommonRegistration(saveListCommon).subscribe(data => {
                this.saveDataSuccess.emit(true);
                this.handleDataAfterGetCommonRegistration(data);
              });
              this.switchTab(tabEnum);
            }
          }
        } else {
          this.switchTab(tabEnum);
        }
      }
    );
  }

  /**
   * handle change tab from common playlist tab
   * @param tabEnum
   */
  private handleChangeTabFromSpecificTab(tabEnum: any): void {
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('announcement-manager.save-changes'),
          button1: this.translateService.instant('announcement-manager.yes'),
          button2: this.translateService.instant('announcement-manager.no')
        }
      },
      result => {
        if (result) {
          let deviceChecking = this.listCommonPlaylistRegistration?.filter(device => device.isChecking && !device.isGroup);
          const playlistChecking = this.playlistsSpecific?.filter(e => e.isChecking);
          if (deviceChecking.length > 0 && !this.deviceSelected.isChecking) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.translateService.instant('announcement-manager.select-device')
              }
            });
            let el: HTMLElement = this.specificPlaylistTab.nativeElement;
            el.click();
            el.focus();
            return;
          }
          if (deviceChecking.length > 1) {
            this.dialogService.showDialog(
              DialogConfirmComponent,
              {
                data: {
                  title: this.translateService.instant('dialog-confirm.title'),
                  text: this.translateService.instant('announcement-manager.multi-select'),
                  button1: this.translateService.instant('announcement-manager.yes'),
                  button2: this.translateService.instant('announcement-manager.no')
                }
              },
              result => {
                if (!result) {
                  this.switchTab(tabEnum);
                } else {
                  if (this.validateSavePlaylistSpecific()) {
                    let el: HTMLElement = this.specificPlaylistTab.nativeElement;
                    el.click();
                    el.focus();
                    return;
                  }
                  deviceChecking.forEach(device => {
                    device.playlistSpecific = playlistChecking;
                  });
                  this.saveSequenceRegistration(true, tabEnum);
                }
              }
            );
            return;
          } else {
            if (this.validateSavePlaylistSpecific()) {
              let el: HTMLElement = this.specificPlaylistTab.nativeElement;
              el.click();
              el.focus();
              return;
            }
            this.saveSequenceRegistration(true, tabEnum);
          }
        } else {
          this.switchTab(tabEnum);
        }
      }
    );
  }

  /**
   * handle change tab from create playlist tab
   * @param tabEnum
   */
  public handleChangeTabFromCreatePlaylistTab(tabEnum: any): void {
    let checkChange = false;
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: this.translateService.instant('announcement-manager.save-changes'),
          button1: this.translateService.instant('announcement-manager.yes'),
          button2: this.translateService.instant('announcement-manager.no')
        }
      },
      result => {
        if (result) {
          if (this.playlistSelected?.isEdit) {
            let i = 0;
            if (!this.validatePlaylist()) {
              i = 1;
              let el: HTMLElement = this.createPlaylistTab.nativeElement;
              el.click();
              el.focus();
              return;
            } else {
              // validate exists playlist
              this.announcementManagerService.checkExistPlaylist(this.playlistSelected.name, this.playlistSelected.id).subscribe(data => {
                if (data) {
                  i = 1;
                  let el: HTMLElement = this.createPlaylistTab.nativeElement;
                  el.click();
                  el.focus();
                  this.dialogService.showDialog(DialogMessageComponent, {
                    data: {
                      title: this.translateService.instant('announcement-manager.error'),
                      text: this.translateService.instant('announcement-manager.exists-pl')
                    }
                  });
                  return;
                } else {
                  i = -1;
                }
              });
            }
            var interval = null;
            interval = setInterval(() => {
              if (i == -1) {
                this.savePlaylist(true);
                clearInterval(interval);
              } else if (i == 1) {
                clearInterval(interval);
              }
            }, 50);
          }
          if (this.folderSelected?.isEdit) {
            let i = 0;
            if (!this.validateFolder()) {
              i = 1;
              let el: HTMLElement = this.createPlaylistTab.nativeElement;
              el.click();
              el.focus();
              return;
            } else {
              // validate exists folder
              this.announcementManagerService.checkExistFolder(this.folderSelected.name, this.folderSelected.id).subscribe(data => {
                if (data) {
                  i = 1;
                  let el: HTMLElement = this.createPlaylistTab.nativeElement;
                  el.click();
                  el.focus();
                  this.dialogService.showDialog(DialogMessageComponent, {
                    data: {
                      title: this.translateService.instant('announcement-manager.error'),
                      text: this.translateService.instant('announcement-manager.exists-folder')
                    }
                  });
                  return false;
                } else {
                  i = -1;
                }
              });
            }
            var interval1 = null;
            interval1 = setInterval(() => {
              if (i == -1) {
                this.saveFolder(true);
                clearInterval(interval1);
              } else if (i == 1) {
                clearInterval(interval1);
              }
            }, 50);
          }
          if (this.isChangeAudioOfSequence && Helper.convertDuration(this.totalTime) > this.MAX_TOTAL_TIME) {
            let el: HTMLElement = this.createPlaylistTab.nativeElement;
            el.click();
            el.focus();
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('announcement-manager.error'),
                text: this.translateService.instant('announcement-manager.max-total')
              }
            });
            return;
          } else if (this.isChangeAudioOfSequence) {
            this.saveTabPlaylist(tabEnum);
            checkChange = true;
          }
          if (!checkChange) {
            this.switchTab(tabEnum);
            this.handleAfterLeaveCretePlaylist();
          }
        } else {
          if (this.playlistSelected?.isEdit) {
            this.cancelAddPlaylist();
          }
          if (this.isChangeAudioOfSequence) {
            this.cancelSequence();
          }
          if (this.folderSelected?.isEdit) {
            this.cancelAddFolder();
          }
          this.switchTab(tabEnum);
        }
      }
    );
  }

  /**
   * handleAfterLeaveCretePlaylist
   */
  private handleAfterLeaveCretePlaylist(): void {
    setTimeout(() => {
      this.isChangeAudioOfSequence = false;
      this.isChangeCommonRegistration = false;
      this.isChangeSpecificRegistration = false;
    }, 1000);
  }

  /**
   * switch tab
   * @param tabEnum
   */
  public switchTab(tabEnum: any) {
    this.tabSelected = tabEnum;
    this.isCheckedAll = false;
    this.dataService.sendData([Constant.IS_TAB_PLAYLIST, tabEnum == Constant.PLAYLIST_ENUM]);
    this.dataService.sendData([Constant.IS_TAB_CREATE_PLAYLIST, tabEnum == Constant.CREATE_SEQUENCE_ENUM]);
    this.dataService.sendData([Constant.IS_TAB_COMMON_PLAYLIST, tabEnum == Constant.COMMON_SEQUENCE_ENUM]);
    this.dataService.sendData([Constant.IS_TAB_REGISTER_PLAYLIST, tabEnum == Constant.SPECIFIC_SEQUENCE_ENUM]);
    this.dataService.sendData([
      Constant.IS_TAB_TRANSLATION_AND_SPEECH_SYNTHESIS,
      tabEnum == Constant.TRANSLATION_AND_SPEECH_SYNTHESIS_ENUM
    ]);
    if (tabEnum != Constant.CREATE_SEQUENCE_ENUM) {
      this.totalTime = undefined;
      this.playlistGroupSelected = undefined;
      this.groupPlaylistCommon.isExpand = false;
      this.groupPlaylistSpecial.isExpand = false;
      this.audioOfSequenceSelected = undefined;
      if (this.isPreviewOn) {
        if (this.audioSelected) this.pauseAudio();
        else this.pauseSequence();
      }
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      this.clearPreview();
      this.clearPreviewMedia();
      this.listCommonPlaylistRegistration = new Array();
      this.oldCommonPlaylistRegistrations = new Array();
    }
    if (tabEnum != Constant.COMMON_SEQUENCE_ENUM) {
      this.listCommonPlaylistRegistration = new Array();
      this.oldCommonPlaylistRegistrations = new Array();
      this.customtagClones = new Array();
    }
    if (this.tabSelected != Constant.SPECIFIC_SEQUENCE_ENUM) {
      this.isChangeSpecificRegistration = false;
      this.listCommonPlaylistRegistration?.forEach(e => (e.isChecking = false));
      this.playlistsSpecific?.forEach(item => (item.isChecking = false));
      this.deviceSelected = undefined;
    }
    if (this.tabSelected != Constant.PLAYLIST_ENUM) {
      this.listCommonPlaylistRegistration = new Array();
      this.oldCommonPlaylistRegistrations = new Array();
      this.customtagClones = new Array();
      this.indexLiveTime = 0;
      this.isLiveTimeRunning = false;
      this.announcementManagerService.listScheduleFromAPI = null;
      this.announcementManagerService.listScheduleCanRun = null;
      this.schedules = null;
    }
    switch (tabEnum) {
      case Constant.PLAYLIST_ENUM:
        forkJoin([this.getAllPlaylists(), this.getAllDataCommonPlaylistRegistration()]);
        var interval = null;
        interval = setInterval(() => {
          if (this.customtagClones[0]) {
            this.openCustomTagElement(this.customtagClones[0]);
            this.selectDevicePlaylist(this.customtagClones[1]);
            clearInterval(interval);
          }
        }, 50);
        break;
      case Constant.CREATE_SEQUENCE_ENUM:
        this.getAllDataTabCreatePlaylist();
        break;
      case Constant.COMMON_SEQUENCE_ENUM:
        this.getAllDataCommonPlaylistRegistration();
        var interval = null;
        interval = setInterval(() => {
          if (this.customtagClones[0]) {
            this.openCustomTagElement(this.customtagClones[0]);
            this.selectDevice(this.customtagClones[1]);
            clearInterval(interval);
          }
        }, 50);
        break;
      case Constant.SPECIFIC_SEQUENCE_ENUM:
        forkJoin([this.getAllPlaylists(), this.getAllDataCommonPlaylistRegistration()]);
        var interval = null;
        interval = setInterval(() => {
          if (this.customtagClones[0] && this.playlistsSpecific) {
            this.openCustomTagElement(this.customtagClones[0]);
            this.selectDeviceRegistration(this.customtagClones[1], null);
            clearInterval(interval);
          }
        }, 50);
        break;
      case Constant.TRANSLATION_AND_SPEECH_SYNTHESIS_ENUM:
        break;
      default:
        break;
    }
  }

  /**
   * save changed data when switching to Calendar tab
   * @returns
   */
  public saveTabPlaylist(tabEnum: any): void {
    if (!this.validatePlaylist() || !this.validateSequence() || !this.validateFolder()) {
      return;
    }
    forkJoin({
      checkExistPlaylist: this.announcementManagerService.checkExistPlaylist(this.playlistSelected.name, this.playlistSelected.id),
      checkExistFolder: this.announcementManagerService.checkExistFolder(this.folderSelected?.name, this.folderSelected?.id)
    }).subscribe(data => {
      if (!data) {
        return;
      }
      if (data.checkExistPlaylist) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('announcement-manager.error'),
            text: this.translateService.instant('announcement-manager.exists-pl')
          }
        });
        return;
      }
      if (data.checkExistFolder) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('announcement-manager.error'),
            text: this.translateService.instant('announcement-manager.exists-folder')
          }
        });
        return;
      }

      if (this.playlistSelected?.isEdit) {
        // get information to save data
        let playlistToSave = _.cloneDeep(this.playlistSelected);
        if (playlistToSave.id) {
          playlistToSave.sequence = this.handleSequenceBeforeSave();
        }
        this.announcementManagerService.savePlaylist(playlistToSave).subscribe(data => {
          // this.handleAfterSavePlaylist(data, playlistToSave);
        });
      } else if (this.isChangeAudioOfSequence) {
        // get information to save data
        let playlist = _.cloneDeep(this.playlistSelected);
        playlist.sequence = this.handleSequenceBeforeSave();
        this.announcementManagerService.savePlaylist(playlist).subscribe(data => {
          // this.handleAfterSaveSequence(playlist);
        });
      }

      if (this.folderSelected?.isEdit) {
        // get information folder to save data
        let folderToSave = _.cloneDeep(this.folderSelected);
        this.announcementManagerService.saveFolder(folderToSave).subscribe(data => {
          this.handleAfterSaveFolder(data, folderToSave);
        });
      }
      this.switchTab(tabEnum);
      this.handleAfterLeaveCretePlaylist();
    });
  }

  /**
   * handle mouse over
   * @param folder
   */
  public handleMouseOver(folder: AnnouncementFolder): void {
    this.clearAttributeOfFolder(this.folders.filter(item => item.id != folder.id));
    this.isShowPlaceholder = false;
    if (this.isDraggingMedia) {
      folder[this.IS_MOVING_CSS] = true;
      folder[this.IS_DRAGGING_MEDIA] = true;
      this.folderNameContains = folder.name;
    }
  }

  /**
   * clear attribute of folder
   * @param folders
   */
  private clearAttributeOfFolder(folders: Array<AnnouncementFolder>): void {
    folders.forEach(data => {
      delete data[this.IS_MOVING_CSS];
      delete data[this.IS_DRAGGING_MEDIA];
    });
  }

  /**
   * select playlist
   * @param playlist
   * @param isCheckDiffData
   * @returns
   */
  public selectPlaylist(playlist: AnnouncementPlaylist, isCheckDiffData?: boolean, event?: any): void {
    if (this.playlistSelected?.isEdit || this.isPreviewOn || event?.target?.id === 'deletePlaylist') {
      return;
    }
    this.playlistGroupSelected = undefined;
    // confirm save if change data
    this.isChangeAudioOfSequence = isCheckDiffData && playlist.id != this.playlistSelected?.id && !this.checkDiffDataAudioOfSequences();
    if (this.isChangeAudioOfSequence) {
      this.isChangedData = true;
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.save-changes'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          this.isChangedData = false;
          if (result) {
            this.saveSequence(true);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.audioOfSequences = null;
                this.selectPlaylist(playlist);
              }
            });
          } else {
            this.audioOfSequences = null;
            this.selectPlaylist(playlist);
          }
        }
      );
    } else {
      this.playlistSelected = playlist;
      this.playlistSelectedClone = _.cloneDeep(this.playlistSelected);
      // get sequence of playlist
      this.announcementManagerService.getSequenceOfPlaylist(this.playlistSelected.id).subscribe(
        data => {
          this.audioOfSequences = Helper.convertDataListAudioOfSequence(data);
          if (this.audioOfSequences && this.audioOfSequences.length > 0) {
            this.audioOfSequences = _.cloneDeep(this.convertSequenceToSilenceSequence(this.audioOfSequences));
          }
          this.handleDuration();
          this.calculatorPointList();
          this.oldAudioOfSequences = _.cloneDeep(!this.audioOfSequences ? new Array<AudioOfSequence>() : this.audioOfSequences);
          for (let i = 1; i <= this.audioOfSequences.length; i += 2) {
            this.loadFileTemp(this.audioOfSequences[i]);
          }
          this.selectAudioOfSequence(this.audioOfSequences[0]);
        },
        error => Helper.handleError(error, this.translateService, this.dialogService)
      );
    }
  }

  /**
   * check diff data media of sequence
   * @returns
   */
  private checkDiffDataAudioOfSequences(): boolean {
    let oldData = Helper.convertDataToCheckDiffDataAnnouncement(
      !this.oldAudioOfSequences ? new Array<AudioOfSequence>() : this.oldAudioOfSequences
    );
    oldData = oldData.filter((data, index) => index % 2 != 0);
    let audioOfSequencesNew = _.cloneDeep(!this.audioOfSequences ? new Array<AudioOfSequence>() : this.audioOfSequences);
    let newData = Helper.convertDataToCheckDiffDataAnnouncement(audioOfSequencesNew);
    newData = newData.filter((data, index) => index % 2 != 0);
    // return true if equals
    return JSON.stringify(oldData) == JSON.stringify(newData);
  }

  /**
   * save sequence
   * @param isSelectOtherPlaylist
   */
  public saveSequence(isSelectOtherPlaylist: boolean): void {
    // validate
    if (this.isPreviewOn || !this.validateSequence()) {
      this.saveDataSuccess.emit(false);
      return;
    }
    let playlist: AnnouncementPlaylist = _.cloneDeep(this.playlistSelected);
    playlist.sequence = this.handleSequenceBeforeSave();
    // save data
    this.announcementManagerService.savePlaylist(playlist).subscribe(
      () => {
        this.saveDataSuccess.emit(true);
        if (isSelectOtherPlaylist) {
          return;
        }
        this.handleAfterSaveSequence(playlist);
      },
      error => {
        this.saveDataSuccess.emit(false);
        Helper.handleError(error, this.translateService, this.dialogService);
      }
    );
  }

  /**
   * Handle duration value
   */
  private handleDuration(): void {
    const TIME_START = '00:00:00';
    this.totalSize = 0;
    const calculatedIdMedia = new Set();
    this.audioOfSequences?.forEach((media, index) => {
      if (index == 0) {
        media.startTime = TIME_START;
      }
      if (!calculatedIdMedia.has(media.idMedia)) {
        this.totalSize += media.size;
        calculatedIdMedia.add(media.idMedia);
      }
      if (index + 1 == this.audioOfSequences.length) {
        return;
      }
      const time = Helper.convertDuration(media.startTime ?? TIME_START);
      const startTimeNext = media.duration + time;
      this.audioOfSequences[index + 1].startTime = Helper.formatDurationSimpleSignage(startTimeNext);
    });
    this.totalSize = _.round(this.totalSize / 1048576, 2);
    const mediaEnd = this.audioOfSequences[this.audioOfSequences.length - 1];
    const totalDuration = Helper.convertDuration(mediaEnd?.startTime ?? TIME_START) + +mediaEnd?.duration;
    this.totalTime = Helper.formatDurationSimpleSignage(`${isNaN(totalDuration) ? 0 : totalDuration}`);
  }

  /**
   * Handle duration old
   */
  private handleDurationOld(): void {
    const TIME_START = '00:00:00';
    this.oldAudioOfSequences?.forEach((media, index) => {
      if (index == 0) {
        media.startTime = TIME_START;
      }
      if (index + 1 == this.oldAudioOfSequences.length) {
        return;
      }
      const time = Helper.convertDuration(media.startTime ?? TIME_START);
      const startTimeNext = media.duration + time;
      this.oldAudioOfSequences[index + 1].startTime = Helper.formatDurationSimpleSignage(startTimeNext);
    });
  }

  /**
   * select media of sequence
   * @param media
   */
  public selectAudioOfSequence(media: AudioOfSequence): void {
    if (this.isPreviewOn || this.folderSelected?.isEdit || this.playlistSelected?.isEdit) {
      return;
    }
    this.audioOfSequenceSelected = media;
    this.audioSelected = undefined;
    this.folderSelected = undefined;
    this.calculatorPointList();
    const index = this.audioOfSequences?.findIndex(media => media?.startTime == this.audioOfSequenceSelected?.startTime);
    if (index == -1) {
      this.isSelectedAudioOfSequence = false;
    } else {
      this.isSelectedAudioOfSequence = true;
    }
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
    this.dataService.sendData([this.IS_AUDIO_SILENCE, !this.audioOfSequenceSelected?.idMedia]);
    this.valueCursorPoint = this.calculateCursorPoint(index);
    this.timeCursorPointSecond = Helper.convertDuration(this.audioOfSequences[index]?.startTime);
    this.isSelectedAudioOrFolder = false;
    this.clearPreview();
    this.previewAudioOfSequence();
  }

  /**
   * validate sequence
   * @returns
   */
  // validate duration
  private validateSequence(): boolean {
    if (!this.validateDurationMediaOfSequence()) {
      return false;
    }

    // validate total size
    let totalSize = 0;
    const calculatedIdMedia = new Set();
    let audioOfSequenceArray: Array<AudioOfSequence> = _.cloneDeep(this.audioOfSequences);

    audioOfSequenceArray.forEach(media => {
      if (!calculatedIdMedia.has(media.idMedia)) {
        totalSize += media.size;
        calculatedIdMedia.add(media.idMedia);
      }
    });
    if (totalSize > Constant.MAXIMUM_FILE_SIZE_AUDIO) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.maximum-file-size-save')
        }
      });
      return false;
    }
    // validate total time
    if (Helper.convertDuration(this.totalTime) > this.MAX_TOTAL_TIME) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.max-total')
        }
      });
      return false;
    }
    return true;
  }

  /**
   * validate duration media of sequence
   * @returns
   */
  private validateDurationMediaOfSequence(): boolean {
    if (this.audioOfSequences.some(media => this.checkDurationEmpty(media) || media.duration == 0)) {
      this.audioOfSequences.forEach(media => {
        media.isInValidTime = this.checkDurationEmpty(media) || media.duration == 0;
      });
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.invalid-duration')
        }
      });
      return false;
    }
    return true;
  }

  /**
   * check duration empty
   * @param media
   * @returns
   */
  private checkDurationEmpty(media: AudioOfSequence): boolean {
    return !`${media.hour}`?.trim().length || !`${media.minute}`?.trim().length || !`${media.second}`?.trim().length;
  }

  /**
   * handle sequence before save
   * @returns string media of sequences
   */
  private handleSequenceBeforeSave(): string {
    let audioOfSequences: Array<AudioOfSequence> = _.cloneDeep(this.audioOfSequences);
    if (!audioOfSequences?.length) {
      return null;
    }
    audioOfSequences = audioOfSequences.filter(audioOfSequence => audioOfSequence.idMedia);
    audioOfSequences.forEach(media => {
      media.name = media.name;
      const mediaUrl = `${media.name}.${media.type}`;
      media.url = media.folderS3Name ? `${media.folderS3Name}/${mediaUrl}` : mediaUrl;
    });
    return JSON.stringify(audioOfSequences);
  }

  /**
   * handle after save Sequence
   *
   * @param playlist
   * @returns
   */
  private handleAfterSaveSequence(playlist: AnnouncementPlaylist): void {
    let playlistClone =
      this.playlistSelected.playlistType == Constant.TYPE_PLAYLIST_GROUP_COMMON ? this.playlistsCommon : this.playlistsSpecific;
    const index = playlistClone.findIndex(data => data.id == playlist.id);
    if (index == -1) {
      return;
    }
    this.audioOfSequences = null;
    this.playlistSelected.isEdit = false;
    this.selectPlaylist(playlistClone[index]);
  }

  /**
   * reset cursor point
   */
  private resetCursorPoint(): void {
    if (this.timeCursorPointSecond >= Helper.convertDuration(this.totalTime)) {
      this.timeCursorPointSecond = Helper.convertDuration(this.totalTime);
      this.valueCursorPoint = this.MAX_WIDTH_TIME_LINE - this.CURSOR_POINT;
    } else {
      this.timeCursorPointSecond = Helper.convertDuration(this.audioOfSequenceSelected.startTime);
      const index = this.audioOfSequences.findIndex(media => media.randomNumber == this.audioOfSequenceSelected.randomNumber);
      this.valueCursorPoint = this.calculateCursorPoint(index);
    }
  }

  /**
   * calculate cursor point
   * @param index
   * @returns
   */
  private calculateCursorPoint(index: number): number {
    let result = 0;
    if (index == 0) {
      return result;
    }
    for (let i = 0; i < index; i++) {
      result += this.pointPercents[i];
    }
    return result - 2;
  }

  /**
   * save playlist
   *
   */
  public savePlaylist(checkExits?: boolean): boolean {
    if (this.isPreviewOn || this.isWaitingActionAPI) {
      return true;
    }
    this.isWaitingActionAPI = true;
    // validate playlist
    if (!this.validatePlaylist()) {
      this.saveDataSuccess.emit(false);
      this.isWaitingActionAPI = false;
      return false;
    }
    if (!checkExits) {
      // validate exists playlist
      this.announcementManagerService.checkExistPlaylist(this.playlistSelected.name, this.playlistSelected.id).subscribe(
        data => {
          if (data) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('announcement-manager.error'),
                text: this.translateService.instant('announcement-manager.exists-pl')
              }
            });
            this.isWaitingActionAPI = false;
            return false;
          }
          // get information to save data
          let playlistToSave = _.cloneDeep(this.playlistSelected);
          playlistToSave.name = playlistToSave.name;
          if (playlistToSave.id) {
            playlistToSave.sequence = this.handleSequenceBeforeSave();
          }
          // save data
          this.announcementManagerService.savePlaylist(playlistToSave).subscribe(
            data => {
              this.saveDataSuccess.emit(true);
              this.handleAfterSavePlaylist(data, playlistToSave);
              return true;
            },
            error => {
              this.isWaitingActionAPI = false;
              this.saveDataSuccess.emit(false);
              this.handleErrorSaveData(error, this.translateService.instant('announcement-manager.exists-pl'));
              return false;
            }
          );
        },
        error => {
          this.isWaitingActionAPI = false;
          this.saveDataSuccess.emit(false);
          Helper.handleError(error, this.translateService, this.dialogService);
          return false;
        }
      );
    } else {
      // get information to save data
      let playlistToSave = _.cloneDeep(this.playlistSelected);
      playlistToSave.name = playlistToSave.name;
      if (playlistToSave.id) {
        playlistToSave.sequence = this.handleSequenceBeforeSave();
      }
      // save data
      this.announcementManagerService.savePlaylist(playlistToSave).subscribe(
        data => {
          this.saveDataSuccess.emit(true);
          this.handleAfterSavePlaylist(data, playlistToSave);
          return true;
        },
        error => {
          this.isWaitingActionAPI = false;
          this.saveDataSuccess.emit(false);
          this.handleErrorSaveData(error, this.translateService.instant('announcement-manager.exists-pl'));
          return false;
        }
      );
    }
  }

  /**
   * handle after save Playlist
   *
   * @param playlistSaved
   * @param playlistEdit
   * @returns
   */
  private handleAfterSavePlaylist(playlistSaved: AnnouncementPlaylist, playlistEdit: AnnouncementPlaylist): void {
    this.isChangedData = false;
    this.isWaitingActionAPI = false;
    this.dataService.sendData([this.IS_EDIT_PLAYLIST, false]);
    let playlistClone =
      this.playlistSelected.playlistType == Constant.TYPE_PLAYLIST_GROUP_COMMON ? this.playlistsCommon : this.playlistsSpecific;
    // add playlist
    if (!playlistEdit.id) {
      playlistClone[playlistClone.length - 1] = playlistSaved;
      this.playlistSelected = playlistClone[playlistClone.length - 1];
      this.playlistSelectedClone = _.cloneDeep(this.playlistSelected);
      this.playlists.push(playlistSaved);
    } else {
      // edit playlist
      const index = playlistClone.findIndex(playlist => playlist.id == this.playlistSelected.id);
      playlistClone[index] = playlistSaved;
      this.playlistSelected = playlistClone[index];
      this.playlistSelectedClone = _.cloneDeep(this.playlistSelected);
    }
    this.playlistSelected.isEdit = false;
    this.selectPlaylist(this.playlistSelected);
  }

  /**
   * redraw media (choose tab, enlarge preview)
   * @param isRedraw
   * @returns
   */
  private redrawAudio(isRedraw: boolean): void {
    if (isRedraw) {
      if (this.audioOfSequenceSelected) {
        clearInterval(this.idInterval);
        this.clearPreview();
        this.previewAudioOfSequence();
        if (this.isPreviewOn && this.isEnlargePreview) {
          this.fakeTimelinePreview();
        }
      } else if (this.audioSelected) {
        if (this.isPreviewOn && this.isEnlargePreview) {
          clearInterval(this.idIntervalMedia);
          this.clearPreviewMedia();
          this.playAudio();
          this.fakeTimelinePreviewMedia();
        } else {
          this.selectAudio(this.audioSelected);
        }
      }
    } else {
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      if (this.audioOfSequenceSelected) {
        clearInterval(this.idInterval);
        this.pauseVideoSequence();
        this.resetCursorPoint();
      } else {
        this.pauseAudio();
      }
    }
  }

  /**
   * pause video
   * @param video
   */
  private pauseAudio(): void {
    clearInterval(this.idIntervalMedia);
    (document.getElementById(this.AUDIO_ANNOUNCEMENT_ID) as HTMLAudioElement)?.pause();
    if (this.tabSelected != Constant.CREATE_SEQUENCE_ENUM || !this.isEnlargePreview) {
      (document.getElementById(this.AUDIO_ANNOUNCEMENT_ID) as HTMLAudioElement)?.load();
    }
  }

  /**
   * clear preview
   */
  private clearPreview(): void {
    (document.getElementById(this.AUDIO_SEQ_ID) as HTMLAudioElement)?.remove();
  }

  /**
   * preview video sequence
   * @returns
   */
  private previewAudioElementSequence(media?: AudioOfSequence): HTMLAudioElement {
    let audio = document.createElement('audio') as HTMLAudioElement;
    audio.src = media ? media.url : this.audioOfSequenceSelected?.url;
    audio.setAttribute('id', this.AUDIO_SEQ_ID);
    this.setStyleElement(audio);
    audio.load();
    return audio;
  }

  /**
   * validate playlist
   * @returns
   */
  private validatePlaylist(): boolean {
    // validate empty
    if (this.playlistSelected.name.trim() == Constant.EMPTY) {
      this.textBoxRef?.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.empty-pl')
        }
      });
      return false;
      // validate max length
    } else if (this.playlistSelected.name.length > this.MAX_LENGTH_PLAYLIST_NAME) {
      this.textBoxRef?.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: Helper.formatString(this.translateService.instant('announcement-manager.length-pl'), `${this.MAX_LENGTH_PLAYLIST_NAME}`)
        }
      });
      return false;
      // validate special characters
    } else if (this.playlistSelected.name.match(Constant.SPECIAL_PLAYLIST)) {
      const character = Constant.SPECIAL_CHARACTER;
      this.textBoxRef?.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          texts: [this.translateService.instant('announcement-manager.character-not-enter'), `${character}`]
        }
      });
      return false;
    }
    return true;
  }

  /**
   * handle Error Save Data
   */
  private handleErrorSaveData(error: any, msg: any): void {
    this.dialogService.showDialog(DialogMessageComponent, {
      data: {
        title: this.translateService.instant('dialog-error.title'),
        text: error.error?.detail == Constant.ERROR_EXISTS_NAME ? msg : this.translateService.instant('dialog-error.msg')
      }
    });
  }

  /**
   * preview media of sequence
   */
  private previewAudioOfSequence(): void {
    let div = document.getElementById('preview') as HTMLElement;
    div.appendChild(this.previewAudioElementSequence());
    if (this.isPreviewOn && this.isEnlargePreview) {
      this.playAudioSequence();
    }
  }

  /**
   * play video sequence
   */
  private playAudioSequence(): void {
    (document.getElementById(this.AUDIO_SEQ_ID) as HTMLAudioElement)?.play();
  }

  /**
   * play Speech synthesis
   * @param speechSynthesis
   */
  public playSpeech(speechSynthesis: SpeechSynthesis): void {
    this.isPlaying = true;
    speechSynthesis['isPlaying'] = true;
    this.listSpeechSynthesis.filter(e => e.pollyLanguageCode !== speechSynthesis.pollyLanguageCode).forEach(e => (e['isPlaying'] = false));
    const audioElement = document.getElementById(`audio-speech${speechSynthesis.pollyLanguageCode}`) as HTMLAudioElement;
    audioElement?.play().then(() => {
      audioElement.addEventListener('ended', () => {
        this.isPlaying = false;
        this.listSpeechSynthesis.forEach(e => (e['isPlaying'] = false));
      });
    });
  }

  /**
   * pauseSpeech
   * @param speechSynthesis
   */
  public pauseSpeech(speechSynthesis: SpeechSynthesis): void {
    this.isPlaying = false;
    this.listSpeechSynthesis.forEach(e => (e['isPlaying'] = false));
    (document.getElementById(`audio-speech${speechSynthesis.pollyLanguageCode}`) as HTMLAudioElement)?.pause();
  }

  /**
   * set Audio To Tag
   * @param speechSynthesis
   */
  private setAudioToTag(speechSynthesis: SpeechSynthesis): void {
    let div = document.getElementById(`audioSpeech${speechSynthesis.pollyLanguageCode}`) as HTMLElement;
    let audio = document.createElement('audio') as HTMLAudioElement;
    audio.src = speechSynthesis.media?.url;
    audio.setAttribute('id', `audio-speech${speechSynthesis.pollyLanguageCode}`);
    this.setStyleElement(audio);
    audio.load();
    div.appendChild(audio);
  }

  /**
   * fake timeline preview
   */
  private fakeTimelinePreview(): void {
    this.idInterval = setInterval(() => {
      this.timeCursorPointSecond++;
      if (this.timeCursorPointSecond >= Helper.convertDuration(this.totalTime)) {
        this.timeCursorPointSecond = Helper.convertDuration(this.totalTime);
        this.isPreviewOn = false;
        this.isSelectedAudioOfSequence = true;
        this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
        this.valueCursorPoint = this.MAX_WIDTH_TIME_LINE - this.CURSOR_POINT;
        clearInterval(this.idInterval);
        return;
      }
      const i = this.audioOfSequences.findIndex(media => media.randomNumber == this.audioOfSequenceSelected.randomNumber);
      this.valueCursorPoint += this.pointPercents[i] / this.audioOfSequenceSelected.duration;
      if (this.timeCursorPointSecond == Helper.convertDuration(this.audioOfSequences[i + 1]?.startTime)) {
        clearInterval(this.idInterval);
        this.clearPreview();
        this.audioOfSequenceSelected = this.audioOfSequences[i + 1];
        this.playPreviewSequence();
      }
    }, 1000);
  }

  /**
   * clear preview media
   */
  private clearPreviewMedia(): void {
    this.isSelectedAudioOrFolder = true;
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOrFolder]);
    (document.getElementById(this.AUDIO_ANNOUNCEMENT_ID) as HTMLAudioElement)?.load();
    this.timeCursorPointSecond = 0;
    this.valueCursorPoint = 0;
  }

  /**
   * play video
   */
  private playAudio(): void {
    let audioElement = document.getElementById(this.AUDIO_ANNOUNCEMENT_ID) as HTMLAudioElement;
    audioElement.currentTime = this.timeCursorPointSecond;
    audioElement?.play();
  }

  /**
   * fake timeline preview media
   */
  private fakeTimelinePreviewMedia(): void {
    this.idIntervalMedia = setInterval(() => {
      this.timeCursorPointSecond++;
      this.valueCursorPoint += this.pointPercents[0] / Helper.convertDuration(this.audioSelected?.duration);
      if (this.timeCursorPointSecond >= Helper.convertDuration(this.totalTimeVideo)) {
        this.isPreviewOn = false;
        this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
        let video = document.getElementById(this.AUDIO_ANNOUNCEMENT_ID) as HTMLAudioElement;
        if (video) {
          video.currentTime = video.duration;
        }
        clearInterval(this.idIntervalMedia);
        return;
      }
    }, 1000);
  }

  /**
   * select media
   * @param media
   */
  public selectAudio(media: AnnouncementMedia): void {
    if (this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    this.isSelectedAudioOrFolder = true;
    this.isSelectedAudioOfSequence = true;
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
    this.dataService.sendData([this.IS_AUDIO_SILENCE, false]);
    this.audioSelected = media;
    this.folderSelected = undefined;
    this.audioOfSequenceSelected = undefined;

    if (this.audioSelected?.type == TypeMediaFileEnum.MP3) {
      this.pointPercents = [];
      this.pointPercents[0] = this.MAX_WIDTH_TIME_LINE;
      this.valueCursorPoint = 0;
      this.timeCursorPointSecond = 0;
      const totalDuration = Helper.convertDuration(this.audioSelected?.duration);
      this.totalTimeVideo = Helper.formatDurationSimpleSignage(`${isNaN(totalDuration) ? 0 : totalDuration}`);
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      (document.getElementById(this.AUDIO_ANNOUNCEMENT_ID) as HTMLAudioElement)?.load();
    }
  }

  /**
   * pause video sequence
   */
  private pauseVideoSequence(): void {
    (document.getElementById(this.AUDIO_SEQ_ID) as HTMLAudioElement)?.pause();
  }

  /**
   * set style element
   * @param element image or video
   */
  private setStyleElement(element: HTMLElement): void {
    element.style.width = '100%';
    element.style.height = '100%';
    element.style.objectFit = 'contain';
  }

  /**
   * play preview
   */
  private playPreviewSequence(): void {
    let audio = document.getElementById(this.AUDIO_SEQ_ID) as HTMLAudioElement;
    console.log('Play audio of sequence: ' + audio);
    // if preview is ended
    if (this.timeCursorPointSecond >= Helper.convertDuration(this.totalTime)) {
      this.timeCursorPointSecond = Helper.convertDuration(this.totalTime);
      this.valueCursorPoint = 0;
      this.timeCursorPointSecond = 0;
      this.audioOfSequenceSelected = this.audioOfSequences[0];
      if (audio) {
        audio.currentTime = 0;
      }
    }
    if (audio && audio.currentTime > 0) {
      this.playAudioSequence();
    } else {
      this.clearPreview();
      clearInterval(this.idInterval);
      this.previewAudioOfSequence();
    }
    this.fakeTimelinePreview();
  }
  /**
   * cancel add playlist
   */
  public cancelAddPlaylist(): void {
    this.playlistSelected.isEdit = false;
    this.dataService.sendData([this.IS_EDIT_PLAYLIST, this.playlistSelected.isEdit]);
    this.isChangedData = false;
    if (!this.playlistSelected.id) {
      let playlistClone =
        this.playlistSelected.playlistType == Constant.TYPE_PLAYLIST_GROUP_COMMON ? this.playlistsCommon : this.playlistsSpecific;
      playlistClone.pop();
      if (playlistClone?.length) {
        this.selectPlaylist(playlistClone[0]);
      } else {
        this.playlistSelected = undefined;
        this.playlistSelectedClone = _.cloneDeep(this.playlistSelected);
      }
    } else {
      this.playlistSelected.name = this.oldValuePlaylistName;
      this.selectPlaylist(this.playlistSelected);
    }
  }

  /**
   * add playlist
   * @returns
   */
  public addPlaylist(): void {
    if (this.playlistSelected?.isEdit || this.isPreviewOn) {
      return;
    }
    this.totalTime = undefined;
    this.audioOfSequenceSelected = undefined;
    let playlist = new AnnouncementPlaylist(
      null,
      '',
      this.playlistSelected ? this.playlistSelected.playlistType : this.playlistGroupSelected.id
    );
    let playlistClone =
      (this.playlistSelected && this.playlistSelected.playlistType == Constant.TYPE_PLAYLIST_GROUP_COMMON) ||
      (this.playlistGroupSelected && this.playlistGroupSelected.id == Constant.TYPE_PLAYLIST_GROUP_COMMON)
        ? this.playlistsCommon
        : this.playlistsSpecific;
    playlistClone.push(playlist);
    this.playlistSelected = playlistClone[playlistClone.length - 1];
    this.playlistSelected.isEdit = true;
    this.playlistSelectedClone = _.cloneDeep(this.playlistSelected);
    this.audioOfSequences = [];
    this.totalSize = 0;
    this.dataService.sendData([this.IS_EDIT_PLAYLIST, this.playlistSelected?.isEdit]);
    this.isChangedData = true;
    // clear preview
    this.clearPreview();
    clearInterval(this.idInterval);
    clearInterval(this.idIntervalMedia);
  }

  /**
   * Drop media
   * @param e CdkDragDrop
   */
  public dropMedia(e: CdkDragDrop<any[]>): void {
    if (!this.playlistSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.select-pl')
        }
      });
      return;
    } else if (this.playlistSelected?.isEdit) {
      return;
    }
    if (e.previousContainer === e.container) {
      if (!this.isDraggingMediaOfSequence) {
        return;
      }
      this.dropMediaOfSequence(e);
      this.handleAfterDragAudioSequence(e.currentIndex);
    } else {
      // convert data media
      const dataMedia =
        this.isTypeList || this.audiosOfFolder ? e.previousContainer.data[e.previousIndex] : this.miniMediaStateOfComponent?.mediaDragging;
      let audioOfSequence = Helper.convertAnnouncementAudioToAudioOfSequence(dataMedia);
      if (this.miniMediaStateOfComponent?.folderSelected) {
        audioOfSequence.folderS3Name = this.miniMediaStateOfComponent?.folderSelected.folderS3Name;
      } else if (dataMedia.folderId != null) {
        const index = this.miniMediaStateOfComponent?.folders?.findIndex(item => item.id == dataMedia.folderId);
        if (index != -1) {
          audioOfSequence.folderS3Name = this.miniMediaStateOfComponent?.folders[index].folderS3Name;
        }
      }
      if (e.currentIndex < this.audioOfSequences.length && !this.audioOfSequences[e.currentIndex + 1]?.idMedia && e.currentIndex !== 0) {
        e.currentIndex = e.currentIndex - 1;
      }
      let mediaClone = new AudioOfSequence();
      mediaClone.name = this.translateService.instant('announcement-manager.silence');
      mediaClone.duration = 2;
      mediaClone.type = 'mp3';
      mediaClone.size = 0;
      mediaClone.randomNumber = Math.random();
      if (e.currentIndex == 0) {
        this.audioOfSequences.splice(e.currentIndex, 0, audioOfSequence);
        this.handleAfterDragAudioSequence(e.currentIndex);
        if (this.audioOfSequences?.length != 1) {
          this.audioOfSequences.splice(e.currentIndex + 1, 0, mediaClone);
          this.handleAfterDragAudioSequence(e.currentIndex + 1);
        }
      } else {
        this.audioOfSequences.splice(e.currentIndex, 0, mediaClone);
        this.handleAfterDragAudioSequence(e.currentIndex);
        this.audioOfSequences.splice(e.currentIndex + 1, 0, audioOfSequence);
        this.handleAfterDragAudioSequence(e.currentIndex + 1);
      }
    }
    this.isChangeAudioOfSequence = !this.checkDiffDataAudioOfSequences();
  }

  /**
   * Drop media in area sequence maker
   * @param event CdkDragDrop
   */
  public dropMediaOfSequence(event: CdkDragDrop<any[]>): void {
    if (this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    let currentMediasOfSequence = event.container.data;
    let indexAudioFrom = event.previousIndex;
    let indexAudioTo = event.currentIndex;
    if (indexAudioTo == 0) {
      //remove audio silence cũ
      currentMediasOfSequence.splice(indexAudioFrom - 1, 1);
      // di chuyển audio đến vị trí mới
      moveItemInArray(currentMediasOfSequence, indexAudioFrom - 1, indexAudioTo);
      // thêm audio silence vào vị trí mới, bên dưới audio
      let mediaClone = new AudioOfSequence();
      mediaClone.name = this.translateService.instant('announcement-manager.silence');
      mediaClone.duration = 2;
      mediaClone.type = 'mp3';
      mediaClone.size = 0;
      mediaClone.randomNumber = Math.random();
      currentMediasOfSequence.splice(indexAudioTo + 1, 0, mediaClone);
      event.currentIndex = event.currentIndex + 1;
    } else if (indexAudioTo % 2 == 0) {
      if (indexAudioFrom == 0) {
        currentMediasOfSequence.splice(indexAudioFrom + 1, 1);
      } else {
        //remove audio silence cũ
        currentMediasOfSequence.splice(indexAudioFrom - 1, 1);
      }
      // di chuyển audio đến vị trí mới
      moveItemInArray(currentMediasOfSequence, indexAudioFrom - 1, indexAudioTo - 1);
      // thêm audio silence vào vị trí mới, bên trên audio
      let mediaClone = new AudioOfSequence();
      mediaClone.name = this.translateService.instant('announcement-manager.silence');
      mediaClone.duration = 2;
      mediaClone.type = 'mp3';
      mediaClone.size = 0;
      mediaClone.randomNumber = Math.random();
      currentMediasOfSequence.splice(indexAudioTo - 1, 0, mediaClone);
    } else {
      if (indexAudioFrom == 0) {
        currentMediasOfSequence.splice(indexAudioFrom + 1, 1);
      } else {
        //remove audio silence cũ
        currentMediasOfSequence.splice(indexAudioFrom - 1, 1);
      }
      // di chuyển audio đến vị trí mới
      moveItemInArray(currentMediasOfSequence, indexAudioFrom - 1, indexAudioTo);
      // thêm audio silence vào vị trí mới, bên dưới audio
      let mediaClone = new AudioOfSequence();
      mediaClone.name = this.translateService.instant('announcement-manager.silence');
      mediaClone.duration = 2;
      mediaClone.type = 'mp3';
      mediaClone.size = 0;
      mediaClone.randomNumber = Math.random();
      currentMediasOfSequence.splice(indexAudioTo, 0, mediaClone);
      event.currentIndex = event.currentIndex + 1;
    }
  }

  /**
   * handle after drag media sequence
   * @param currentIndex
   */
  private handleAfterDragAudioSequence(currentIndex: number) {
    this.handleDuration();
    this.calculatorPointList();
    this.selectAudioOfSequence(this.audioOfSequences[currentIndex]);
  }

  /**
   * calculator point list color pink of
   */
  private calculatorPointList(): void {
    this.pointPercents = [];
    this.pointNumbers = this.audioOfSequences.map(media => media.duration);
    this.pointNumbers.forEach((point, index) => {
      let percentPoint1 = this.pointNumbers[index] / Helper.convertDuration(this.totalTime);
      const width = Math.floor(this.MAX_WIDTH_TIME_LINE * percentPoint1);
      this.pointPercents[index] = width > 0 ? width : 1;
    });
  }

  /**
   * Allow Drop
   * @param e event
   */
  public allowDrop(e: any): void {
    if (this.isPreviewOn) {
      return;
    }
    e.preventDefault();
  }

  /**
   * checkShowStartAt
   * @returns
   */
  public checkShowStartAt(): boolean {
    if (!this.audioOfSequences?.length) {
      return;
    }
    //const audioOfSequences = JSON.parse(this.playlistSelected?.sequence);
    let media = this.audioOfSequences?.filter(item => item.idMedia == null);
    return media && media.length ? true : false;
  }

  /**
   * handle input
   * @param mediaOfSequence
   */
  public handleInput(audioOfSequence: AudioOfSequence): void {
    audioOfSequence.isEdit = true;
    audioOfSequence.duration = +audioOfSequence.hour * this.TIME_ONE_HOUR + +audioOfSequence.minute * 60 + +audioOfSequence.second;
    this.handleDuration();
    this.selectAudioOfSequence(this.audioOfSequenceSelected);
    this.isChangeAudioOfSequence = !this.checkDiffDataAudioOfSequences();
  }

  /**
   * cancel sequence
   */
  public cancelSequence(): void {
    if (this.isPreviewOn || !this.playlistSelected) {
      return;
    }
    this.audioOfSequences = _.cloneDeep(this.oldAudioOfSequences);
    this.selectAudioOfSequence(this.audioOfSequences[0]);
    this.handleDuration();
    this.calculatorPointList();
    this.isChangeAudioOfSequence = !this.checkDiffDataAudioOfSequences();
  }

  /**
   * enlarge preview
   */
  public enlargePreview(): void {
    this.isEnlargePreview = !this.isEnlargePreview;
    this.redrawAudio(this.isEnlargePreview);
  }

  /**
   * change cursor point of sequence
   */
  public changeCursorPointOfSequence(): void {
    clearInterval(this.idInterval);
    let mediaCurrent = this.audioOfSequences.find(
      media =>
        this.timeCursorPointSecond >= Helper.convertDuration(media.startTime) &&
        this.timeCursorPointSecond < Helper.convertDuration(media.startTime) + media.duration
    );
    let mediaLast = this.audioOfSequences.find(
      media => this.timeCursorPointSecond == Helper.convertDuration(media.startTime) + media.duration
    );
    let mediaCheck = mediaCurrent ?? mediaLast;
    if (mediaCheck) {
      this.audioOfSequenceSelected = mediaCheck;
      this.clearPreview();
      let div = document.getElementById('preview') as HTMLElement;
      if (this.audioOfSequenceSelected.type == TypeMediaFileEnum.MP3) {
        if (!document.getElementById(this.AUDIO_SEQ_ID)) {
          div.appendChild(this.previewAudioElementSequence());
        }
        let video = document.getElementById(this.AUDIO_SEQ_ID) as HTMLAudioElement;
        video.currentTime = mediaCurrent ? this.timeCursorPointSecond - Helper.convertDuration(mediaCurrent.startTime) : mediaLast.duration;
        this.isPreviewOn = mediaCurrent ? this.isPreviewOn : false;
        this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
        if (this.isPreviewOn && this.isEnlargePreview) {
          this.playAudioSequence();
        }
      }
      if (this.isPreviewOn) {
        this.fakeTimelinePreview();
      }
    }
  }

  /**
   * calculate split point between media
   * @param index
   * @returns
   */
  public calculateSplitPoint(index: number): number {
    return index + 1 != this.pointPercents.length ? _.sumBy(this.pointPercents.slice(0, index + 1)) : this.MAX_WIDTH_TIME_LINE;
  }

  /**
   * change cursor point
   */
  public changeCursorPoint(): void {
    clearInterval(this.idIntervalMedia);
    let audio = document.getElementById(this.AUDIO_ANNOUNCEMENT_ID) as HTMLAudioElement;
    if (!audio) {
      return;
    }

    audio.currentTime = this.timeCursorPointSecond;
    if (this.isPreviewOn) {
      this.fakeTimelinePreviewMedia();
    }
  }

  /**
   * reset play sequence
   */
  public resetPlaySequence(): void {
    this.isPreviewOn = false;
    this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
    this.clearPreview();
    // reset timeline preview
    this.timeCursorPointSecond = 0;
    this.valueCursorPoint = 0;
    clearInterval(this.idInterval);
    this.audioOfSequenceSelected = this.audioOfSequences[0];
    this.previewAudioOfSequence();
  }

  /**
   * previous media of sequence
   */
  public prevMediaOfSequence(): void {
    clearInterval(this.idInterval);
    const index = this.audioOfSequences.findIndex(media => media.randomNumber == this.audioOfSequenceSelected.randomNumber);
    if (index == -1) {
      return;
    }
    if (this.audioOfSequenceSelected?.type == TypeMediaFileEnum.MP3) {
      let video = document.getElementById(this.AUDIO_SEQ_ID) as HTMLAudioElement;
      video.currentTime = 0;
    }
    if (index == 0) {
      this.audioOfSequenceSelected = this.audioOfSequences[0];
      this.handleDrawPreview(0);
      return;
    }
    const startTimeOfCurrentMedia = Helper.convertDuration(this.audioOfSequenceSelected.startTime);
    const endTimeOfCurrentMedia = startTimeOfCurrentMedia + this.audioOfSequenceSelected.duration;
    // preview previous media of sequence
    if (this.timeCursorPointSecond == startTimeOfCurrentMedia) {
      this.audioOfSequenceSelected = this.audioOfSequences[index - 1];
      this.handleDrawPreview(index - 1);
    } else if (this.timeCursorPointSecond > startTimeOfCurrentMedia && this.timeCursorPointSecond < endTimeOfCurrentMedia) {
      this.handleDrawPreview(index);
    }
  }

  /**
   * preview media
   */
  public previewAudio(isPlay: boolean): void {
    if (this.audioOfSequenceSelected && !this.validateDurationMediaOfSequence()) {
      return;
    }
    clearInterval(this.idIntervalMedia);
    clearInterval(this.idInterval);
    this.isPreviewOn = isPlay;
    this.isSelectedAudioOfSequence = !isPlay;
    this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
    if (isPlay) {
      if (this.audioOfSequenceSelected) {
        this.playPreviewSequence();
      } else {
        this.playPreviewAudio();
      }
    } else {
      if (this.audioOfSequenceSelected) {
        this.pauseSequence();
      } else {
        this.pauseAudio();
      }
    }
  }

  /**
   * next media of sequence
   */
  public nextMediaOfSequence(): void {
    this.clearPreview();
    const index = this.audioOfSequences.findIndex(media => media.randomNumber == this.audioOfSequenceSelected.randomNumber);
    if (index == -1) {
      return;
    }
    if (index == this.audioOfSequences.length - 1) {
      this.audioOfSequenceSelected = this.audioOfSequences[this.audioOfSequences.length - 1];
      clearInterval(this.idInterval);
      this.clearPreview();
      this.valueCursorPoint = this.MAX_WIDTH_TIME_LINE - this.CURSOR_POINT;
      this.timeCursorPointSecond = Helper.convertDuration(this.totalTime);
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      return;
    }
    this.audioOfSequenceSelected = this.audioOfSequences[index + 1];
    this.handleDrawPreview(index + 1);
  }

  /**
   * handle button back
   */
  public async handleButtonBack(): Promise<void> {
    if (!this.isActiveButtonBack) {
      return;
    }
    await this.getDataMiniMedia();
    this.audiosOfFolder = null;
    if (this.isPreviewOn) {
      this.dataService.sendData([this.IS_ON_PREVIEW, !this.isPreviewOn]);
      this.pauseAudio();
    }
    this.handleSetDataAfterBack();

    this.searchInputValue = '';
    this.calculateRowHeight(this.folders.length + this.medias.length);
  }

  /**
   * search by name
   * @param value search value
   */
  public searchMediaByName(value: string): void {
    this.searchInputValue = value;
    if (this.audiosOfFolder) {
      this.dispatchDataToStore(null, null, this.oldAudiosOfFolder, null);
      if (this.searchInputValue.trim() == '') {
        this.handleSearchEmpty(true);
        return;
      }
      this.audiosOfFolder = this.oldAudiosOfFolder.filter(media =>
        media.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase())
      );
      this.selectAudio(this.audiosOfFolder[0]);
      if (!this.audiosOfFolder.length) {
        this.isSelectedAudioOfSequence = false;
        this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
        this.isSelectedAudioOrFolder = false;
      }
      this.calculateRowHeight(this.audiosOfFolder.length);
    } else {
      this.store.dispatch(
        new SaveMiniMediaStateAction({
          folderSelected: null,
          folders: this.miniMediaStateOfComponent?.folders ?? this.folders,
          medias: this.miniMediaStateOfComponent?.medias ?? this.medias,
          audiosOfFolder: null,
          mediaDragging: null
        })
      );
      if (this.searchInputValue.trim() == '') {
        this.isActiveButtonBack = false;
        this.getDataMiniMedia();
        this.audiosOfFolder = null;
        this.store.dispatch(new ResetMiniMediaStateAction());
        return;
      }
      this.announcementManagerService.searchByName(this.searchInputValue).subscribe(data => {
        // get all folder audio
        this.announcementManagerService.getAllFolderMedias().subscribe(dataFolder => {
          const folders = Helper.convertDataAnnouncementFolderMedias(dataFolder);
          const medias = Helper.convertDataAnnouncementMediasNotContainDate(data, true).filter(
            media => !TypeMediasNotSupported.includes(media.type)
          );
          this.folders = folders.filter(folder => folder.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase()));
          this.medias = medias.filter(media => media.name.toLocaleLowerCase().includes(this.searchInputValue.toLocaleLowerCase()));
          if (this.folders && this.folders.length > 0) {
            this.selectFolder(this.folders[0]);
          } else {
            this.selectAudio(this.medias[0]);
          }
          this.isActiveButtonBack = true;
          if (!this.medias.length) {
            this.isSelectedAudioOfSequence = false;
            this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
            this.isSelectedAudioOrFolder = false;
          }
          this.calculateRowHeight(this.folders.length + this.medias.length);
        });
      });
    }
  }

  /**
   * dispatch content tab data to store (mini media)
   * @param folders
   * @param medias
   * @param audiosOfFolder
   */
  private dispatchDataToStore(
    folders: Array<AnnouncementFolder>,
    medias: Array<AnnouncementMedia>,
    audiosOfFolder: Array<AnnouncementMedia>,
    mediaDragging: AnnouncementMedia
  ): void {
    this.store.dispatch(
      new SaveMiniMediaStateAction({
        folderSelected: this.miniMediaStateOfComponent?.folderSelected ?? this.folderSelected,
        folders: folders,
        medias: medias,
        audiosOfFolder: audiosOfFolder,
        mediaDragging: mediaDragging
      })
    );
  }

  /**
   * add folder
   */
  public addFolder(): void {
    if (this.audiosOfFolder || this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    let folder = new AnnouncementFolder(null, '');
    this.folders.push(folder);
    this.folderSelected = this.folders[this.folders.length - 1];
    this.folderSelected.isEdit = true;
    this.audioSelected = undefined;
    if (this.folders?.length > 0 || this.medias?.length > 0) {
      this.calculateRowHeight(this.folders.length + this.medias.length);
    }
    this.dataService.sendData([this.IS_EDIT_FOLDER, this.folderSelected.isEdit]);
    this.isChangedData = true;
  }

  /**
   * calculate row height
   * @param size
   */
  private calculateRowHeight(size: number): void {
    const MAX_ROWS = 7;
    const HEIGHT_ROW = 50;
    this.heightRowDisplayNone = size > MAX_ROWS ? '0' : `calc(35vh - ${size * HEIGHT_ROW}px)`;
  }

  /**
   * delete item
   */
  public deleteItem(): void {
    if (this.isPreviewOn) {
      return;
    }
    if (this.folderSelected) {
      this.deleteFolder();
    } else if (this.audioSelected) {
      this.deleteMedia();
    }
  }

  /**
   * delete item in menu
   */
  private deleteItemInMenu(): void {
    if (this.isPreviewOn) {
      return;
    }
    if (this.folderSelected) {
      this.deleteFolder();
    } else if (this.audioSelected) {
      this.deleteMedia();
    } else if (this.audioOfSequenceSelected) {
      this.deleteAudioOfSequence();
    }
  }

  /**
   * delete media of sequence
   */
  private deleteAudioOfSequence(): void {
    if (this.isPreviewOn) {
      return;
    }
    const index = this.audioOfSequences?.findIndex(media => media.randomNumber == this.audioOfSequenceSelected.randomNumber);
    if (index == -1) {
      return;
    }
    if (index == 0) {
      this.audioOfSequences?.splice(index, 2);
    } else {
      this.audioOfSequences?.splice(index - 1, 2);
    }
    this.selectAudioOfSequence(this.audioOfSequences[0]);
    this.handleDuration();
    this.calculatorPointList();
    this.isChangeAudioOfSequence = !this.checkDiffDataAudioOfSequences();
  }

  /**
   * change display list folder/media
   * @param isTypeFolder
   */
  public changeDisplayListMedia(isTypeFolder: boolean): void {
    this.isTypeList = isTypeFolder;
  }

  /**
   * drop media in folder (list)
   * @param e
   */
  public async dropMediaInFolderList(e: CdkDragDrop<any[]>) {
    // convert data media
    let mediaOfFolder = Helper.convertDataAnnouncementMedia(e.previousContainer.data[e.previousIndex], false);
    const folder = this.folders.find(item => item[this.IS_DRAGGING_MEDIA]);
    if (!folder) {
      this.clearAttributeOfFolder(this.folders);
      return;
    }
    this.announcementManagerService.checkExistMediaUsed(mediaOfFolder.id).subscribe(data => {
      const index = this.audioOfSequences?.findIndex(media => media.idMedia == mediaOfFolder.id);
      if (data || (index && index != -1)) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('announcement-manager.error'),
            text: this.translateService.instant('announcement-manager.drag-media-folder')
          }
        });
        return;
      }
      this.announcementManagerService.saveMediaDroppedInFolder(mediaOfFolder, folder?.id ?? null).subscribe(
        data => {
          delete folder[this.IS_MOVING_CSS];
          delete folder[this.IS_DRAGGING_MEDIA];
          this.folderNameContains = undefined;
          this.store.dispatch(new ResetMiniMediaStateAction());
          delete folder[this.IS_DRAGGING_MEDIA];
          this.calculateRowHeight(this.folders.length + this.medias.length);
          this.medias.splice(e.previousIndex, 1);
          this.audioSelected = undefined;
        },
        error => {
          if (error.error?.detail == Constant.ERROR_EXISTS_NAME) {
            delete folder[this.IS_MOVING_CSS];
            delete folder[this.IS_DRAGGING_MEDIA];
            this.folderNameContains = undefined;
            this.store.dispatch(new ResetMiniMediaStateAction());
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.getErrorMessage('exists-name', mediaOfFolder.name, null)
              }
            });
          }
        }
      );
    });
  }

  /**
   * handle mouse leave
   * @param folder
   */
  public handleMouseLeave(folder: AnnouncementFolder): void {
    if (!this.isDraggingMedia) {
      return;
    }
    delete folder[this.IS_MOVING_CSS];
    delete folder[this.IS_DRAGGING_MEDIA];
    this.folderNameContains = undefined;
    this.store.dispatch(new ResetMiniMediaStateAction());
  }

  /**
   * select folder
   * @param folder
   */
  public selectFolder(folder: AnnouncementFolder): void {
    if (this.folderSelected?.isEdit || this.isPreviewOn) {
      return;
    }
    this.folderSelected = folder;
    this.isSelectedAudioOrFolder = true;
    this.isSelectedAudioOfSequence = true;
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
    this.dataService.sendData([this.IS_AUDIO_SILENCE, false]);
    if (this.audioSelected?.type == TypeMediaFileEnum.MP3) {
      (document.getElementById(this.AUDIO_ANNOUNCEMENT_ID) as HTMLAudioElement)?.load();
    }
    this.clearPreview();
    this.audioSelected = undefined;
    this.audioOfSequenceSelected = undefined;
  }

  /**
   * open folder media
   * @param folder
   */
  public openFolderMedia(folder: AnnouncementFolder): void {
    if (this.folderSelected?.isEdit || this.isPreviewOn) {
      return;
    }
    setTimeout(() => {
      this.store.dispatch(
        new SaveMiniMediaStateAction({
          folderSelected: this.miniMediaStateOfComponent?.folderSelected ?? folder,
          folders: null,
          medias: null,
          audiosOfFolder: null,
          mediaDragging: null
        })
      );
    });
    // get data media of folder
    this.announcementManagerService.getMediasByFolderId(folder.id).subscribe(
      data => {
        this.searchInputValue = Constant.EMPTY;
        this.handleAfterGetAudiosOfFolder(data);
        if (!data.length) {
          this.isSelectedAudioOrFolder = false;
          this.isSelectedAudioOfSequence = false;
          this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
        }
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * handle after get medias of folder
   * @param data
   */
  private handleAfterGetAudiosOfFolder(data: any): void {
    this.audiosOfFolder = Helper.convertDataAnnouncementMediasNotContainDate(data, true).filter(
      media => !TypeMediasNotSupported.includes(media.type)
    );
    this.oldAudiosOfFolder = _.cloneDeep(this.audiosOfFolder);
    this.isActiveButtonBack = true;
    this.selectAudio(this.audiosOfFolder[0]);
    this.calculateRowHeight(this.audiosOfFolder.length);
  }

  /**
   * save folder
   */
  public saveFolder(isCheckExits?: boolean): boolean {
    if (this.isWaitingActionAPI) {
      return true;
    }
    this.isWaitingActionAPI = true;
    if (!this.validateFolder()) {
      this.saveDataSuccess.emit(false);
      this.isWaitingActionAPI = false;
      return false;
    }
    if (!isCheckExits) {
      // validate exists folder
      this.announcementManagerService.checkExistFolder(this.folderSelected.name, this.folderSelected.id).subscribe(
        data => {
          if (data) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('announcement-manager.error'),
                text: this.translateService.instant('announcement-manager.exists-folder')
              }
            });
            this.isWaitingActionAPI = false;
            return false;
          }
          // get information to save data
          let folderToSave = _.cloneDeep(this.folderSelected);
          folderToSave.name = folderToSave.name;
          // save data
          this.announcementManagerService.saveFolder(folderToSave).subscribe(
            data => {
              this.saveDataSuccess.emit(true);
              this.handleAfterSaveFolder(data, folderToSave);
              return true;
            },
            error => {
              this.isWaitingActionAPI = false;
              this.saveDataSuccess.emit(false);
              this.handleErrorSaveData(error, this.translateService.instant('announcement-manager.exists-folder'));
              return false;
            }
          );
        },
        error => {
          this.isWaitingActionAPI = false;
          this.saveDataSuccess.emit(false);
          Helper.handleError(error, this.translateService, this.dialogService);
          return false;
        }
      );
    } else {
      // get information to save data
      let folderToSave = _.cloneDeep(this.folderSelected);
      folderToSave.name = folderToSave.name;
      // save data
      this.announcementManagerService.saveFolder(folderToSave).subscribe(
        data => {
          this.saveDataSuccess.emit(true);
          this.handleAfterSaveFolder(data, folderToSave);
          return true;
        },
        error => {
          this.isWaitingActionAPI = false;
          this.saveDataSuccess.emit(false);
          this.handleErrorSaveData(error, this.translateService.instant('announcement-manager.exists-folder'));
          return false;
        }
      );
    }
  }

  /**
   * handle after save Folder
   *
   * @param folderSaved
   * @param folderEdit
   * @returns
   */
  private handleAfterSaveFolder(folderSaved: AnnouncementFolder, folderEdit: AnnouncementFolder): void {
    this.isChangedData = false;
    this.isWaitingActionAPI = false;
    // add folder
    this.dataService.sendData([this.IS_EDIT_FOLDER, false]);
    if (!folderEdit.id) {
      this.folders[this.folders.length - 1] = folderSaved;
      this.folderSelected = this.folders[this.folders.length - 1];
    } else {
      //  edit folder
      const index = this.folders.findIndex(folder => folder.id == this.folderSelected.id);
      this.folders[index] = folderSaved;
      this.folderSelected = this.folders[index];
    }
    this.folderSelected.isEdit = false;
    this.selectFolder(this.folderSelected);
  }

  /**
   * validate folder
   * @returns
   */
  private validateFolder(): boolean {
    // max length of folder name
    const MAX_LENGTH_FOLDER_NAME = 64;
    // validate empty
    if (this.folderSelected?.name.trim() == Constant.EMPTY) {
      this.folderRef?.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.empty-folder')
        }
      });
      return false;
      // validate max length
    } else if (this.folderSelected?.name.length > MAX_LENGTH_FOLDER_NAME) {
      this.folderRef?.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.length-folder')
        }
      });
      return false;
    } else if (this.folderSelected?.name.match(Constant.SPECIAL_PLAYLIST)) {
      const character = Constant.SPECIAL_CHARACTER;
      this.textBoxRef?.nativeElement.focus();
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          texts: [this.translateService.instant('announcement-manager.character-not-enter'), `${character}`]
        }
      });
      return false;
    }
    return true;
  }

  /**
   * cancel add folder
   */
  public cancelAddFolder(): void {
    this.folderSelected.isEdit = false;
    this.dataService.sendData([this.IS_EDIT_FOLDER, this.folderSelected.isEdit]);
    this.isChangedData = false;
    if (!this.folderSelected.id) {
      this.folders.pop();
      if (this.folders?.length) {
        this.selectFolder(this.folders[0]);
      } else {
        this.folderSelected = undefined;
      }
    } else {
      this.folderSelected.name = this.oldValueFolderName;
      this.selectFolder(this.folderSelected);
    }
  }

  /**
   * drag ended
   * @param media
   */
  public dragEnded(media?: AnnouncementMedia): void {
    this.isDraggingMedia = false;
    this.folders.forEach(data => delete data[this.IS_MOVING_CSS]);
    if (media) {
      this.dispatchDataToStore(null, null, null, media);
    }
  }

  /**
   * drop media in folder (tile)
   */
  public dropMediaInFolderTile(): void {
    if (!this.miniMediaStateOfComponent?.mediaDragging) {
      return;
    }
    let mediaOfFolder = Helper.convertDataAnnouncementMedia(this.miniMediaStateOfComponent?.mediaDragging, false);
    const folder = this.folders.find(item => item[this.IS_DRAGGING_MEDIA]);
    if (!folder) {
      this.clearAttributeOfFolder(this.folders);
      return;
    }
    this.announcementManagerService.checkExistMediaUsed(mediaOfFolder.id).subscribe(data => {
      const index = this.audioOfSequences.findIndex(media => media.idMedia == mediaOfFolder.id);
      if (data || index != -1) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('announcement-manager.error'),
            text: this.translateService.instant('announcement-manager.drag-media-folder')
          }
        });
        return;
      }
      this.announcementManagerService.saveMediaDroppedInFolder(mediaOfFolder, folder?.id ?? null).subscribe(
        data => {
          delete folder[this.IS_DRAGGING_MEDIA];
          const mediaIndex = this.medias.findIndex(item => item.id == mediaOfFolder.id);
          if (mediaIndex == -1) {
            return;
          }
          this.medias.splice(mediaIndex, 1);
          this.store.dispatch(new ResetMiniMediaStateAction());
        },
        error => {
          if (error.error?.detail == Constant.ERROR_EXISTS_NAME) {
            this.dialogService.showDialog(DialogMessageComponent, {
              data: {
                title: this.translateService.instant('dialog-error.title'),
                text: this.getErrorMessage('exists-name', mediaOfFolder.name, null)
              }
            });
          }
        }
      );
    });
  }

  /**
   * handle draw preview
   * @param point
   */
  private handleDrawPreview(index: number): void {
    this.valueCursorPoint = this.calculateCursorPoint(index);
    this.timeCursorPointSecond = Helper.convertDuration(this.audioOfSequences[index].startTime);
    this.playPreviewSequence();
  }

  /**
   * play preview video
   */
  private playPreviewAudio(): void {
    let audio = document.getElementById(this.AUDIO_ANNOUNCEMENT_ID) as HTMLAudioElement;
    console.log('Play audio: ' + audio);
    // if preview is ended
    if (this.timeCursorPointSecond >= Helper.convertDuration(this.audioSelected?.duration)) {
      this.valueCursorPoint = 0;
      this.timeCursorPointSecond = 0;
      if (audio) {
        audio.currentTime = 0;
      }
    }
    this.playAudio();
    this.fakeTimelinePreviewMedia();
  }

  /**
   * pause sequence on canvas
   */
  private pauseSequence(): void {
    clearInterval(this.idInterval);
    if (this.audioOfSequenceSelected?.type == TypeMediaFileEnum.MP3) {
      let video = document.getElementById(this.AUDIO_SEQ_ID) as HTMLAudioElement;
      video.currentTime = this.timeCursorPointSecond - Helper.convertDuration(this.audioOfSequenceSelected.startTime);
      this.pauseVideoSequence();
    }
  }

  /**
   * handle set data after back
   */
  private handleSetDataAfterBack(): void {
    this.isActiveButtonBack = false;
    if (this.audioSelected?.type == TypeMediaFileEnum.MP3) {
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
    }
    if (this.folderSelected) {
      this.selectFolder(this.folderSelected);
    } else {
      if (this.folders?.length) {
        this.selectFolder(this.folders[0]);
      } else if (this.medias?.length) {
        this.selectAudio(this.medias[0]);
      }
    }
    this.store.dispatch(new ResetMiniMediaStateAction());
  }

  /**
   * handle search empty
   * @param isOpenFolder
   */
  private handleSearchEmpty(isOpenFolder: boolean): void {
    if (this.audioSelected?.type == TypeMediaFileEnum.MP3) {
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      clearInterval(this.idIntervalMedia);
    }
    if (isOpenFolder) {
      this.audiosOfFolder = this.oldAudiosOfFolder;
      this.dispatchDataToStore(this.folders, this.medias, null, null);
    } else {
      this.isActiveButtonBack = false;
      this.folders = this.miniMediaStateOfComponent?.folders;
      this.medias = this.miniMediaStateOfComponent?.medias;
      this.audiosOfFolder = null;
      this.store.dispatch(new ResetMiniMediaStateAction());
    }
    this.selectAudio(undefined);
  }

  /**
   * delete folder
   * @returns
   */
  public deleteFolder(): void {
    if (this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(this.translateService.instant('announcement-manager.want-delete'), this.folderSelected.name),
          button1: this.translateService.instant('announcement-manager.yes'),
          button2: this.translateService.instant('announcement-manager.no')
        }
      },
      result => {
        if (result) {
          // get medias by folder id
          this.announcementManagerService.getMediasByFolderId(this.folderSelected.id).subscribe(
            data => {
              let audiosOfFolder = Helper.convertDataAnnouncementMediasNotContainDate(data, false);
              // delete folder
              this.announcementManagerService.deleteFolder(this.folderSelected.id).subscribe(
                () => {
                  this.handleAfterDeleteFolder(audiosOfFolder);
                  this.isChangeAudioOfSequence = !this.checkDiffDataAudioOfSequences();
                  this.audiosOfFolder = null;
                  let selectedIndex = this.folders.findIndex(folder => folder.id == this.folderSelected.id);
                  if (selectedIndex != -1) {
                    this.folders.splice(selectedIndex, 1);
                    this.folderSelected = undefined;
                    if (this.folders?.length) {
                      this.selectFolder(this.folders[0]);
                    } else {
                      this.isSelectedAudioOrFolder = false;
                      this.isSelectedAudioOfSequence = false;
                      this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
                    }
                  }
                },
                error => Helper.handleError(error, this.translateService, this.dialogService)
              );
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
        }
      }
    );
  }

  /**
   * delete media
   */
  public deleteMedia(): void {
    if (!this.audioSelected?.id || this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(
            this.translateService.instant('announcement-manager.want-delete'),
            `${this.audioSelected.name}.${this.audioSelected.type}`
          ),
          button1: this.translateService.instant('announcement-manager.yes'),
          button2: this.translateService.instant('announcement-manager.no')
        }
      },
      result => {
        if (result) {
          // delete media
          this.announcementManagerService.deleteMedia(this.audioSelected.id).subscribe(
            () => {
              // handle after delete media of folder
              if (this.audiosOfFolder) {
                // case search and delete
                let index = this.oldAudiosOfFolder.findIndex(media => media.id == this.audioSelected.id);
                if (index != -1) {
                  this.oldAudiosOfFolder.splice(index, 1);
                }
                this.dispatchDataToStore(null, null, this.oldAudiosOfFolder, null);
                // handle after delete medias of folder
                this.handleAfterDeleteMedia(this.audiosOfFolder);

                // select media
                this.selectMedia(this.audiosOfFolder.length ? this.audiosOfFolder[0] : undefined);
                this.calculateRowHeight(this.audiosOfFolder.length);
                if (!this.audiosOfFolder.length) {
                  this.isSelectedAudioOrFolder = false;
                  this.isSelectedAudioOfSequence = false;
                  this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
                }
              } else {
                // handle after delete medias
                this.handleAfterDeleteMedia(this.medias);

                // case search and delete
                let oldMedias = this.miniMediaStateOfComponent?.medias;
                if (oldMedias?.length) {
                  let index = oldMedias.findIndex(media => media.id == this.audioSelected.id);
                  if (index != -1) {
                    oldMedias.splice(index, 1);
                  }
                  this.dispatchDataToStore(this.miniMediaStateOfComponent?.folders ?? this.folders, oldMedias, null, null);
                }
                // select media
                this.selectMedia(this.medias.length ? this.medias[0] : undefined);
                if (!this.medias.length) {
                  this.isSelectedAudioOrFolder = false;
                  this.isSelectedAudioOfSequence = false;
                  this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
                }
                this.calculateRowHeight(this.folders.length + this.medias.length);
              }
              this.isChangeAudioOfSequence = !this.checkDiffDataAudioOfSequences();
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
        }
      }
    );
  }

  /**
   * select media
   * @param media
   */
  public selectMedia(media: AnnouncementMedia): void {
    if (this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    this.isSelectedAudioOrFolder = true;
    this.isSelectedAudioOfSequence = true;
    this.dataService.sendData([this.IS_DELETE_DISABLE, this.isSelectedAudioOfSequence]);
    this.audioSelected = media;
    this.folderSelected = undefined;
    this.audioOfSequenceSelected = undefined;

    if (this.audioSelected?.type == TypeMediaFileEnum.MP3) {
      this.pointPercents = [];
      this.pointPercents[0] = this.MAX_WIDTH_TIME_LINE;
      this.valueCursorPoint = 0;
      this.timeCursorPointSecond = 0;
      const totalDuration = Helper.convertDuration(this.audioSelected?.duration);
      this.totalTimeVideo = Helper.formatDurationSimpleSignage(`${isNaN(totalDuration) ? 0 : totalDuration}`);
      this.isPreviewOn = false;
      this.dataService.sendData([this.IS_ON_PREVIEW, this.isPreviewOn]);
      (document.getElementById(this.AUDIO_ANNOUNCEMENT_ID) as HTMLAudioElement)?.load();
    }
  }

  /**
   * handle after delete media
   * @param medias
   */
  private handleAfterDeleteMedia(medias: Array<AnnouncementMedia>): void {
    let selectedIndex = medias.findIndex(media => media.id == this.audioSelected.id);
    if (selectedIndex != -1 && this.playlistSelected) {
      this.audioOfSequences = this.audioOfSequences?.filter(
        mediaOfSequence => mediaOfSequence.idMedia !== medias[selectedIndex].id && mediaOfSequence.idMedia
      );
      this.audioOfSequences = _.cloneDeep(this.convertSequenceToSilenceSequence(this.audioOfSequences));
      this.oldAudioOfSequences = this.oldAudioOfSequences.filter(item => item.idMedia !== medias[selectedIndex].id && item.idMedia);
      this.oldAudioOfSequences = _.cloneDeep(this.convertSequenceToSilenceSequence(this.oldAudioOfSequences));
      this.handleDuration();
      this.handleDurationOld();
    }
    medias.splice(selectedIndex, 1);
  }

  /**
   * convert sequence to silence sequence
   * @param audioOfSequence
   * @returns
   */
  public convertSequenceToSilenceSequence(audioOfSequence: Array<AudioOfSequence>): Array<AudioOfSequence> {
    let newAudioOfSequences = new Array<AudioOfSequence>();
    newAudioOfSequences.length = audioOfSequence.length * 2 - 1;
    let index = 0;
    for (let i = 0; i < newAudioOfSequences?.length; i++) {
      if (i % 2 != 0) {
        let mediaClone = new AudioOfSequence();
        mediaClone.name = this.translateService.instant('announcement-manager.silence');
        mediaClone.duration = 2;
        mediaClone.type = 'mp3';
        mediaClone.size = 0;
        mediaClone.randomNumber = Math.random();
        newAudioOfSequences[i] = mediaClone;
      } else {
        newAudioOfSequences[i] = audioOfSequence[index];
        index++;
      }
    }
    return newAudioOfSequences;
  }

  /**
   * import media
   */
  private importMedia(): void {
    // search => prevent import data
    if (this.searchInputValue?.length) {
      return;
    }
    let element = document.getElementById(this.INPUT_IMPORT_ID) as HTMLInputElement;
    element.setAttribute('accept', '.mp3');
    element.click();
  }

  /**
   * upload media files
   * @param event
   */
  async upload(event: any): Promise<void> {
    // get all media from db
    let mediaFromDB: any[] = [];
    let mediaNameSelects: string[] = [];
    await this.announcementManagerService
      .getAllMediaFromDB()
      .toPromise()
      .then(async data => {
        mediaFromDB = Helper.convertDataAnnouncementMediasNotContainDate(data, true).filter(
          media => !TypeMediasNotSupported.includes(media.type)
        );
      });

    let selectedFiles: any[] = event.target.files;
    if (!selectedFiles || selectedFiles.length <= 0) {
      return;
    }
    for (let file of selectedFiles) {
      mediaNameSelects.push(file.name);
    }
    // check duplicate file name when import a file or multi files
    let mediaNames = mediaFromDB.map(media => media.name);
    for (const file of mediaNameSelects) {
      let nameConvert = file.substring(0, file.lastIndexOf('.'));
      if (mediaNames.includes(nameConvert)) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('announcement-manager.error'),
            text: this.translateService.instant('announcement-manager.duplicate-file-import')
          }
        });
        this.refreshInputFile();
        return;
      }
    }
    this.errorMessageImportFile = await this.validateAnnouncementMediaInFrontEnd(selectedFiles);
    if (!this.errorMessageImportFile.isValid) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          texts: this.errorMessageList,
          validateImport: this.errorMessageImportFile
        }
      });
    } else {
      // upload file
      for (const file of selectedFiles) {
        await this.addNewAnnouncementMedia(file);
      }
      if (this.errorMessageList.length > 0 || !this.errorMessageImportFile.isValid) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('announcement-manager.error'),
            texts: this.errorMessageList,
            validateImport: this.errorMessageImportFile
          }
        });
      }
    }
    this.refreshInputFile();
  }

  /**
   * Add new announcement media
   *
   * @param file file
   */
  private async addNewAnnouncementMedia(file: any): Promise<void> {
    const folder = this.miniMediaStateOfComponent?.folderSelected;
    await this.announcementManagerService
      .addNewAnnouncementMedia(file, folder?.id ?? null)
      .toPromise()
      .then(
        data => {
          if (!data) {
            this.errorMessageImportFile.otherError.push(file[Constant.NAME_ELEMENT]);
            return;
          }
          let errorMessageImportFile = this.getErrorMessageFromBackWard(data, file);
          if (!errorMessageImportFile.isValid) {
            this.errorMessageImportFile = errorMessageImportFile;
            return;
          }
          const media = Helper.convertDataAnnouncementMediaNotContainDate(data, true);
          if (this.audiosOfFolder) {
            this.audiosOfFolder.push(media);
            this.oldAudiosOfFolder = _.cloneDeep(this.audiosOfFolder);
          } else {
            this.medias.push(media);
          }
          this.calculateRowHeight(this.folders.length + this.medias.length);
        },
        error => {
          if (error.status == Constant.NETWORK_ERROR_CODE) {
            if (!this.errorMessageList.includes(this.translateService.instant('dialog-error.error-network'))) {
              this.errorMessageList.push(this.translateService.instant('dialog-error.error-network'));
            }
            return;
          }
          this.errorMessageImportFile.otherError.push(file[Constant.NAME_ELEMENT]);
        }
      );
  }

  /**
   * Get error message from backward
   *
   * @param data
   * @param file
   * @returns
   */
  private getErrorMessageFromBackWard(data: AnnouncementMedia, file: any): MessageErrorImportFile {
    let messageErrorImportFile = new MessageErrorImportFile(null, null, false, null, true);
    switch (data.id) {
      case ErrorCodeAnnouncementMedia.ERROR_MAX_LENGTH_MEDIA_CODE:
        messageErrorImportFile.isMaxLengthError = true;
        messageErrorImportFile.isValid = this.calculateIsValid(messageErrorImportFile);
        break;
      case ErrorCodeSimpleMedia.ERROR_OTHER_CODE:
        messageErrorImportFile.otherError = file[Constant.NAME_ELEMENT];
        messageErrorImportFile.isValid = this.calculateIsValid(messageErrorImportFile);
        break;
      default:
        break;
    }
    return messageErrorImportFile;
  }

  /**
   * refresh input file
   */
  private refreshInputFile(): void {
    // reset input file value
    let element = document.getElementById(this.INPUT_IMPORT_ID) as HTMLInputElement;
    element.value = null;
    this.errorMessageList = [];
    this.errorMessageImportFile = new MessageErrorImportFile(null, null, false, null, true);
  }

  /**
   * Validate data simple media in front end
   *
   * @param files
   * @returns
   */
  private async validateAnnouncementMediaInFrontEnd(files: any[]): Promise<MessageErrorImportFile> {
    const errorFiles = {
      maxLengthErrorFiles: [],
      formatErrorFiles: [],
      specialCharErrorFiles: []
    };

    let messageErrorImportFile = new MessageErrorImportFile(null, null, false, null, true);
    const typeMedias = [TypeMediaFileEnum.MP3];
    for (const file of files) {
      let typeName = file.name.slice(file.name.lastIndexOf('.') + 1, file.name.length).toLowerCase();
      let fileName = file.name.slice(0, file.name.lastIndexOf('.'));
      // validate type file
      if (!typeMedias.includes(typeName)) {
        errorFiles.formatErrorFiles.push(file.name);
        continue;
      }
      // validate max length
      if (fileName.length > Constant.MAX_LENGTH_MEDIA_NAME_IN_TAB_CREATE_SEQUENCE) {
        errorFiles.maxLengthErrorFiles.push(file.name);
        continue;
      }
      // validate special characters
      if (fileName.match(Constant.FORMAT) || fileName.includes('\\')) {
        errorFiles.specialCharErrorFiles.push(file.name);
        continue;
      }
    }
    if (errorFiles.formatErrorFiles.length > 0) {
      messageErrorImportFile.formatErrorFile = errorFiles.formatErrorFiles;
      messageErrorImportFile.isValid = this.calculateIsValid(messageErrorImportFile);
      return messageErrorImportFile;
    }

    if (errorFiles.maxLengthErrorFiles.length > 0) {
      messageErrorImportFile.isMaxLengthError = true;
      messageErrorImportFile.isValid = this.calculateIsValid(messageErrorImportFile);
      return messageErrorImportFile;
    }

    if (errorFiles.specialCharErrorFiles.length > 0) {
      messageErrorImportFile.specialCharError = errorFiles.specialCharErrorFiles;
      messageErrorImportFile.isValid = this.calculateIsValid(messageErrorImportFile);
      return messageErrorImportFile;
    }
    return messageErrorImportFile;
  }
  /**
   * Get error message
   *
   * @param errorMessage
   * @param mediaName
   * @param errorValue
   * @returns
   */
  private getErrorMessage(errorMessage: string, mediaName: string, errorValue: string): string {
    return Helper.formatString(this.translateService.instant(`announcement-manager.${errorMessage}`), mediaName, errorValue);
  }

  /**
   * get all playlists
   */
  private getAllPlaylists(): void {
    this.announcementManagerService.getAllPlaylists().subscribe(data => {
      this.playlists = Helper.convertDataAnnouncementPlaylists(data);
      this.playlistsCommon = this.playlists.filter(playlist => playlist.playlistType == Constant.TYPE_PLAYLIST_GROUP_COMMON);
      this.playlistsSpecific = this.playlists.filter(playlist => playlist.playlistType == Constant.TYPE_PLAYLIST_GROUP_SPECIAL);
      this.playlistsCommon = this.playlistsCommon.sort((p1, p2) => {
        return +p1.id - +p2.id;
      });
      this.playlistsSpecific = this.playlistsSpecific.sort((p1, p2) => {
        return +p1.id - +p2.id;
      });
      this.selectPlaylistGroup(this.groupPlaylistCommon);
    });
  }

  /**
   * get all data folder and media in mini media
   */
  public async getDataMiniMedia(): Promise<void> {
    // get all folder medias
    this.announcementManagerService.getAllFolderMedias().subscribe(data => {
      this.folders = Helper.convertDataAnnouncementFolderMedias(data);
      // get all medias
      this.getAllMedias();
    });
  }

  /**
   * get all medias
   */
  private getAllMedias(): void {
    this.announcementManagerService.getMediasByFolderId(null).subscribe(data => {
      this.medias = Helper.convertDataAnnouncementMediasNotContainDate(data, true).filter(
        media => !TypeMediasNotSupported.includes(media.type)
      );
      this.calculateRowHeight(this.folders.length + this.medias.length);
    });
  }

  /**
   *
   * @param playlistGroup
   */
  public expandPlaylistGroup(playlistGroup: any): void {
    if (
      this.playlistSelected?.isEdit ||
      this.isPreviewOn ||
      this.playlistGroupSelected == playlistGroup ||
      this.playlistSelected?.playlistType == playlistGroup.id
    ) {
      return;
    }
    playlistGroup.isExpand = !playlistGroup.isExpand;
  }

  /**
   * selectPlaylistGroup
   *
   * @param playlistGroup
   * @param isCheckChangeData
   */
  public selectPlaylistGroup(playlistGroup: any, isCheckChangeData?: boolean): void {
    if (this.playlistSelected?.isEdit || this.isPreviewOn) {
      return;
    }
    this.isChangeAudioOfSequence = !this.checkDiffDataAudioOfSequences();
    if (this.isChangeAudioOfSequence && isCheckChangeData) {
      this.isChangedData = true;
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.save-changes'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          this.isChangedData = false;
          if (result) {
            this.saveSequence(true);
            const sub = this.saveDataSuccess.subscribe(isSuccess => {
              sub.unsubscribe();
              if (isSuccess) {
                this.audioOfSequences = null;
                this.handleSelectPlaylistGroup(playlistGroup);
              }
            });
          } else {
            this.audioOfSequences = null;
            this.handleSelectPlaylistGroup(playlistGroup);
          }
        }
      );
    } else {
      this.handleSelectPlaylistGroup(playlistGroup);
    }
  }

  /**
   * handleSelectPlaylistGroup
   */
  private handleSelectPlaylistGroup(playlistGroup: any): void {
    this.playlistGroupSelected = playlistGroup;
    playlistGroup.isExpand = true;
    this.playlistSelected = undefined;
    this.playlistSelectedClone = _.cloneDeep(this.playlistSelected);
    this.audioOfSequences = undefined;
    this.oldAudioOfSequences = undefined;
    this.audioOfSequenceSelected = undefined;
    this.totalTime = undefined;
    this.totalSize = undefined;
  }

  /**
   * edit playlist
   * @returns
   */
  private editPlaylist(): void {
    if (!this.playlistSelected || !this.playlists?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.select-pl')
        }
      });
      return;
    } else if (this.playlistSelected.isEdit || this.isPreviewOn) {
      return;
    }
    this.playlistSelected.isEdit = true;
    this.oldValuePlaylistName = this.playlistSelected.name;
    this.dataService.sendData([this.IS_EDIT_PLAYLIST, this.playlistSelected?.isEdit]);
    this.isChangedData = true;
  }

  /**
   * duplicate playlist
   * @returns
   */
  private duplicatePlaylist(): void {
    if (this.playlistSelected?.isEdit || this.isPreviewOn) {
      return;
    }
    if (!this.playlistSelected || !this.playlists?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.select-pl')
        }
      });
      return;
    }
    this.announcementManagerService.duplicatePlaylist(this.playlistSelected).subscribe(
      data => {
        if (!data) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('announcement-manager.error'),
              text: Helper.formatString(this.translateService.instant('announcement-manager.length-pl'), `${this.MAX_LENGTH_PLAYLIST_NAME}`)
            }
          });
          return;
        }
        if (!data.id) {
          return;
        }
        let playlistClone =
          this.playlistSelected.playlistType == Constant.TYPE_PLAYLIST_GROUP_COMMON ? this.playlistsCommon : this.playlistsSpecific;
        playlistClone.push(Helper.convertDataAnnouncementPlaylist(data));
        this.selectPlaylist(playlistClone[playlistClone.length - 1]);
      },
      error => Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * delete playlist
   * @returns
   */
  private deletePlaylist(): void {
    if (this.playlistSelected?.isEdit || this.isPreviewOn) {
      return;
    }
    if (!this.playlistSelected || !this.playlists?.length) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.select-pl')
        }
      });
      return;
    }
    this.dialogService.showDialog(
      DialogConfirmComponent,
      {
        data: {
          text: Helper.formatString(this.translateService.instant('announcement-manager.want-delete'), this.playlistSelected.name),
          button1: this.translateService.instant('announcement-manager.yes'),
          button2: this.translateService.instant('announcement-manager.no')
        }
      },
      result => {
        if (result) {
          this.announcementManagerService.deletePlaylist(this.playlistSelected.id).subscribe(
            () => {
              let playlistClone =
                this.playlistSelected && this.playlistSelected.playlistType == Constant.TYPE_PLAYLIST_GROUP_COMMON
                  ? this.playlistsCommon
                  : this.playlistsSpecific;
              let selectedIndex = playlistClone.findIndex(playlist => playlist.id == this.playlistSelected.id);
              if (selectedIndex == -1) {
                return;
              }
              playlistClone.splice(selectedIndex, 1);
              this.audioOfSequences = [];
              this.oldAudioOfSequences = [];
              this.playlistSelected = undefined;
              this.playlistSelectedClone = _.cloneDeep(this.playlistSelected);
              if (playlistClone?.length) {
                this.selectPlaylist(playlistClone[0]);
              } else {
                this.selectPlaylistGroup(this.groupPlaylistCommon);
              }
            },
            error => Helper.handleError(error, this.translateService, this.dialogService)
          );
        }
      }
    );
  }

  /**
   * edit folder
   * @returns
   */
  private editFolder(): void {
    if (this.isPreviewOn || this.folderSelected?.isEdit) {
      return;
    }
    if (!this.folderSelected) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.choose-folder')
        }
      });
      return;
    } else if (this.folderSelected.isEdit) {
      return;
    }
    this.folderSelected.isEdit = true;
    this.oldValueFolderName = this.folderSelected.name;
    this.dataService.sendData([this.IS_EDIT_FOLDER, this.folderSelected.isEdit]);
    this.isChangedData = true;
  }

  /**
   * handle after delete folder
   * @param mediasOfFolder
   */
  private handleAfterDeleteFolder(audioOfSequences: Array<AnnouncementMedia>): void {
    if (!audioOfSequences?.length) {
      return;
    }
    // get list media's id deleted
    let mediaIdsDeleted = audioOfSequences.map(media => media.id);
    this.audioOfSequences = this.audioOfSequences?.filter(audioOfSequence => !mediaIdsDeleted.includes(audioOfSequence.idMedia));
    this.oldAudioOfSequences = this.oldAudioOfSequences?.filter(item => !mediaIdsDeleted.includes(item.idMedia));
    this.calculateRowHeight(this.folders.length + this.medias.length);
  }

  /**
   * announcement timing
   */
  public playTimingSetting(): void {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_PLAY_TIMING_SETTING).subscribe(data => {
      this.commonTableService.getValueCommonTableByKey(Constant.KEY_TIMETABLE_CHANGE_DATE_LINE_TIMETABLE).subscribe(changeDateLine => {
        changeDateLine = moment(!changeDateLine ? Constant.TIME_DATE_LINE_DEFAULT : changeDateLine.value, Constant.FORMAT_TIME_TO_MINUTES);
        let saveListCommon = this.listCommonPlaylistRegistration?.filter(common => !common.isGroup);
        saveListCommon = Helper.convertCommonPlaylistRegistrationsToBackend(saveListCommon);
        let isConfirmTurnOff = this.customtagClones?.some(device => {
          return device.playlistSpecific?.some(playlist => playlist.id);
        });
        this.dialogService.showDialog(
          DialogPlayTimingSettingComponent,
          {
            data: {
              playTimingSetting: data,
              timeDateline: changeDateLine,
              tabSelected: this.tabSelected,
              saveListCommon: saveListCommon,
              isConfirmTurnOff: isConfirmTurnOff
            }
          },
          result => {
            if (!result) {
              return;
            }
            this.objectTiming = JSON.parse(result.value);
            this.commonTableService.save(result).subscribe();
          }
        );
      });
    });
  }

  /**
   * specific sequence setting
   */
  public specificSequenceSetting(): void {
    this.dialogService.showDialog(DialogSpecificSequenceSettingComponent);
  }

  /**
   * get all data common playlist
   */
  public getAllDataCommonPlaylistRegistration() {
    this.commonTableService.getValueCommonTableByKey(Constant.KEY_PLAY_TIMING_SETTING).subscribe(dataDateLine => {
      this.objectTiming = !dataDateLine
        ? new PlayTimingSetting(null, null, null, null, null, false, false)
        : JSON.parse(dataDateLine.value);
      this.announcementManagerService.getListCommonPlaylistRegistration().subscribe(data => {
        if (data) {
          this.listCommonPlaylistRegistration = Helper.convertCommonPlaylistRegistrationsFromBackend(data);
          this.deviceCommonSpecifics = Helper.convertCommonRegistrationsToDeviceCommonSpecific(this.listCommonPlaylistRegistration);
          const listName = [...new Set(this.listCommonPlaylistRegistration.map(commonPlaylist => commonPlaylist.customTagElement.name))];
          listName?.forEach(name => {
            let customTag = new CommonPlaylistRegistration();
            customTag.deviceName = name;
            customTag.isGroup = true;
            this.listCommonPlaylistRegistration.push(customTag);
          });
          this.oldCommonPlaylistRegistrations = _.cloneDeep(this.listCommonPlaylistRegistration);
          this.customtagClones = this.listCommonPlaylistRegistration.filter(e => e.isGroup);
          if (!this.playlists) {
            this.announcementManagerService.getAllPlaylists().subscribe(dataPlaylist => {
              if (dataPlaylist) {
                this.playlists = Helper.convertDataAnnouncementPlaylists(dataPlaylist);
                this.playlistsCommon = this.playlists.filter(playlist => playlist.playlistType == Constant.TYPE_PLAYLIST_GROUP_COMMON);
                this.playlistsCommon = this.playlistsCommon.sort((p1, p2) => {
                  return +p1.id - +p2.id;
                });
              }
            });
          }
        }
      });
    });
  }

  /**
   * checkAll
   */
  public checkAll(): void {
    this.isCheckedAll = !this.isCheckedAll;
    this.customtagClones?.forEach(group => {
      group.isChecking = this.isCheckedAll;
    });
    this.listCommonPlaylistRegistration?.forEach(device => {
      device.isChecking = this.isCheckedAll;
    });
  }

  /**
   * check All Language
   */
  public checkAllLanguage(): void {
    this.isCheckedAllLanguage = !this.isCheckedAllLanguage;
    this.listSpeechSynthesis.forEach(e => {
      if (e.translationLanguageCode) {
        e.isChecked = this.isCheckedAllLanguage;
      }
    });
  }

  /**
   * select device
   * @param commonRegistration
   */
  public selectDevice(commonRegistration: CommonPlaylistRegistration): void {
    if (!commonRegistration.isGroup) {
      this.commonPlaylistRegistrationSelecting = commonRegistration;
      this.customtagCloneSelecting = _.cloneDeep(this.commonPlaylistRegistrationSelecting);
    }
  }

  /**
   * check device
   * @param device device selected
   */
  changeChecked(commonRegistration: CommonPlaylistRegistration) {
    commonRegistration.isSelecting = !commonRegistration.isSelecting;
  }

  /**
   * check translation
   * @param device translation selected
   */
  changeCheckedTranslation(speechSynthesis: SpeechSynthesis) {
    speechSynthesis.isChecked = !speechSynthesis.isChecked;
    this.isCheckedAllLanguage = this.listSpeechSynthesis.filter(e => e.translationLanguageCode).every(e => e.isChecked);
  }

  /**
   * check device
   * @param device device selected
   */
  changeCheckedPlaylist(playlist: AnnouncementPlaylist, index: number) {
    let deviceChecking = this.listCommonPlaylistRegistration?.filter(device => device.isChecking && !device.isGroup);
    if (deviceChecking.length > 0 && !this.deviceSelected.isChecking) {
      let el = document.getElementById(`device${index}`) as HTMLInputElement;
      el.checked = playlist.isChecking;
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('announcement-manager.select-device')
        }
      });
      return;
    }
    playlist.isChecking = !playlist.isChecking;
    let idPlaylistsInDevice = this.deviceSelected ? this.deviceSelected.playlistSpecific?.map(playlist => playlist.id) : [];
    idPlaylistsInDevice?.sort((a, b) => (a > b ? 1 : -1));
    let idPlayInSpecifics = this.playlistsSpecific?.filter(e => e.isChecking)?.map(item => item.id);
    idPlayInSpecifics?.sort((a, b) => (a > b ? 1 : -1));
    if (idPlaylistsInDevice == undefined && idPlayInSpecifics.length == 0) {
      this.isChangeSpecificRegistration = false;
    } else if (!_.isEqual(idPlaylistsInDevice, idPlayInSpecifics)) {
      this.isChangeSpecificRegistration = true;
    } else {
      this.isChangeSpecificRegistration = false;
    }
  }

  /**
   * check all custom tag element
   * @param data
   * @param index
   */
  public changeCheckCustomTagElement(e, data: CommonPlaylistRegistration): void {
    e.stopPropagation();
    data.isChecking = !data.isChecking;
    if (data.isGroup) {
      this.listCommonPlaylistRegistration.forEach(device => {
        if (device.customTagElement?.name == data.deviceName && !device.isGroup) {
          device.isChecking = data.isChecking;
        }
      });
      this.customtagClones.forEach(device => {
        if (device.customTagElement?.name == data.deviceName && !device.isGroup) {
          device.isChecking = data.isChecking;
        }
      });
      this.isCheckedAll = this.customtagClones?.every(group => group.isChecking);
    } else {
      let customtagName = data.customTagElement.name;
      let index = this.customtagClones.findIndex(e => e.deviceName == customtagName);
      let dataCustomtagGroup = this.customtagClones.filter(e => e.customTagElement?.name == customtagName);
      if (dataCustomtagGroup.every(item => item.isChecking)) {
        this.customtagClones[index].isChecking = true;
      } else {
        this.customtagClones[index].isChecking = false;
      }
    }
    this.isCheckedAll = this.customtagClones?.every(group => group.isChecking);
  }

  /**
   * open custom tag
   * @param customTagElement
   * @param isFirstGo
   */
  public openCustomTagElement(customTagElement: CommonPlaylistRegistration, isClick?: boolean): void {
    if (isClick) {
      customTagElement.isExpand = !customTagElement.isExpand;
    } else {
      customTagElement.isExpand = true;
    }
    let listExpand = this.listCommonPlaylistRegistration.filter(e => e.customTagElement?.name == customTagElement.deviceName);
    let index = this.customtagClones.findIndex(i => i == customTagElement);
    if (customTagElement.isExpand) {
      this.customtagClones.splice(index + 1, 0, ...listExpand);
    } else {
      this.customtagClones.splice(index + 1, listExpand.length);
    }
  }

  /**
   * saveCommonRegistration
   * @param isClickDelivery
   * @returns
   */
  public saveCommonRegistration(isClickDelivery?: boolean): void {
    let saveListCommon = _.cloneDeep(this.listCommonPlaylistRegistration?.filter(common => !common.isGroup));
    saveListCommon = Helper.convertCommonPlaylistRegistrationsToBackend(saveListCommon);
    let customList = this.customtagClones.filter(cusomtag => cusomtag.isChecking);
    if (customList.length > 1) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.multi-select'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          if (!result) {
            this.cancelCommonRegistration();
            return;
          } else {
            if (this.validateBeforeSaveCommonRegistration(saveListCommon)) {
              return;
            } else {
              this.announcementManagerService.saveCommonRegistration(saveListCommon).subscribe(data => {
                this.saveDataSuccess.emit(true);
                this.handleDataAfterGetCommonRegistration(data);
                if (isClickDelivery) {
                  this.showDeliveryPopup();
                }
              });
            }
          }
        }
      );
    } else {
      if (this.validateBeforeSaveCommonRegistration(saveListCommon)) {
        return;
      } else {
        this.announcementManagerService.saveCommonRegistration(saveListCommon).subscribe(data => {
          this.saveDataSuccess.emit(true);
          this.handleDataAfterGetCommonRegistration(data);
          if (isClickDelivery) {
            this.showDeliveryPopup();
          }
        });
      }
    }
  }

  /**
   * handle data after get common registration
   * @param data
   */
  public handleDataAfterGetCommonRegistration(data: Array<CommonPlaylistRegistration>): void {
    this.listCommonPlaylistRegistration = Helper.convertCommonPlaylistRegistrationsFromBackend(data);
    const listName = [...new Set(this.listCommonPlaylistRegistration.map(commonPlaylist => commonPlaylist.customTagElement.name))];
    listName?.forEach(name => {
      if (!this.customtagClones?.map(e => e.deviceName).includes(name)) {
        let customTag = new CommonPlaylistRegistration();
        customTag.deviceName = name;
        customTag.isGroup = true;
        this.listCommonPlaylistRegistration.push(customTag);
        this.customtagClones?.push(customTag);
      }
    });
    if (this.customtagClones.length) {
      this.customtagClones.forEach(e => {
        if (!e.isGroup) {
          let index = this.listCommonPlaylistRegistration.findIndex(i => i.deviceId == e.deviceId);
          this.listCommonPlaylistRegistration[index].isChecking = e.isChecking;
          e = this.listCommonPlaylistRegistration[index];
        } else {
          this.listCommonPlaylistRegistration.push(e);
        }
      });
    }
    this.oldCommonPlaylistRegistrations = _.cloneDeep(this.listCommonPlaylistRegistration);
    if (this.customtagClones.length < 1) {
      this.customtagClones = this.listCommonPlaylistRegistration.filter(e => e.isGroup);
    }
    this.isChangeCommonRegistration = !this.checkDiffDataCommonPlaylistRegistration();
  }

  /**
   * validate before save common registration
   * @returns
   */
  public validateBeforeSaveCommonRegistration(listCommon: Array<CommonPlaylistRegistration>): boolean {
    let isInvalid: boolean = false;
    let listCommon1 = listCommon.map(common => common.commonAnnouncement1);
    let listCommon2 = listCommon.map(common => common.commonAnnouncement2);
    if (
      !this.objectTiming.playbackTime1 &&
      !this.objectTiming.playbackTime2 &&
      (listCommon1.some(data => data != null || data != undefined) || listCommon2.some(data => data != null || data != undefined))
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.empty-playtiming-common-registration')
        }
      });
      isInvalid = true;
    } else if (
      !this.objectTiming.playbackTime1 &&
      listCommon1.some(data => data != null || data != undefined) &&
      this.objectTiming.playbackTime2
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.empty-playtiming1')
        }
      });
      isInvalid = true;
    } else if (
      this.objectTiming.playbackTime1 &&
      !this.objectTiming.playbackTime2 &&
      listCommon2.some(data => data != null || data != undefined)
    ) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.empty-playtiming2')
        }
      });
      isInvalid = true;
    }
    return isInvalid;
  }

  /**
   * cancel common registration
   */
  public cancelCommonRegistration(): void {
    this.listCommonPlaylistRegistration = _.cloneDeep(this.oldCommonPlaylistRegistrations);
    this.listCommonPlaylistRegistration?.forEach(device => {
      device.isExpand = false;
      device.isChecking = false;
    });
    this.isCheckedAll = false;
    this.customtagClones = this.listCommonPlaylistRegistration.filter(e => e.isGroup);
    this.isChangeCommonRegistration = !this.checkDiffDataCommonPlaylistRegistration();
    // Select device
    if (this.customtagClones[0]) {
      this.openCustomTagElement(this.customtagClones[0]);
      this.selectDevice(this.customtagClones[1]);
    }
  }

  /**
   * change data
   * @param type
   * @param e
   */
  public changeData(type: string, e): void {
    let index = this.customtagClones.findIndex(custom => custom.id == this.commonPlaylistRegistrationSelecting.id);
    let indexCommon = this.listCommonPlaylistRegistration.findIndex(custom => custom.id == this.commonPlaylistRegistrationSelecting.id);
    if (!this.commonPlaylistRegistrationSelecting.isChecking && !this.customtagClones.every(data => !data.isChecking)) {
      this.customtagClones[index] = this.customtagCloneSelecting;
      this.listCommonPlaylistRegistration[indexCommon] = this.customtagCloneSelecting;
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('announcement-manager.error'),
          text: this.translateService.instant('announcement-manager.select-device')
        }
      });
      return;
    }
    this.listCommonPlaylistRegistration[indexCommon] = this.customtagClones[index];
    if (type == 'commonAnnouncement1') {
      this.customtagClones.forEach(customTag => {
        if (customTag.isChecking) {
          customTag.commonAnnouncement1 = e;
        }
      });
    } else if (type == 'commonAnnouncement2') {
      this.customtagClones.forEach(customTag => {
        if (customTag.isChecking) {
          customTag.commonAnnouncement2 = e;
        }
      });
    } else {
      this.customtagClones.forEach(customTag => {
        if (customTag.isChecking) {
          customTag.periodicAnnouncement = e;
        }
      });
    }

    // let i = this.listCommonPlaylistRegistration.findIndex(e => e.id == this.commonPlaylistRegistrationSelecting.id);
    // this.listCommonPlaylistRegistration[i] = this.commonPlaylistRegistrationSelecting;
    this.listCommonPlaylistRegistration.forEach(dv => {
      if (dv.isChecking) {
        if (type == 'commonAnnouncement1') {
          dv.commonAnnouncement1 = e;
        } else if (type == 'commonAnnouncement2') {
          dv.commonAnnouncement2 = e;
        } else {
          dv.periodicAnnouncement = e;
        }
      }
    });
    this.isChangeCommonRegistration = !this.checkDiffDataCommonPlaylistRegistration();
  }

  /**
   * check diff data common playlist registration
   * @returns
   */
  private checkDiffDataCommonPlaylistRegistration(): boolean {
    let oldData = Helper.convertDataToCheckDiffCommonRegistration(this.oldCommonPlaylistRegistrations);
    let commonRegsitrationsNew = _.cloneDeep(this.listCommonPlaylistRegistration);
    let newData = Helper.convertDataToCheckDiffCommonRegistration(commonRegsitrationsNew);
    // return true if equals
    return JSON.stringify(oldData) == JSON.stringify(newData);
  }

  /**
   *selectDeviceRegistration
   * @param device
   * @param event
   * @returns
   */
  public selectDeviceRegistration(device: CommonPlaylistRegistration, event: any): void {
    if (!device || event?.target?.id?.startsWith('check-box') || device.isGroup || this.deviceSelected?.id == device.id) {
      return;
    }
    if (this.isChangeSpecificRegistration) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.save-changes'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          if (!result) {
            this.isChangeSpecificRegistration = false;
            this.handleCheckSpecific(device);
            this.deviceSelected = device;
          } else {
            this.savePlaylistForDevice();
            var interval = null;
            interval = setInterval(() => {
              if (!this.isChangeSpecificRegistration) {
                this.handleCheckSpecific(device);
                this.deviceSelected = device;
                clearInterval(interval);
              }
            }, 50);
          }
        }
      );
    } else {
      this.deviceSelected = device;
      this.handleCheckSpecific(device);
    }
  }

  /**
   *selectDeviceRegistration
   * @param device
   * @returns
   */
  public async selectDevicePlaylist(device: CommonPlaylistRegistration): Promise<void> {
    if (device.isGroup) {
      return;
    }
    this.deviceSelectedPlaylist = device;
    this.lastTimeUpdate = null;
    this.timeByImg = null;
    this.grayOut = false;
    this.indexLiveTime = 0;
    this.isLiveTimeRunning = false;
    this.headers = _.cloneDeep(this.headersOriginal);
    this.announcementManagerService.listScheduleFromAPI = null;
    this.announcementManagerService.listScheduleCanRun = null;
    this.schedules = null;
    this.dataService.sendData([Constant.IS_NETWORK_OK, false]);
    this.dataService.sendData([Constant.IS_CALL_API_A01, false]);
    this.getDataCurrentDayForDeviceSelected();
  }
  /**
   * handleCheckSpecific
   * @param device
   */
  private handleCheckSpecific(device: CommonPlaylistRegistration): void {
    const idPlaylists = device.playlistSpecific?.map(playlist => playlist.id);
    this.playlistsSpecific?.forEach(e => {
      if (idPlaylists?.length && idPlaylists.includes(e.id)) {
        e.isChecking = true;
      } else {
        e.isChecking = false;
      }
    });
  }

  /**
   * savePlaylistForDevice
   * @param isClickDelivery
   * @returns
   */
  public savePlaylistForDevice(isClickDelivery?: boolean): void {
    let playlistChecking = this.playlistsSpecific?.filter(playlist => playlist.isChecking);
    let deviceChecking = this.listCommonPlaylistRegistration?.filter(device => device.isChecking && !device.isGroup);
    if (deviceChecking.length > 0 && !this.deviceSelected.isChecking) {
      this.saveDataSuccess.emit(false);
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('announcement-manager.select-device')
        }
      });
      return;
    }
    if (deviceChecking.length >= 2) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            title: this.translateService.instant('dialog-confirm.title'),
            text: this.translateService.instant('announcement-manager.multi-select'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          if (!result) {
            this.cancelPlaylistForDevice();
            this.saveDataSuccess.emit(true);
            return;
          }
          if (this.validateSavePlaylistSpecific()) {
            this.saveDataSuccess.emit(false);
            return;
          }
          deviceChecking.forEach(device => {
            device.playlistSpecific = playlistChecking;
          });
          this.saveSequenceRegistration();
          if (isClickDelivery) {
            this.showDeliveryPopup();
          }
        }
      );
    } else {
      if (this.validateSavePlaylistSpecific()) {
        this.saveDataSuccess.emit(false);
        return;
      }
      if (deviceChecking[0]) {
        deviceChecking[0].playlistSpecific = playlistChecking;
      } else {
        this.deviceSelected.playlistSpecific = playlistChecking;
      }
      this.saveSequenceRegistration();
      if (isClickDelivery) {
        this.showDeliveryPopup();
      }
    }
  }

  /**
   * validateSavePlaylistSpecific
   */
  private validateSavePlaylistSpecific(): boolean {
    let playlistChecking = this.playlistsSpecific?.filter(playlist => playlist.isChecking);
    if ((!this.objectTiming || (!this.objectTiming.playbackTime1 && !this.objectTiming.playbackTime2)) && playlistChecking.length > 0) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('announcement-manager.empty-playtiming-playlist-specific')
        }
      });
      return true;
    }
    if (playlistChecking.length > 100) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-error.title'),
          text: this.translateService.instant('announcement-manager.max-playlist-for-device')
        }
      });
      return true;
    }
    return false;
  }
  /**
   * saveSequenceRegistration
   */
  private saveSequenceRegistration(isChangeTab?: any, tabEnum?: any): void {
    if (isChangeTab) {
      let playlistChecking = this.playlistsSpecific?.filter(playlist => playlist.isChecking);
      let deviceChecking = this.listCommonPlaylistRegistration?.filter(device => device.isChecking && !device.isGroup);
      if (deviceChecking.length > 1) {
        deviceChecking.forEach(device => {
          device.playlistSpecific = playlistChecking;
        });
      } else {
        if (deviceChecking[0]) {
          deviceChecking[0].playlistSpecific = playlistChecking;
        } else {
          this.deviceSelected.playlistSpecific = playlistChecking;
        }
      }
    }
    let saveListCommon = this.listCommonPlaylistRegistration?.filter(common => !common.isGroup);
    this.announcementManagerService.saveCommonRegistration(saveListCommon).subscribe(data => {
      this.saveDataSuccess.emit(true);
      this.isChangeSpecificRegistration = false;
      if (isChangeTab) {
        this.switchTab(tabEnum);
      }
    });
  }
  /**
   * cancelPlaylistForDevice
   */
  public cancelPlaylistForDevice(): void {
    const idPlaylists = this.deviceSelected ? this.deviceSelected.playlistSpecific?.map(playlist => playlist.id) : [];
    this.playlistsSpecific?.forEach(e => {
      if (idPlaylists?.length && idPlaylists.includes(e.id)) {
        e.isChecking = true;
      } else {
        e.isChecking = false;
      }
    });
    this.isChangeSpecificRegistration = false;
  }

  /**
   * showDeliveryPopup
   */
  private showDeliveryPopup(): void {
    let groupDeviceAnnouncement = this.convertDeviceCommonSpecificsToGroupDeviceAnnouncement(this.deviceCommonSpecifics);
    // open popup
    this.dialogService.showDialog(DialogDeliveryAnnouncementComponent, {
      data: {
        groupDevices: _.cloneDeep(groupDeviceAnnouncement)
      }
    });
  }

  /**
   * delivery
   * @returns
   */
  public delivery(): void {
    // return if choose tab playlist
    if (this.tabSelected == Constant.PLAYLIST_ENUM || this.tabSelected == Constant.CREATE_SEQUENCE_ENUM) {
      return;
    }
    if (this.isChangeCommonRegistration || this.isChangeSpecificRegistration) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.save-changes1'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          if (!result) {
            if (this.tabSelected == Constant.COMMON_SEQUENCE_ENUM) {
              this.cancelCommonRegistration();
            } else if (this.tabSelected == Constant.SPECIFIC_SEQUENCE_ENUM) {
              this.cancelPlaylistForDevice();
            }
          } else if (this.tabSelected == Constant.COMMON_SEQUENCE_ENUM) {
            this.saveCommonRegistration(true);
          } else if (this.tabSelected == Constant.SPECIFIC_SEQUENCE_ENUM) {
            this.savePlaylistForDevice(true);
          }
        }
      );
    } else {
      this.showDeliveryPopup();
    }
  }

  /**
   * convert device common specific to group device announcement
   * @param listCommonPlaylistRegistration
   * @returns
   */
  public convertDeviceCommonSpecificsToGroupDeviceAnnouncement(
    deviceCommonSpecifics: Array<DeviceCommonSpecific>
  ): Array<GroupDeviceAnnouncement> {
    const nameGroups = [...new Set(deviceCommonSpecifics?.map(commonPlaylist => commonPlaylist.nameGroup))];
    let groupDeviceCommon: GroupDeviceAnnouncement[] = new Array();
    nameGroups?.forEach(nameGroup => {
      let groupDevice = new GroupDeviceAnnouncement();
      groupDevice.name = nameGroup;
      let deviceData = deviceCommonSpecifics.find(device => device.nameGroup == nameGroup);
      groupDevice.groupId = this.groupOriginal.find(e => e.id == deviceData.groupId).groupId;
      let devices = deviceCommonSpecifics.filter(device => device.nameGroup == nameGroup);
      groupDevice.deviceCommonSpecifics = _.cloneDeep(devices);
      groupDeviceCommon.push(groupDevice);
    });
    return groupDeviceCommon;
  }
  /**
   * getDataCurrentDay
   */
  private getDataCurrentDayForDeviceSelected() {
    let timeZone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '');
    this.announcementManagerService.listScheduleFromAPI = null;
    this.schedules = null;
    this.timeByImg = null;
    this.grayOut = false;
    this.lastTimeUpdate = null;
    this.headers = _.cloneDeep(this.headersOriginal);
    this.announcementManagerService.listScheduleCanRun = null;
    this.announcementManagerService.getDataCurrent(this.deviceSelectedPlaylist.registrationId, timeZone).subscribe(
      data => {
        if (!data) {
          return;
        }
        if (data['differentTimeZone']) {
          this.dialogService.showDialog(
            DialogConfirmComponent,
            {
              data: {
                text: this.translateService.instant('announcement-manager.different-timezone'),
                button1: this.translateService.instant('announcement-manager.yes'),
                button2: this.translateService.instant('announcement-manager.no')
              }
            },
            result => {
              if (!result) {
                return;
              }
              this.handleAfterGetData(data);
            }
          );
        } else {
          this.handleAfterGetData(data);
        }
      },
      error => {
        if (error.status == Constant.NETWORK_ERROR_CODE) {
          if (!this.errorMessageList.includes(this.translateService.instant('dialog-error.error-network'))) {
            this.errorMessageList.push(this.translateService.instant('dialog-error.error-network'));
          }
          return;
        }
      }
    );
  }

  /**
   * handle After Get Data
   * @param data
   */
  private handleAfterGetData(data: any): void {
    this.announcementManagerService.listScheduleFromAPI = data['entry'];
    this.timeByImg = data['date'];
    this.grayOut = data['grayout'] ? true : false;
    this.numTimeDateLine = data['numberChangeDateLine'];
    this.lastTimeUpdate = Helper.formatString(
      this.translateService.instant(`announcement-manager.last-time-update`),
      Helper.updateLanguageLastTime(this.timeByImg, this.languageKey)
    );
    this.getHeadersFromTimetable();
    this.indexLiveTime = 0;
    this.isLiveTimeRunning = false;
  }

  /**
   * findFirstKey
   * @returns
   */
  findFirstKey() {
    for (const [index, schedule] of this.announcementManagerService.listScheduleFromAPI.entries()) {
      if (schedule.originalIndex == 1) {
        this.loadDataWithKeyNum(index + 1);
        return;
      }
    }
    this.loadDataWithKeyNum(this.announcementManagerService.listScheduleFromAPI.length);
    return;
  }

  /**
   * loadDataWithKeyNum
   * @param key
   */
  loadDataWithKeyNum(key) {
    this.indexItem = 0;
    const numSize = (key - (key % this.size)) / this.size;
    let numIndexItem;
    let scrollTo;
    this.schedules = [];
    if (numSize < 2) {
      numIndexItem = 0;
      scrollTo = key - 5;
    } else if ((numSize + 1) * this.size <= this.dataLengthAPI) {
      numIndexItem = this.size * (numSize - 1);
      scrollTo = (key % this.size) - 5 + this.size; //(key % this.size == 0 ? 0 : this.size);
    } else {
      numIndexItem = this.size * (numSize - 2);
      scrollTo = (key % this.size) - 5 + this.size * 2;
    }
    this.getData(numIndexItem, this.size * 3, true, false, (scrollTo < 0 ? -1 : scrollTo) + '');
  }
  public loadMoreScrollDown(): void {
    if (this.dataLengthAPI < this.size) {
      return;
    }
    this.getData(this.indexItem, this.size, true);
  }

  public loadMoreScrollUp(): void {
    if (this.dataLengthAPI < this.size) {
      return;
    }
    this.getData(this.indexItem, this.size, false);
  }

  public scrollToElementById(id: string) {
    if (id == '-1') {
      var myDiv = document.getElementById('tableDiv');
      if (myDiv) {
        myDiv.scrollTop = 0;
      }
      return;
    }
    const element = document.getElementById(id);
    if (!element) {
      setTimeout(() => {
        this.scrollToElementById(id);
      }, 100);
    }
    if (element) {
      element.scrollIntoView();
    }
  }

  /**
   * getData
   * @param index
   * @param size
   * @param isScrollDown
   * @param loadLast
   * @param scrollTo
   * @returns
   */
  getData(index, size, isScrollDown, loadLast?, scrollTo?) {
    if (index < 0) {
      return;
    } else if (!isScrollDown && index <= size * 3) {
      return;
    }
    // if (this.isEditRow) {
    //   return;
    // }
    this.indexItem = index;
    let dataArr;
    this.announcementManagerService.getScheduleFromAPI(index, size, isScrollDown).subscribe(data => {
      if (data && data.length > 0) {
        if (isScrollDown) {
          if (loadLast) {
            dataArr = data;
            this.indexItem = index + size;
          } else {
            //if scroll down => push data
            dataArr = [...this.schedules, ...data];
            this.indexItem += size;
          }
        } else {
          //if scroll up => unshift data
          dataArr = [...data, ...this.schedules];
          this.indexItem -= size;
          this.scrollToElementById('0'); //scroll to id (O) before add data.
          setTimeout(() => {
            this.scrollToElementById((this.dataLengthAPI < index ? this.dataLengthAPI + size - index : size) + '');
          }, 300);
        }
        this.schedules = _.cloneDeep(this.announcementManagerService.arrConvertData(index, size, isScrollDown, dataArr));
        if (scrollTo) {
          setTimeout(() => {
            this.scrollToElementById(parseInt(scrollTo) > this.schedules.length ? this.schedules.length - 1 : scrollTo);
          }, 500);
        }

        const indexSelected = this.schedules.findIndex(e => e.key == this.scheduleSelected?.key);
        if (indexSelected == -1) {
          this.scheduleSelected = null; // reset selected if load more row out size of last seleted
          // this.sendDataEnableEdit();
        }
      }
    });
  }

  public selectSchedule(index: number): void {
    // if (this.isEditRow) {
    //   return;
    // }
    if (this.checkRealTime(this.schedules[index])) {
      this.scheduleSelected = this.schedules[index];
      // this.sendDataEnableEdit();
    }
    if (this.schedules[index].isExpand === undefined) {
      return;
    }
    if (this.schedules[index].skipSequence && this.schedules[index].skipReason == 'no sequence') {
      this.schedules[index].isExpand = !this.schedules[index].isExpand;
      return;
    }
    if (this.schedules[index].isExpand) {
      this.schedules.splice(index + 1, this.schedules[index].audioFiles.length);
    } else {
      this.schedules.splice(index + 1, 0, ...this.schedules[index].audioFiles);
    }
    this.schedules[index].isExpand = !this.schedules[index].isExpand;
    if (this.schedules[index].isExpand) {
      this.schedules[index].audio = '';
      this.schedules[index].sequenceIndex = '';
    } else {
      this.schedules[index].sequenceIndex = this.announcementManagerService.listScheduleFromAPI[
        this.schedules[index].key - 1
      ].sequenceIndex;
      if (!this.checkRealTime(this.schedules[index])) {
        this.schedules[index].sequenceIndex = '-';
      }
      this.schedules[index].audioFiles.forEach(e => {
        if (e.completed != undefined) {
          if (e.completed) {
            this.schedules[index].sequenceIndex = '-';
          }
          if (!this.schedules[index].audio) {
            this.schedules[index].audio = this.translateService.instant('announcement-manager.completed');
          }
        }
        if (e.skipAudio) {
          e.class = 'conflictTime';
          this.schedules[index].sequenceIndex = '-';
          if (e.skipReason) {
            if (e.skipReason == 'hold') {
              this.schedules[index].audio = this.translateService.instant('announcement-manager.delay');
            } else if (e.skipReason == 'timeover') {
              this.schedules[index].audio = this.translateService.instant('announcement-manager.time-over');
            } else if (e.skipReason == 'overlap') {
              this.schedules[index].audio = this.translateService.instant('announcement-manager.overlap');
            } else if (e.skipReason == 'no file') {
              this.schedules[index].audio = this.translateService.instant('announcement-manager.no-file');
            } else if (e.skipReason == 'information') {
              this.schedules[index].audio = Helper.formatString(
                this.translateService.instant('announcement-manager.skipInfor'),
                e.skipInfo
              );
              e.audio = Helper.formatString(this.translateService.instant('announcement-manager.skipInfor'), e.skipInfo);
            } else if (e.skipReason == 'delete') {
              this.schedules[index].audio = this.translateService.instant('announcement-manager.delete');
            }
          }
        }
      });
    }
  }

  checkRealTime(item): boolean {
    if (this.grayOut) {
      return false;
    }
    if (item && !item.startTime) {
      return true;
    }
    // true is time(item) > real time.
    if (item.checkTime) {
      // if checked = false -> return.
      return false;
    } else if (item.notIn35Min) {
      return true;
    }
    const dateNow = new Date(Helper.getCurrentDateByTimeZone(this.commonObject));
    const time = moment(dateNow)
      .format(this.formatTime)
      .replace(/:?/g, '');
    let timeInt = parseInt(time);
    if (timeInt < this.numTimeDateLine) {
      timeInt = timeInt + 240000;
    }
    let timeRowStart = parseInt(item.startTime.replace(/:?/g, ''));
    let timeRowEnd = parseInt(item.endTime.replace(/:?/g, ''));
    if (timeRowStart < this.numTimeDateLine) {
      timeRowStart = timeRowStart + 240000;
      timeRowEnd = timeRowEnd + 240000;
    } else if (timeRowEnd < this.numTimeDateLine) {
      timeRowEnd = timeRowEnd + 240000;
    }
    if (timeRowEnd > timeInt) {
      if (item.class != 'conflictTime') {
        if (timeRowStart < timeInt) {
          item.liveTime = true;
          // this.isLiveTimeRunning = true;
          if (item.isAudio) {
            // if (item.originalIndex) this.indexLiveTime = item.originalIndex;
            if (item.completed) {
              item.sequenceIndex = this.translateService.instant('announcement-manager.now-playing');
            }
          } else {
            item.sequenceIndex = this.translateService.instant('announcement-manager.now-playing');
          }
        } else if (
          (this.formatTime == 'HH:mm:ss' && timeRowStart - timeInt > 6000) ||
          (this.formatTime == 'HH:mm' && timeRowStart - timeInt > 60)
        ) {
          item.notIn35Min = true;
        }
        return true;
      }
    } else {
      item.checkTime = true;
      // if (item.liveTime) {
      //   this.isLiveTimeRunning = false;
      // }
      item.liveTime = false;
      if (item.class != 'conflictTime') {
        item.sequenceIndex = '-';
      }
      return false;
    }
  }

  /**
   * settingAutoUpdatePlaylist
   */
  public settingAutoUpdatePlaylist(): void {
    this.dialogService.showDialog(
      DialogAutoUpdatePlaylistComponent,
      {
        data: this.autoUpdateObject
      },
      result => {
        if (!result) {
          return;
        }
        this.autoUpdateObject = JSON.parse(result);
        if (this.autoUpdateObject.autoRefresh) {
          this.intervalCallAPIUpdate(0);
        } else {
          clearInterval(this.intervalAutoUpdate);
        }
      }
    );
  }

  /**
   * interval Call API Update
   * @param timeout
   */
  private intervalCallAPIUpdate(timeout: number) {
    setTimeout(() => {
      clearInterval(this.intervalAutoUpdate);
      const timeCall = this.autoUpdateObject.timeAutoRefresh * 1000 * 60;
      this.intervalAutoUpdate = setInterval(() => {
        this.dataService.sendData([Constant.IS_NETWORK_OK, false]);
        this.dataService.sendData([Constant.IS_CALL_API_A01, false]);
        this.getDataCurrentDayForDeviceSelected();
      }, timeCall);
    }, timeout);
  }

  /**
   * clear and search all
   */
  public clearAndSearchAll(): void {
    this.searchInputValue = Constant.EMPTY;
    if (this.audiosOfFolder) {
      this.dispatchDataToStore(null, null, this.oldAudiosOfFolder, null);
      this.handleSearchEmpty(true);
    } else {
      this.getDataMiniMedia();
      this.dispatchDataToStore(
        this.miniMediaStateOfComponent?.folders ?? this.folders,
        this.miniMediaStateOfComponent?.medias ?? this.medias,
        null,
        null
      );
      this.isActiveButtonBack = false;
    }
  }

  /**
   * auto update
   * @param isClick
   * @param registrationId
   */
  public async autoUpdate(): Promise<void> {
    const payload = {
      device: this.deviceSelectedPlaylist.registrationId,
      account: this.commonObject.tenantName.toUpperCase()
    };
    this.headers = _.cloneDeep(this.headersOriginal);
    this.announcementManagerService.listScheduleFromAPI = null;
    this.announcementManagerService.listScheduleCanRun = null;
    this.schedules = null;
    const registrationIds: string[] = [this.deviceSelectedPlaylist.registrationId];
    this.apiCustomerService.getCurrentStatusRequest(registrationIds).subscribe(data => {
      this.isNetworkConnect = !!_.get(data?.['completed'], `[0]`, undefined);
      this.dataService.sendData([Constant.IS_NETWORK_OK, this.isNetworkConnect]);
      this.dataService.sendData([Constant.IS_CALL_API_A01, true]);
      if (this.isNetworkConnect) {
        const resonse = this.apiCustomerService.getAnnouncementPlaylist(payload);
        if (resonse == null) {
          this.dialogService.showDialog(DialogMessageComponent, {
            data: {
              title: this.translateService.instant('dialog-error.title'),
              text: this.translateService.instant('dialog-error.msg')
            }
          });
          return;
        }
      }
      this.getDataCurrentDayForDeviceSelected();
    });
  }

  /**
   * get Headers From Timetable Editor
   */
  private getHeadersFromTimetable(): void {
    const currentDate = this.getCurrentDateByConditions();
    let item = null;
    const index = this.announcementManagerService.listScheduleFromAPI.findIndex(e => Object.keys(e).includes('item1'));
    if (index != -1) {
      for (const property in this.announcementManagerService.listScheduleFromAPI[index]) {
        if (property.toLocaleLowerCase().includes(this.ITEM)) {
          item = property;
        }
      }
      this.announcementManagerService.getHeadersFromTimetable(this.deviceSelectedPlaylist.deviceId, currentDate, item).subscribe(
        data => {
          this.headersFromTimetable = data ? data : [];
          this.headers.splice(1, 0, ...this.headersFromTimetable);
          this.announcementManagerService.convertDataApi(this.numTimeDateLine, this.commonObject);
          this.dataLengthAPI = this.announcementManagerService.listScheduleFromAPI.length;
          this.announcementManagerService.listScheduleFromAPI.forEach(e => {
            for (let prop in e) {
              if (e.hasOwnProperty(prop) && e[prop]) {
                if (typeof e[prop] == 'string') {
                  e[prop] = Helper.reEncodeHTML(e[prop]);
                } else if (prop == 'audioFiles') {
                  e[prop].forEach(item => {
                    for (let prop_item in item) {
                      if (item.hasOwnProperty(prop_item) && item[prop_item] && typeof item[prop_item] == 'string') {
                        item[prop_item] = Helper.reEncodeHTML(item[prop_item]);
                      }
                    }
                  });
                }
              }
            }
          });
          this.findFirstKey();
        },
        error => {
          Helper.handleError(error, this.translateService, this.dialogService);
        }
      );
    } else {
      this.announcementManagerService.convertDataApi(this.numTimeDateLine, this.commonObject);
      this.dataLengthAPI = this.announcementManagerService.listScheduleFromAPI.length;
      this.findFirstKey();
    }
  }

  /**
   * get Time Date Line
   * @returns
   */
  private async getTimeDateLine(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.commonTableService.getValueCommonTableByKey(Constant.KEY_TIMETABLE_CHANGE_DATE_LINE_TIMETABLE).subscribe(
        data => {
          this.timeDateLine = data ? JSON.parse(data.value) : Constant.TIME_DATE_LINE_DEFAULT;
          resolve();
        },
        error => {
          reject();
          Helper.handleError(error, this.translateService, this.dialogService);
        }
      );
    });
  }

  /**
   * get getEndPoint
   * @returns
   */
  private async getEndPoint(): Promise<void> {
    return new Promise<void>((resolve, reject) => {
      this.announcementManagerService.getEndPoint().subscribe(
        data => {
          this.announcementManagerService.endPoint = data;
          resolve();
        },
        error => {
          reject();
          Helper.handleError(error, this.translateService, this.dialogService);
        }
      );
    });
  }

  /**
   * Get current Date by conditions
   * @returns
   */
  private getCurrentDateByConditions(): string {
    if (Helper.convertHoursToSeconds(this.getCurrentDateByTimeZone(true)) <= this.timeDateLine) {
      const currentDate = new Date(this.getCurrentDateByTimeZone(false));
      currentDate.setDate(currentDate.getDate() - 1);
      return moment(currentDate).format(Constant.FORMAT_DATE);
    }
    return this.getCurrentDateByTimeZone(false);
  }

  /**
   * Get current date by time zone
   * @param isGetHours
   * @returns
   */
  private getCurrentDateByTimeZone(isGetHours: boolean): string {
    let offsetHour = 0;
    let offsetMinute = 0;
    let setting = this.commonObject.setting;
    if (setting) {
      offsetHour = setting.timezone.offsetHour;
      offsetMinute = setting.timezone.offsetMinute;
    }
    return moment
      .utc()
      .add(offsetHour, 'hour')
      .add(offsetMinute, 'minute')
      .format(isGetHours ? this.FORMAT_HOURS : Constant.FORMAT_DATE);
  }

  /**
   * Get languages translation
   */
  private getLanguagesTranslation(): void {
    let languagesSetting = [];
    if (this.commonObject.setting?.languagesSetting) {
      languagesSetting = this.commonObject.setting?.languagesSetting.includes('"')
        ? this.commonObject.setting?.languagesSetting.slice(1, -1).split(',')
        : this.commonObject.setting?.languagesSetting.split(',');
    }
    this.languagesTranslation = Constant.LANGUAGES_SETTING.filter(e => {
      return languagesSetting.includes(e.translation_language_code);
    });
    let languagesSettingMandatory = languagesSetting.slice();
    if (!languagesSettingMandatory.includes('en')) {
      languagesSettingMandatory.unshift('en');
    }
    if (!languagesSettingMandatory.includes('ja')) {
      languagesSettingMandatory.unshift('ja');
    }
    this.languagesTranslatioMandatory = Constant.LANGUAGES_SETTING.filter(e => {
      return languagesSettingMandatory.includes(e.translation_language_code);
    });
    this.setDefaultTranslation();
    this.setDefaultSpeech();
  }

  /**
   * Get list speaker
   * @param languageCode
   */
  getListSpeaker(languageCode: string): Array<Object> {
    if (['vi', 'th', 'id', 'ms'].includes(languageCode)) {
      return [];
    }
    const index = this.languagesTranslation.findIndex(language => language.polly_language_code == languageCode);
    return this.languagesTranslation[index]['speaker'];
  }

  /**
   * delete speechSynthesis
   */

  deleteSpeechSynthesis(pollyLanguageCodes: Array<String>): void {
    this.announcementManagerService.deleteSpeechSynthesis(pollyLanguageCodes).subscribe(() => {
      let listSpeechSynthesisFilter = this.listSpeechSynthesis.filter(element => pollyLanguageCodes.includes(element.pollyLanguageCode));
      this.removeAudioTag(listSpeechSynthesisFilter);
      for (let i of this.listSpeechSynthesis) {
        if (pollyLanguageCodes.includes(i.pollyLanguageCode)) {
          i.isSpeech = false;
          i.media = null;
        }
      }
    });
  }

  /**
   * translate
   */
  public translate(): void {
    this.listSpeechSynthesis
      .filter(e => e.translationLanguageCode)
      .forEach(e => {
        e.isChangeReText = false;
        e.isChangeText = false;
      });
    let speechChecked = this.listSpeechSynthesis.filter(e => e.isChecked);
    if (speechChecked.length) {
      this.handleConfirmDeleteSpeech(speechChecked);
    } else {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.translate-all-language'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          this.handleConfirmDeleteSpeech(this.listSpeechSynthesis);
        }
      );
    }
  }

  /**
   * handleConfirmDeleteSpeech
   *
   * @param listSpeechSynthesis
   */
  handleConfirmDeleteSpeech(listSpeechSynthesis: Array<SpeechSynthesis>): void {
    let targetLanguageCode = [];
    let isConfirm = listSpeechSynthesis.some(item => item.isSpeech);
    if (isConfirm) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.confirmTranslated'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          targetLanguageCode = listSpeechSynthesis.filter(item => item.translationLanguageCode).map(e => e.translationLanguageCode);
          this.handleCallAPITranslate(targetLanguageCode);
          this.deleteSpeechSynthesis(listSpeechSynthesis.filter(item => item.isSpeech)?.map(item => item.pollyLanguageCode));
        }
      );
    } else {
      targetLanguageCode = listSpeechSynthesis.filter(item => item.translationLanguageCode).map(e => e.translationLanguageCode);
      this.handleCallAPITranslate(targetLanguageCode);
    }
  }

  /**
   * handle Call API Translate
   * @param targetLanguageCode
   */
  private handleCallAPITranslate(targetLanguageCode: any[]): void {
    const MAX_LENGTH_INPUT = 600;
    let payload = {
      base_language_code: this.translation.baseLanguageCode,
      text: Helper.encodeHTMLAnnouncementManager(this.translation.text),
      source_language_code: this.translation.sourceLanguageCode,
      target_language_code: targetLanguageCode,
      glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_announcement` : ''
    };
    this.announcementManagerService.translate(payload).subscribe(data => {
      const response = data['message'];
      let languageInValid = new Array<String>();
      for (let e of response) {
        if (e.translated_text.length > 600) {
          const languageKey =
            this.languageKey == 'en'
              ? Constant.LANGUAGES_SETTING.find(item => item.translation_language_code == e.target_language_code).language_name_en
              : Constant.LANGUAGES_SETTING.find(item => item.translation_language_code == e.target_language_code).language_name_ja;
          languageInValid.push(`・${languageKey}`);
        }
      }
      if (languageInValid.length) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('announcement-manager.error'),
            text: this.translateService.instant('announcement-manager.error-max-length'),
            texts: languageInValid
          }
        });
      }
      for (let message of response) {
        this.listSpeechSynthesis.forEach(speechSynthesis => {
          if (speechSynthesis.translationLanguageCode == message['target_language_code']) {
            speechSynthesis.translatedText =
              message['translated_text'].length > MAX_LENGTH_INPUT
                ? Helper.reEncodeHTMLAnnouncementManager(message['translated_text'].substring(0, MAX_LENGTH_INPUT))
                : Helper.reEncodeHTMLAnnouncementManager(message['translated_text']);
            speechSynthesis.retranslatedText = Helper.reEncodeHTMLAnnouncementManager(message['retranslated_text']);
          }
        });
      }
      this.listSpeechSynthesisCloneDeep = _.cloneDeep(this.listSpeechSynthesis);
    });
  }

  /**
   * validate Translation Text
   * @returns
   */
  validateTranslationText(str: string): boolean {
    const regex = Constant.REGEX_CHECK_SPECIAL_CHARACTERS_TAB_TRANSLATION;
    const matches = regex.test(str);
    let messageErrorTabTranslation = new MessageErrorTabTranslation(null, true, false, false, null, false);
    if (matches) {
      this.dialogService.showDialog(DialogMessageComponent, {
        data: {
          title: this.translateService.instant('dialog-playlist-translation.title'),
          validateTabTranslation: messageErrorTabTranslation
        }
      });
      return false;
    }
    return true;
  }

  /**
   * handle Call API Speech Synthesis
   * @param targetLanguageCode
   */
  private handleCallAPISpeechSynthesis(targetLanguageCode: Array<SpeechSynthesis>): void {
    let pollySettings = [];
    targetLanguageCode.forEach(e => {
      let valuePollySetting: any = new Object();
      valuePollySetting['polly_language_code'] = e.pollyLanguageCode;
      valuePollySetting['text'] = Helper.encodeHTMLAnnouncementManager(e.translatedText);
      valuePollySetting['speaker'] = e.speaker[Object.keys(e.speaker)[0]];
      valuePollySetting['rate'] = e.rate;
      valuePollySetting['engine'] = e.speaker[Object.keys(e.speaker)[1]];
      pollySettings.push(valuePollySetting);
    });
    let payload = {
      s3_bucket: this.bucketSaveAudio,
      s3_prefix: `ACN_${this.commonObject.tenantName.toUpperCase()}/polly_media/`,
      polly_settings: pollySettings
    };
    this.announcementManagerService.speechSynthesis(payload).subscribe(
      data => {
        if (data['status'] != 'success') {
          return;
        }
        let listPollySpeech = targetLanguageCode.map(e => e.pollyLanguageCode);
        this.announcementManagerService.getMediasSpeech(listPollySpeech).subscribe(medias => {
          if (!medias) {
            return;
          }
          this.removeAudioTag(targetLanguageCode);
          medias.forEach(media => {
            targetLanguageCode.forEach(e => {
              if (e.pollyLanguageCode == media.name) {
                e.media = media;
                e.isSpeech = true;
                this.setAudioToTag(e);
              }
            });
          });
        });
      },
      error => this.Helper.handleError(error, this.translateService, this.dialogService)
    );
  }

  /**
   * clearText
   */
  public clearText(): void {
    this.translation.text = '';
  }

  /**
   * convertSpeakerDisplay
   */
  public convertSpeakerDisplay(object: any) {
    if (object) {
      return this.translateService.instant(`announcement-manager.${Object.keys(object)[0]}`);
    }
  }

  /**
   * handle Change Speaker
   * @param speechSynthesis
   * @param e
   */
  public handleChangeSpeaker(speechSynthesis: SpeechSynthesis, e: any): void {
    const index = this.languagesTranslation.findIndex(
      language => language.translation_language_code == speechSynthesis.translationLanguageCode
    );
    let speaker = this.languagesTranslation[index].speaker?.find(speaker => e.target.value in speaker);
    speechSynthesis.speaker = speaker;
    if (speechSynthesis.isSpeech) {
      speechSynthesis.isChangeSpeaker = true;
    }
  }

  /**
   * handle Change Speaker
   * @param speechSynthesis
   */
  public handleChangeRate(speechSynthesis: SpeechSynthesis): void {
    if (speechSynthesis.isSpeech) {
      speechSynthesis.isChangeRate = true;
    }
  }

  /**
   * handle click button speech Synthesis
   */
  public speechSynthesis(): void {
    let speechChecked = this.listSpeechSynthesis.filter(e => e.isChecked);
    if (speechChecked.length) {
      if (!this.validateSynthesis(speechChecked)) {
        this.handleCallAPISpeechSynthesis(speechChecked);
      }
    } else {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.speech-all-language'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          let listSpeechSynthesis = this.listSpeechSynthesis.filter(e => e.translationLanguageCode && e.isAvailable && e.translatedText);
          this.handleCallAPISpeechSynthesis(listSpeechSynthesis);
        }
      );
    }
  }

  /**
   * validateSynthesis
   * @param speechChecked
   * @returns
   */
  private validateSynthesis(speechChecked): boolean {
    for (let e of speechChecked) {
      if (!e.translatedText) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('announcement-manager.error'),
            text: this.translateService.instant('announcement-manager.error-none-translate-text')
          }
        });
        return true;
      }
      if (!e.isAvailable) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('announcement-manager.error'),
            text: this.translateService.instant('announcement-manager.error-none-support-speaker')
          }
        });
        return true;
      }
    }
    return false;
  }

  /**
   * handle Change Language Code
   * @param speechSynthesis
   */
  public handleChangeLanguageCode(speechSynthesis: SpeechSynthesis): void {
    if (speechSynthesis.translatedText || speechSynthesis.isSpeech) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.confirm-change-language'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        data => {
          if (!data) {
            const index = this.listSpeechSynthesis.findIndex(e => e.translatedText == speechSynthesis.translatedText);
            speechSynthesis.translationLanguageCode = _.cloneDeep(this.listSpeechSynthesisCloneDeep[index].translationLanguageCode);
            return;
          }
          this.changeLanguage(speechSynthesis);
        }
      );
    } else {
      this.changeLanguage(speechSynthesis);
    }
    const listSpeechSynthesis = this.listSpeechSynthesis.filter(e => e.translationLanguageCode);
    this.isCheckedAllLanguage = listSpeechSynthesis.length ? listSpeechSynthesis.every(e => e.isChecked) : false;
  }

  /**
   * changeLanguge
   * @param speechSynthesis
   */
  changeLanguage(speechSynthesis: SpeechSynthesis): void {
    if (speechSynthesis.pollyLanguageCode) {
      const pollyLanguageCodeOld = _.cloneDeep(speechSynthesis.pollyLanguageCode);
      const indexToRemove = this.languagesTranslation.findIndex(item =>
        item['polly_language_code']
          ? item['polly_language_code'] == pollyLanguageCodeOld
          : item['translation_language_code'] == pollyLanguageCodeOld
      );
      if (indexToRemove != -1) {
        this.listActive.forEach(e => {
          if (e == this.languagesTranslation[indexToRemove]['translation_language_code']) {
            this.listActive.delete(e);
          }
        });
      }
    }
    if (speechSynthesis.translationLanguageCode) {
      const languageCode = this.languagesTranslation.find(e => e['translation_language_code'] == speechSynthesis.translationLanguageCode);
      this.listActive.add(speechSynthesis.translationLanguageCode);
      speechSynthesis.pollyLanguageCode = languageCode['polly_language_code']
        ? languageCode['polly_language_code']
        : speechSynthesis.translationLanguageCode;
      let index = this.languagesTranslation.findIndex(e => e['translation_language_code'] == speechSynthesis.translationLanguageCode);
      speechSynthesis.languageNameEn = this.languagesTranslation[index]['language_name_en'];
      speechSynthesis.languageNameJa = this.languagesTranslation[index]['language_name_ja'];
      speechSynthesis.rate = '100%';
      speechSynthesis.isAvailable = this.languagesTranslation[index]['polly_available'];
      speechSynthesis.speaker = this.languagesTranslation[index]['speaker'] ? this.languagesTranslation[index]['speaker'][0] : null;
      speechSynthesis.translatedText = '';
      speechSynthesis.retranslatedText = '';
      speechSynthesis.isSpeech = false;
      speechSynthesis.media = null;
      speechSynthesis.isChangeRate = false;
      speechSynthesis.isChangeSpeaker = false;
      speechSynthesis.isChangeReText = false;
      speechSynthesis.isChangeText = false;
      speechSynthesis.isAllowsTextInput = true;
      speechSynthesis.isEditText = false;
    } else {
      speechSynthesis.pollyLanguageCode = '';
      speechSynthesis.languageNameEn = '';
      speechSynthesis.languageNameJa = '';
      speechSynthesis.rate = '100%';
      speechSynthesis.isAvailable = false;
      speechSynthesis.speaker = null;
      speechSynthesis.translatedText = '';
      speechSynthesis.retranslatedText = '';
      speechSynthesis.isSpeech = false;
      speechSynthesis.media = null;
      speechSynthesis.isChangeRate = false;
      speechSynthesis.isChangeSpeaker = false;
      speechSynthesis.isChangeReText = false;
      speechSynthesis.isChangeText = false;
      speechSynthesis.isAllowsTextInput = false;
      speechSynthesis.isEditText = false;
      speechSynthesis.isChecked = false;
    }
    this.listSpeechSynthesisCloneDeep = _.cloneDeep(this.listSpeechSynthesis);
  }

  /**
   * checkValidLanguageTranslate
   * @returns
   */
  public checkValidLanguageTranslate(): boolean {
    return this.listActive.size ? true : false;
  }

  /**
   * edit Speech Synthesis
   * @param speechSynthesis
   * @param event
   * @returns
   */
  public editSpeechSynthesis(speechSynthesis: SpeechSynthesis, event: any): void {
    if (!event) {
      event.preventDefault();
      return;
    }
    if (!speechSynthesis.isEditText) {
      speechSynthesis.isEditText = !speechSynthesis.isEditText;
    }
    if (event.keyCode == Constant.ENTER_KEY_CODE && speechSynthesis.isEditText) {
      let payload = {
        source_language_code: speechSynthesis.translationLanguageCode,
        text: Helper.encodeHTMLAnnouncementManager(speechSynthesis.translatedText),
        target_language_code: this.languageKey == Constant.JP_LANGUAGE ? 'ja' : this.languageKey,
        glossary: this.fileGlossary ? `glossary_${this.commonObject.tenantName.toUpperCase()}_announcement` : ''
      };

      if (!speechSynthesis.retranslatedText) {
        this.announcementManagerService.singleRetranslation(payload).subscribe(res => {
          const data = res['message'];
          speechSynthesis.retranslatedText = Helper.reEncodeHTMLAnnouncementManager(data['retranslated_text']);
        });
        if (speechSynthesis.isSpeech) {
          speechSynthesis.isChangeText = true;
          speechSynthesis.isChangeReText = true;
          speechSynthesis.isEditText = !speechSynthesis.isEditText;
          return;
        }
      }
      speechSynthesis.isEditText = !speechSynthesis.isEditText;
    }
  }
  /**
   * change Speech Text
   * @param speechSynthesis
   */
  public changeSpeechText(speechSynthesis: SpeechSynthesis): void {
    if (speechSynthesis.retranslatedText) {
      speechSynthesis.retranslatedText = '';
    }
    if (speechSynthesis.isSpeech) {
      speechSynthesis.isChangeText = true;
    }
  }

  /**
   * remove Audio Tag
   * @param targetLanguageCode
   */
  private removeAudioTag(targetLanguageCode: Array<SpeechSynthesis>, isSaveAudio?: boolean) {
    targetLanguageCode.forEach(code => {
      if (!isSaveAudio) {
        code.isChangeRate = false;
        code.isChangeSpeaker = false;
        code.isChangeText = false;
        code.isChangeReText = false;
        code.isEditText = false;
      }
      var elementToRemove = document.getElementById(`audio-speech${code.pollyLanguageCode}`);
      if (elementToRemove) {
        elementToRemove.remove();
      }
    });
    this.listSpeechSynthesisCloneDeep = _.cloneDeep(this.listSpeechSynthesis);
  }

  /**
   * check Disable button Speech synthesis
   * @returns
   */
  public checkDisableSpeech(): boolean {
    return this.listSpeechSynthesis.some(e => e.isEditText) || this.isPlaying || !this.listSpeechSynthesis.some(e => e.translatedText);
  }

  /**
   * check Disable button translate
   * @returns
   */
  public checkDisableTranslate(): boolean {
    return this.listSpeechSynthesis.some(e => e.isEditText);
  }

  /**
   * checkDisableSaveAudio
   */
  public checkDisableSaveAudio(): boolean {
    return !this.listSpeechSynthesis.some(e => e.isSpeech) || this.isPlaying || this.listSpeechSynthesis.some(e => e.isEditText);
  }

  /**
   * confirmSaveAudio
   */
  confirmSaveAudio(): void {
    const listSpeechSynthesisFilterChangeText = this.listSpeechSynthesis.filter(
      itemChange => itemChange.isChangeReText || itemChange.isChangeText
    );
    const listSpeechSynthesisFilterChangeTextAndChecked = this.listSpeechSynthesis.some(
      itemChange => (itemChange.isChangeReText || itemChange.isChangeText) && itemChange.isChecked
    );

    const listChecked = this.listSpeechSynthesis.filter(item => item.isChecked);

    if (this.validateSaveSpeech(listChecked)) {
      return;
    }
    if (
      (listChecked && listChecked.length > 0 && listSpeechSynthesisFilterChangeTextAndChecked) ||
      (listChecked && listChecked.length == 0 && listSpeechSynthesisFilterChangeText && listSpeechSynthesisFilterChangeText.length)
    ) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.changed-text'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        data => {
          if (!data) {
            return;
          }
          this.saveAudio();
        }
      );
    } else {
      if (this) this.saveAudio();
    }
  }

  /**
   * validateSaveSpeech
   * @param listChecked
   * @returns
   */
  validateSaveSpeech(listChecked): boolean {
    if (listChecked.length) {
      if (listChecked.some(e => ['vi', 'th', 'id', 'ms'].includes(e.pollyLanguageCode) || !e.translatedText)) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('announcement-manager.error'),
            text: this.translateService.instant('announcement-manager.error-none-support-save')
          }
        });
        return true;
      } else if (listChecked.some(e => !e.isSpeech)) {
        this.dialogService.showDialog(DialogMessageComponent, {
          data: {
            title: this.translateService.instant('announcement-manager.error'),
            text: this.translateService.instant('announcement-manager.error-none-support-save')
          }
        });
        return true;
      } else {
        return false;
      }
    } else {
      return false;
    }
  }

  /**
   * save Audio
   */
  saveAudio(): void {
    const listSpeechSynthesisFilterChecked = this.listSpeechSynthesis.filter(item => item.isChecked);
    if (!listSpeechSynthesisFilterChecked || (listSpeechSynthesisFilterChecked && listSpeechSynthesisFilterChecked.length == 0)) {
      this.dialogService.showDialog(
        DialogConfirmComponent,
        {
          data: {
            text: this.translateService.instant('announcement-manager.save-all-synthesized'),
            button1: this.translateService.instant('announcement-manager.yes'),
            button2: this.translateService.instant('announcement-manager.no')
          }
        },
        result => {
          if (!result) {
            return;
          }
          let listSpeechSynthesisFilter = this.listSpeechSynthesis.filter(item => item.isSpeech);
          this.dialogService.showDialog(
            DialogCreatePlaylistTabTranslationSpeechSynthesisComponent,
            {
              data: {
                listSpeechSynthesisFilter: listSpeechSynthesisFilter,
                languageKey: this.languageKey
              }
            },
            res => {
              if (!res) {
                return;
              }
              this.resetRes(listSpeechSynthesisFilter);
            }
          );
        }
      );
    } else {
      const listSpeechSynthesisFilter = this.listSpeechSynthesis.filter(item => item.isSpeech && item.isChecked);
      this.dialogService.showDialog(
        DialogCreatePlaylistTabTranslationSpeechSynthesisComponent,
        {
          data: {
            listSpeechSynthesisFilter: listSpeechSynthesisFilter,
            languageKey: this.languageKey
          }
        },
        res => {
          if (!res) {
            return;
          }
          this.resetRes(listSpeechSynthesisFilter);
        }
      );
    }
  }

  /**
   * resetRes
   *
   * @param res
   * @param listSpeechSynthesisFilter
   * @returns
   */
  resetRes(listSpeechSynthesisFilter: Array<SpeechSynthesis>): void {
    const mapIds = listSpeechSynthesisFilter.map(e => e.pollyLanguageCode);
    for (let element of this.listSpeechSynthesis) {
      if (mapIds.includes(element.pollyLanguageCode)) {
        element.isSpeech = false;
        element.media = null;
        this.removeAudioTag([element], true);
      }
    }
  }

  /**
   * has Speech items
   */
  hasSpeechItems(): boolean {
    return this.listSpeechSynthesis.some(item => item.isSpeech);
  }

  /**
   * calculate IsValid
   */
  calculateIsValid(messageErrorImportFile: MessageErrorImportFile): boolean {
    return (
      !messageErrorImportFile.formatErrorFile?.length &&
      !messageErrorImportFile.specialCharError?.length &&
      !messageErrorImportFile.isMaxLengthError &&
      !messageErrorImportFile.otherError?.length
    );
  }

  /**
   * getBucketSave
   */
  private getBucketSave(): void {
    this.announcementManagerService.getBucketSaveAudio().subscribe(
      data => {
        this.bucketSaveAudio = data;
      },
      error => {
        console.error(error);
      }
    );
  }

  /**
   * loadFileTemp
   *
   * @param audioOfSequence
   */
  private loadFileTemp(audioOfSequence: AudioOfSequence) {
    this.clearPreview();
    let div = document.getElementById('preview') as HTMLElement;
    div.appendChild(this.previewAudioElementSequence(audioOfSequence));
  }

  /**
   * checkDisableGlossary
   * @returns
   */
  checkDisableGlossary(): boolean {
    return this.isPlaying || this.listSpeechSynthesis.some(e => e.isEditText);
  }

  /**
   * glossary
   */
  public glossary(): void {
    this.dialogService.showDialog(
      DialogGlossaryComponent,
      {
        data: {
          screen: Constant.ANNOUNCEMENT_MANAGER_URL
        }
      },
      result => {
        this.fileGlossary = result;
      }
    );
  }

  /**
   * sendTimezoneToBack
   */
  private sendTimezoneToBack(): void {
    let timeZone = this.commonService
      .getCommonObject()
      .setting.timezone.name.split(' ')[0]
      .replace(')', '')
      .replace('(', '');
    this.simplePlaylistService.transferTimezoneToBack(timeZone).subscribe();
  }

  /**
   * disableTranslate
   * @param status
   */
  disableTranslate(status: boolean) {
    this.isDisableTranslate = status;
  }
}
